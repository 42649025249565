import { HistoryFillIcon } from '../../../assets/icons/HistoryFillIcon';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';
export const Historical = () => {
  const button: ButtonOptions = {
    backgorund: ' #B6FFF7',
    color: '#00A794',
    backgorundHover: ' #B6FFF7',
    colorHover: '#00A794',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={<HistoryFillIcon />}
      button={button}
      title={'Histórica'}
    />
  );
};
