import { CertificateRequestPage } from './components/CertificateRequestPage/CertificateRequestPage';
import { CertificateProvider } from './context/CertificateRequestProdiver';

export function CertificateRequest() {
  return (
    <CertificateProvider>
      <CertificateRequestPage />
    </CertificateProvider>
  );
}
