import { CampusPage } from './components/CampusPage/CampusPage';
import { CampusProvider } from './context/CampusProvider';

export function Campus() {
  return (
    <CampusProvider>
      <CampusPage />
    </CampusProvider>
  );
}
