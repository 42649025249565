import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SellIcon, VerifiedUserIcon } from '../../../../../assets/icons';
import certificateIcon from '../../../../../assets/icons/certificateIcon.svg';
import { RoutesPath } from '../../../../../utils/enums';
import { toCurrencyFormat } from '../../../../../utils/helpers';
import { Constancia } from '../../../../../utils/models';
import { useCertificateRequest } from '../../hooks/useCertificateRequest';

interface Props {
  certificate: Constancia;
}

export const CertificateCard: FC<Props> = ({ certificate }) => {
  const navigate = useNavigate();
  const { setPreviewFileOptions } = useCertificateRequest();
  const handlePreviewFile = () => {
    setPreviewFileOptions({
      file: null,
      open: false,
      certificateId: certificate.id,
    });
  };

  const handleCertificateSelected = () => {
    navigate(RoutesPath.STUDENT_CERTIFICATE_REQUEST_PROCESS, {
      state: { certificateId: certificate.id },
    });
  };
  return (
    <>
      <Card sx={{ width: '100%' }}>
        <Grid container sx={{ bgcolor: 'primary.darker' }}>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              px={1}
              my={2}
              color="white"
              sx={{
                fontWeight: 500,
                lineHeight: '15px',
                minHeight: '30px',
                height: '100%',
                maxHeight: '48px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
              }}>
              {certificate.nombre}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            px={1}
            py={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img
              src={certificateIcon}
              style={{ height: 70, width: 70 }}
              alt="icon"
            />
          </Grid>
        </Grid>
        <CardContent>
          <Typography
            variant="body1"
            color="secondary"
            pr={1}
            sx={{ fontWeight: 600 }}>
            Acerca de la constancia
          </Typography>
          <Typography
            variant="body2"
            color="gray"
            paragraph
            pr={1}
            py={1}
            sx={{
              lineHeight: '15px',
              minHeight: '70px',
              maxHeight: '70px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
            }}>
            {certificate.descripcion}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ borderRadius: 50, fontWeight: 500 }}
            onClick={handlePreviewFile}>
            Vista previa
          </Button>

          <Typography
            variant="body1"
            color="secondary"
            pr={1}
            pt={2}
            pb={1}
            sx={{ fontWeight: 600 }}>
            Características
          </Typography>
          <Grid
            container
            sx={{ bgcolor: 'grey.100', height: '100px', overflow: 'hidden' }}
            pb={1}
            px={1}>
            <Grid item xs={12}>
              <VerifiedUserIcon style={{ display: 'inline' }} />{' '}
              <Typography
                variant="subtitle1"
                display={'inline'}
                pr={1}
                color="gray"
                sx={{ fontWeight: 500 }}>
                {certificate.esAprobacionEscolar
                  ? 'Requiere validación'
                  : 'Solicíta en cualquier momento'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SellIcon style={{ display: 'inline' }} />{' '}
              <Typography
                variant="subtitle1"
                display={'inline'}
                pr={1}
                color="gray"
                sx={{ fontWeight: 600 }}>
                {certificate.esCosto
                  ? toCurrencyFormat(certificate.costo)
                  : 'Descarga sin costo'.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCertificateSelected}>
            Solicitar esta constancia
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
