import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  icon: JSX.Element;
  title: string;
  subtitle?: string;
  subtitleTmpl?: JSX.Element | JSX.Element[];
}

export const DataDetailItem: FC<Props> = ({
  icon,
  title,
  subtitle,
  subtitleTmpl,
}) => {
  return (
    <Grid container px={2} py={1} sx={{ minHeight: '60px', minWidth: '300px' }}>
      <Grid
        item
        container
        xs={'auto'}
        pt={1}
        pr={1}
        justifyContent="flex-start">
        {icon}
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant="subtitle1"
          color="grayCustom.main"
          sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            color="grayCustom.main"
            sx={{ fontWeight: 400 }}>
            {subtitle}
          </Typography>
        )}
        {subtitleTmpl && subtitleTmpl}
      </Grid>
    </Grid>
  );
};
