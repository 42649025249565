import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, Usuario } from '../../../../utils/models';
import { ModalRecordUser, UserState } from '../interfaces/interfaces';

type UserAction =
  | { type: 'setUserList'; userList: Usuario[] }
  | { type: 'setPage'; page: number }
  | { type: 'setStatusUser'; status: boolean }
  | { type: 'setPaginatorOptions'; paginatorOption: PageResult<Usuario[]> }
  | { type: 'setRowsPerPage'; rowsPerPage: number }
  | { type: 'setModalRecord'; modalRecord: ModalRecordUser }
  | { type: 'setSearch'; search: string }
  | { type: 'setOrder'; orderBy: string; dir: DirectionOptions }
  | { type: 'setConfirmDialog'; confirmDialog: ConfirmDialogOptions };

export const userReducer = (
  state: UserState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case 'setUserList':
      return { ...state, userList: action.userList };
    case 'setPage':
      return { ...state, page: action.page };
    case 'setStatusUser':
      return { ...state };
    case 'setPaginatorOptions':
      return { ...state, paginatorOptions: action.paginatorOption };
    case 'setRowsPerPage':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'setModalRecord':
      return { ...state, modalRecord: action.modalRecord };
    case 'setSearch':
      return { ...state, search: action.search };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    case 'setConfirmDialog':
      return { ...state, confirmDialog: { ...action.confirmDialog } };
    default:
      return { ...state };
  }
};
