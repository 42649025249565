import { useReducer } from 'react';
import { Constancia } from '../../../../utils/models';
import { CertificateRequestState, PreviewFile } from '../interfaces/interfaces';
import { CertificateRequestContext } from './CertificateRequestContext';
import { certificateReducer } from './certificateRequestReducer';

const INITIAL_STATE: CertificateRequestState = {
  certificateList: [],
  certificateListFiltered: [],
  search: '',
  categories: [],
  categoriesSelected: [],
  certificateSelected: null,
  previewFileOptions: {
    open: false,
    file: null,
    certificateId: null,
  },
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const CertificateProvider = ({ children }: props) => {
  const [certificateState, dispatch] = useReducer(
    certificateReducer,
    INITIAL_STATE,
  );

  const reloadCertificate = (certificateList: Constancia[]) => {
    dispatch({ type: 'reloadCertificate', certificateList });
  };

  const setSearch = (search: string) => {
    dispatch({ type: 'setSearch', search });
  };

  const setCategoriesSelected = (categories: string[]) => {
    dispatch({ type: 'setCategoriesSelected', categories });
  };

  const setCategories = (categories: string[]) => {
    dispatch({ type: 'setCategories', categories });
  };

  const setCertifcateSelected = (certificate: Constancia) => {
    dispatch({ type: 'setCertifcateSelected', certificate });
  };
  const setPreviewFileOptions = (previewFileOptions: PreviewFile) => {
    dispatch({ type: 'setPreviewFileOptions', previewFileOptions });
  };

  return (
    <CertificateRequestContext.Provider
      value={{
        certificateState,
        reloadCertificate,
        setSearch,
        setCertifcateSelected,
        setCategoriesSelected,
        setCategories,
        setPreviewFileOptions,
      }}>
      {children}
    </CertificateRequestContext.Provider>
  );
};
