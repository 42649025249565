import { useReducer } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, Constancia } from '../../../../utils/models';
import { CertificateTableColumn } from '../enums/CertificateTableColumn';
import {
  CertificateState,
  ModalRecordCertificate,
} from '../interfaces/interfaces';
import { CertificateContext } from './CertificateContext';
import { certificateReducer } from './certificateReducer';

const INITIAL_STATE: CertificateState = {
  certificateList: [],
  page: 1,
  paginatorOptions: new PageResult<Constancia[]>(),
  rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
  search: '',
  modalRecord: { show: false, certificateEdit: new Constancia(), type: 'add' },
  orderBy: CertificateTableColumn.NOMBRE,
  dir: DirectionOptions.ASC,
  confirmDialog: {
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  },
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const CertificateProvider = ({ children }: Props) => {
  const [certificateState, dispatch] = useReducer(
    certificateReducer,
    INITIAL_STATE,
  );

  const setCertificateList = (certificateList: Constancia[]) => {
    dispatch({ type: 'setCertificateList', certificateList });
  };

  const setPage = (page: number) => {
    dispatch({ type: 'setPage', page });
  };

  const setPaginatorOptions = (paginatorOption: PageResult<Constancia[]>) => {
    dispatch({ type: 'setPaginatorOptions', paginatorOption });
  };

  const setRowsPerPage = (rowsPerPage: number) => {
    dispatch({ type: 'setRowsPerPage', rowsPerPage });
  };

  const setModalRecord = (modalRecord: ModalRecordCertificate) => {
    dispatch({ type: 'setModalRecord', modalRecord });
  };

  const setSearch = (search: string) => {
    dispatch({ type: 'setSearch', search });
  };

  const setOrder = (orderBy: CertificateTableColumn, dir: DirectionOptions) => {
    dispatch({ type: 'setOrder', orderBy, dir });
  };

  const setConfirmDialog = (confirmDialog: ConfirmDialogOptions) => {
    dispatch({ type: 'setConfirmDialog', confirmDialog });
  };

  const setStatus = (status: boolean, id: number) => {
    dispatch({ type: 'setStatus', status, id });
  };

  return (
    <CertificateContext.Provider
      value={{
        certificateState,
        setCertificateList,
        setPage,
        setStatus,
        setPaginatorOptions,
        setRowsPerPage,
        setModalRecord,
        setSearch,
        setOrder,
        setConfirmDialog,
      }}>
      {children}
    </CertificateContext.Provider>
  );
};
