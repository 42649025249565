import { ConfirmDialogOptions } from '../../../../components';
import {
  PageResult,
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../../../utils/models';
import {
  FollowUpCertificateFilters,
  FollowUpCertificateState,
  ModalRecordFollowUpCertificate,
} from '../interfaces/interfaces';

type FollowUpCertificateAction =
  | {
      type: 'setFollowUpCertificate';
      followUpCertificate: SolicitudDetalle;
    }
  | {
      type: 'setFollowUpCertificateList';
      followUpCertificateList: SolicitudSeguimiento[];
    }
  | {
      type: 'setPaginatorOptions';
      paginatorOption: PageResult<SolicitudSeguimiento[]>;
    }
  | { type: 'setModal'; modal: ModalRecordFollowUpCertificate }
  | { type: 'setFilters'; filters: FollowUpCertificateFilters }
  | { type: 'setConfirmDialog'; confirmDialog: ConfirmDialogOptions };

export const followUpCertificateReducer = (
  state: FollowUpCertificateState,
  action: FollowUpCertificateAction,
): FollowUpCertificateState => {
  switch (action.type) {
    case 'setFollowUpCertificate':
      return {
        ...state,
        followUpCertificate: action.followUpCertificate,
      };
    case 'setFollowUpCertificateList':
      return {
        ...state,
        followUpCertificateList: action.followUpCertificateList,
      };
    case 'setPaginatorOptions':
      return { ...state, paginatorOptions: action.paginatorOption };
    case 'setModal':
      return { ...state, modal: action.modal };
    case 'setFilters':
      return { ...state, filters: { ...action.filters } };
    case 'setConfirmDialog':
      return { ...state, confirmDialog: { ...action.confirmDialog } };
    default:
      return { ...state };
  }
};
