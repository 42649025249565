export const MilitarTechIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="#299940"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.631 15.994c-.112.075-.222.075-.328 0-.106-.075-.14-.181-.103-.319l.525-1.688-1.369-1.012a.285.285 0 0 1-.093-.319c.037-.125.125-.187.262-.187h1.688l.468-1.819-2.625-1.537a1.698 1.698 0 0 1-.59-.582 1.492 1.492 0 0 1-.216-.787V2.625c0-.313.11-.578.328-.797.219-.219.484-.328.797-.328h5.25c.313 0 .578.11.797.328.219.219.328.484.328.797v5.119c0 .287-.072.55-.216.787-.143.238-.34.431-.59.582L9.3 10.65l.487 1.819h1.67c.137 0 .224.062.262.187a.285.285 0 0 1-.094.319l-1.369 1.013.525 1.687c.038.137.003.244-.103.319-.106.075-.216.069-.328-.019L9 14.981l-1.369 1.013Zm1.444-6.469.563-.338v-6a.546.546 0 0 0-.563-.562.546.546 0 0 0-.563.563v6l.563.337Z" />
    </svg>
  );
};
