import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  description?: string;
  descriptionTmp?: ReactNode;
}

export const ToolbarModule: FC<Props> = ({
  title,
  description,
  descriptionTmp,
}) => {
  return (
    <Grid container>
      <Grid item md={12}>
        <Typography variant="h1" color="secondary" sx={{ fontSize: '32px' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} sx={{ py: 2 }}>
        {description && (
          <Typography variant="subtitle2" color="grayCustom.main">
            {description}
          </Typography>
        )}
        {descriptionTmp}
      </Grid>
    </Grid>
  );
};
