import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import {
  EmptyTable,
  FilterTableMenu,
  StatusItem,
} from '../../../../../components';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { PageResult, Usuario } from '../../../../../utils/models';
import { UserTableColumn } from '../../enums/UserTableColumn';
import { useUsers } from '../../hook/useUsers';
import { UserTableActions } from '../UserTableActions/UserTableActions';

interface Props {
  userList: Usuario[];
  paginatorOptions: PageResult<Usuario[]>;
  onEdit: (user: Usuario) => void;
  onChangeStatus: (user: Usuario) => void;
  onDelete: (user: Usuario) => void;
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const UserTable: FC<Props> = ({
  userList,
  onEdit,
  onChangeStatus,
  paginatorOptions,
  onDelete,
  onTableFilter,
}) => {
  const { page, setPage, rowsPerPage, setRowsPerPage, orderBy, dir, setOrder } =
    useUsers();

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    setPage(value);
    onTableFilter(rowsPerPage, value, orderBy, dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    const valuePage = 1;
    setPage(valuePage);
    onTableFilter(value, valuePage, orderBy, dir);
  };

  const handleEdit = (user: Usuario) => {
    onEdit(user);
  };

  const handleStatusToggle = (user: Usuario) => {
    onChangeStatus(user);
  };

  const handleDelete = (user: Usuario) => {
    onDelete(user);
  };

  const onOrderUpward = (columnName: UserTableColumn) => {
    setOrder(columnName, DirectionOptions.ASC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.ASC);
  };

  const onOrderFalling = (columnName: UserTableColumn) => {
    setOrder(columnName, DirectionOptions.DESC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.DESC);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                Nómina
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(UserTableColumn.NOMINA)}
                  onOrderFalling={() => onOrderFalling(UserTableColumn.NOMINA)}
                />
              </TableCell>
              <TableCell variant="head" sx={{ minWidth: '200px' }}>
                Nombre{' '}
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(UserTableColumn.NOMBRE)}
                  onOrderFalling={() => onOrderFalling(UserTableColumn.NOMBRE)}
                />
              </TableCell>
              <TableCell variant="head">
                Rol{' '}
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(UserTableColumn.ES_ADMIN)}
                  onOrderFalling={() =>
                    onOrderFalling(UserTableColumn.ES_ADMIN)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Status{' '}
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(UserTableColumn.ACTIVO)}
                  onOrderFalling={() => onOrderFalling(UserTableColumn.ACTIVO)}
                />
              </TableCell>
              <TableCell variant="head" align="center" sx={{ pt: 1 }}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.nomina}</TableCell>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>
                  {row.esAdmin ? 'Administrador' : 'Escolar'}
                </TableCell>
                <TableCell>
                  {' '}
                  <StatusItem status={row.activo} />{' '}
                </TableCell>
                <TableCell align="center">
                  <UserTableActions
                    user={row}
                    onEdit={() => handleEdit(row)}
                    onChangeStatus={() => handleStatusToggle(row)}
                    onDelete={() => handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable elements={userList} />
      <Divider />
      <TablePagination
        rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
        component="div"
        count={paginatorOptions.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        SelectProps={{
          inputProps: {
            'aria-label': 'Filas por página',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
