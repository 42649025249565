import React from 'react';

export const FilePageIcon = (props: any) => {
  return (
    <svg
      width={22}
      height={22}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.998 16.27h6.004a.668.668 0 0 0 .688-.687.668.668 0 0 0-.688-.687H7.998a.668.668 0 0 0-.688.687.668.668 0 0 0 .688.688Zm0-3.895h6.004a.668.668 0 0 0 .688-.688.668.668 0 0 0-.688-.687H7.998a.668.668 0 0 0-.688.688.668.668 0 0 0 .688.687Zm-2.956 7.792a1.32 1.32 0 0 1-.963-.413 1.32 1.32 0 0 1-.412-.962V3.208c0-.366.137-.687.412-.962a1.32 1.32 0 0 1 .963-.413h7.7c.183 0 .362.039.538.115.176.076.325.176.447.298l4.194 4.194c.122.122.221.27.298.446.076.176.114.356.114.539v11.367c0 .366-.137.687-.412.962a1.32 1.32 0 0 1-.963.413H5.042Zm7.585-13.384V3.208H5.042v15.584h11.916V7.47h-3.643a.668.668 0 0 1-.688-.688ZM5.042 3.208v4.263-4.263 15.584V3.208Z" />
    </svg>
  );
};
