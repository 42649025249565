import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import './HeadTab.css';
interface Props {
  icon: JSX.Element;
  title: string;
  description: string;
}

export const HeadTab: FC<Props> = ({ icon, title, description }) => {
  return (
    <Grid container alignItems="center">
      <Grid item md={4} lg={2}>
        <Box
          p={1}
          m={2}
          sx={{
            width: '40px',
            height: '40px',
            bgcolor: 'grayCustom.light',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {icon}
        </Box>
      </Grid>
      <Grid item md={8} lg={10} px={1}>
        <Typography
          variant="body1"
          color="secondary"
          pr={1}
          sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="gray" pr={1} py={1}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
