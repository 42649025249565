export const SportScoreIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.313 4.125h1.125V3H7.312v1.125Zm2.25 0V3h1.124v1.125H9.563Zm-2.25 4.5V7.5h1.125v1.125H7.312Zm4.5-2.25V5.25h1.124v1.125h-1.124Zm0 2.25V7.5h1.124v1.125h-1.124Zm-2.25 0V7.5h1.124v1.125H9.563Zm2.25-4.5V3h1.124v1.125h-1.124ZM8.437 5.25V4.125h1.126V5.25H8.437ZM5.626 15a.546.546 0 0 1-.563-.563V3.563A.546.546 0 0 1 5.625 3a.546.546 0 0 1 .563.563v.562h1.125V5.25H6.188v1.125h1.125V7.5H6.188v6.938a.546.546 0 0 1-.563.562Zm5.063-7.5V6.375h1.124V7.5h-1.124Zm-2.25 0V6.375h1.124V7.5H8.438ZM7.312 6.375V5.25h1.125v1.125H7.313Zm2.25 0V5.25h1.126v1.125H9.562Zm1.126-1.125V4.125h1.124V5.25h-1.124Z" />
    </svg>
  );
};
