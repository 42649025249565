import { useReducer } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import {
  PageResult,
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../../../utils/models';
import { FollowUpCertificateModalOption } from '../enums/FollowUpCertificateModalOption';
import { FollowUpCertificateTableColumn } from '../enums/FollowUpCertificateTableColumn';
import {
  FollowUpCertificateFilters,
  FollowUpCertificateState,
  ModalRecordFollowUpCertificate,
} from '../interfaces/interfaces';
import { FollowUpCertificateContext } from './FollowUpCertificateContext';
import { followUpCertificateReducer } from './followUpCertificateReducer';

const INITIAL_STATE: FollowUpCertificateState = {
  followUpCertificate: new SolicitudDetalle(),
  followUpCertificateList: [],
  paginatorOptions: new PageResult<SolicitudSeguimiento[]>(),
  modal: {
    show: false,
    certificateEdit: new SolicitudSeguimiento(),
    type: FollowUpCertificateModalOption.UPDATE_FIELDS,
  },
  confirmDialog: {
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  },
  filters: {
    rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
    orderBy: FollowUpCertificateTableColumn.NOMBRE,
    dir: DirectionOptions.ASC,
    page: 1,
    campus: [],
    folio: null,
    enrollment: null,
  },
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const FollowUpCertificateProvider = ({ children }: Props) => {
  const [followUpCertificateState, dispatch] = useReducer(
    followUpCertificateReducer,
    INITIAL_STATE,
  );

  const setFollowUpCertificate = (followUpCertificate: SolicitudDetalle) => {
    dispatch({ type: 'setFollowUpCertificate', followUpCertificate });
  };

  const setFollowUpCertificateList = (
    followUpCertificateList: SolicitudSeguimiento[],
  ) => {
    dispatch({ type: 'setFollowUpCertificateList', followUpCertificateList });
  };

  const setPaginatorOptions = (
    paginatorOption: PageResult<SolicitudSeguimiento[]>,
  ) => {
    dispatch({ type: 'setPaginatorOptions', paginatorOption });
  };

  const setModal = (modal: ModalRecordFollowUpCertificate) => {
    dispatch({ type: 'setModal', modal });
  };

  const setFilters = (filters: FollowUpCertificateFilters) => {
    dispatch({ type: 'setFilters', filters });
  };

  const setConfirmDialog = (confirmDialog: ConfirmDialogOptions) => {
    dispatch({ type: 'setConfirmDialog', confirmDialog });
  };

  return (
    <FollowUpCertificateContext.Provider
      value={{
        followUpCertificateState,
        setFollowUpCertificate,
        setFollowUpCertificateList,
        setPaginatorOptions,
        setModal,
        setFilters,
        setConfirmDialog,
      }}>
      {children}
    </FollowUpCertificateContext.Provider>
  );
};
