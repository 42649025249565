import { useReducer } from 'react';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { Director, PageResult } from '../../../../utils/models';
import { DirectorTableColumn } from '../enums/DirectorTableColumn';
import { DirectorState, ModalRecordDirector } from '../interfaces/interfaces';
import { directorReducer } from './directorReducer';
import { DirectorContext } from './DirectorsContext';

const INITIAL_STATE_PERIOD: DirectorState = {
  directorList: [],
  page: 1,
  paginatorOptions: new PageResult<Director[]>(),
  rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
  search: '',
  orderBy: DirectorTableColumn.NOMBRE,
  dir: DirectionOptions.ASC,
  modalRecord: {
    show: false,
    directorEdit: null,
    type: 'add',
  },
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const DirectorProvider = ({ children }: props) => {
  const [directorState, dispatch] = useReducer(
    directorReducer,
    INITIAL_STATE_PERIOD,
  );

  const reloadDirector = (directorList: Director[]) => {
    dispatch({ type: 'reloadDirector', directorList });
  };

  const changePage = (page: number) => {
    dispatch({ type: 'changePage', page });
  };

  const changeStatus = (status: boolean, director: Director) => {
    dispatch({ type: 'changeStatus', status, director });
  };

  const changePaginatorOptions = (paginatorOptions: PageResult<Director[]>) => {
    dispatch({ type: 'changePaginatorOptions', paginatorOptions });
  };

  const changeRowsPerPage = (rowsPerPage: any) => {
    dispatch({ type: 'changeRowsPerPage', rowsPerPage });
  };

  const changeSearch = (search: string) => {
    dispatch({ type: 'changeSearch', search });
  };

  const changeModalRecord = (modalRecord: ModalRecordDirector) => {
    dispatch({ type: 'changeModalRecord', modalRecord });
  };

  const setOrder = (orderBy: DirectorTableColumn, dir: DirectionOptions) => {
    dispatch({ type: 'setOrder', orderBy, dir });
  };

  return (
    <DirectorContext.Provider
      value={{
        directorState,
        reloadDirector,
        changePage,
        changeStatus,
        changePaginatorOptions,
        changeRowsPerPage,
        changeSearch,
        changeModalRecord,
        setOrder,
      }}>
      {children}
    </DirectorContext.Provider>
  );
};
