import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  ConfirmDialog,
  ContainerTable,
  ToolbarModule
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CertificateHistoryService } from '../../../../../services';
import { SolicitudAlumno } from '../../../../../utils/models';
import { useCertificateHistory } from '../../hooks/useCertificateHistory';
import { CertificateHistorySearch } from '../CertificateHistorySearch/CertificateHistorySearch';
import { CertificateHistoryTable } from '../CertificateHistoryTable/CertificateHistoryTable';

export const CertificateHistoryPage = () => {
  const certificatesService = new CertificateHistoryService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    setCertificateList,
    search,
    setPaginatorOptions,
    orderBy,
    dir,
    page,
    rowsPerPage,
    setPage,
    confirmDialog,
    setConfirmDialog,
  } = useCertificateHistory();

  const getAllCertificates = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await certificatesService
      .getAllCertificate(filtro, pageSize, pageNumber, ordenar, dir, false)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró una constancia',
              type: 'info',
            });
          }
          const data: SolicitudAlumno[] = res.data.data.map(
            (request: SolicitudAlumno) =>
              new SolicitudAlumno().deserialize(request),
          );
          setCertificateList(data);
          setPaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado al obtener las constancias',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllCertificates(search, pageSize, pageNumber, orderBy, asc);
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    setPage(valuePage);
    getAllCertificates(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllCertificates(search, rowsPerPage, page, orderBy, dir);
  };

  useEffect(() => {
    getAllCertificates(search, rowsPerPage, page, orderBy, dir);
  }, []);

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Grid container>
        <ToolbarModule
          title={'Consulta tu historial aquí'}
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Ahora puedes consultar el histórico de tus solicitudes y estar
                al tanto de las constancias que requieren aprobación por parte
                de {'  '}
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary"
                  display={'inline'}>
                  Servicios Escolares Tecmilenio
                </Typography>
              </Typography>
              <br/>
              <Typography variant="subtitle2" color="grayCustom.main">
                La descarga de tu constancia estará habilitada durante 3 días naturales, a partir de su emisión, te recomendamos validarla y guardarla en tu dispositivo.
              </Typography>
            </>
          }></ToolbarModule>
        <ContainerTable
          title={'Constancias'}
          actions={
            <>
              <Grid container px={0}>
                <CertificateHistorySearch onSearch={onSearch} />
              </Grid>
            </>
          }
          table={
            <Grid item md={12} sx={{ mt: 2 }}>
              <CertificateHistoryTable
                onReloadData={onReloadData}
                onTableFilter={onTableFilter}
              />
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
