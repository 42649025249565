import { ArrowForward } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import PayError from '../../../../../assets/icons/PayError.svg';
import { useLoading } from '../../../../../hooks';
import { RequestProcessService } from '../../../../../services';
import { toCurrencyFormat } from '../../../../../utils/helpers';
import { RequestProcessStep } from '../../enums/enums';
import { useRequestProcess } from '../../hooks/useRequestProcess';

interface Props {
  handleChangeTab: (index: RequestProcessStep) => void;
}

export const MakePay: FC<Props> = ({ handleChangeTab }) => {
  const requestProcessService = new RequestProcessService();
  const { certificate } = useRequestProcess();
  const { setLoading } = useLoading();

  const sendToPay = () => {
    setLoading(true);
    window.location.replace(requestProcessService.getURLToPay(certificate.id));
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ bgcolor: 'grayCustom.light', borderRadius: '16px' }}>
        <Grid container py={{ xs: 2, md: 6 }} px={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              variant="h1"
              color="grayCustom.dark"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '24px' },
                textAlign: { xs: 'center', md: 'left' },
              }}>
              ¡Ups! Pago no procesado
            </Typography>
            <Grid item xs={12} md={8} sx={{ pt: 2, pb: 1 }}>
              <Typography
                component="div"
                variant="subtitle2"
                color="grayCustom.main"
                sx={{ textAlign: { xs: 'center', md: 'left' }, pb: 2 }}>
                ¡Lo sentimos! Por alguna razón, no hemos podido procesar tu
                pago.
              </Typography>
              <Typography
                component="span"
                color="primary.dark"
                sx={{
                  fontWeight: 400,
                  pt: 2,
                  display: 'block',
                  fontSize: '18px',
                  textAlign: { xs: 'center', md: 'left' },
                }}>
                ¡Inténtalo nuevamente!
              </Typography>
            </Grid>
            <Button
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={sendToPay}
              sx={{
                px: 3,
                py: 1,
                color: 'white',
                width: { xs: '100%', md: 'auto' },
                bgcolor: 'primary.dark',
                '&: hover': {
                  bgcolor: 'primary.main',
                },
              }}>
              {`PAGAR ${toCurrencyFormat(certificate.costo)} MXN`}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
            }}>
            <div>
              <img src={PayError} alt="icon" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
