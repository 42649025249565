export const ApproveIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="m9.042 13.063-1.75-1.75a.553.553 0 0 0-.604-.126.46.46 0 0 0-.187.146.553.553 0 0 0-.167.406c0 .16.056.296.167.407l2.104 2.104c.07.07.139.118.208.146.07.028.146.041.23.041.083 0 .159-.013.228-.041a.636.636 0 0 0 .209-.146l3.937-3.938a.553.553 0 0 0 .167-.406.553.553 0 0 0-.167-.406.553.553 0 0 0-.406-.167.553.553 0 0 0-.406.167l-3.563 3.563Zm-4.458 5.27a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V2.917a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h7c.167 0 .33.034.49.104.16.07.295.16.406.27l3.812 3.813c.111.111.202.247.271.406.07.16.104.323.104.49v10.333a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H4.584ZM11.48 6.167v-3.25H4.584v14.166h10.833V6.792h-3.312a.607.607 0 0 1-.625-.625Zm-6.896-3.25v3.875-3.875 14.166V2.917Z" />
    </svg>
  );
};
