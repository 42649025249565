import { useContext } from 'react';
import { FollowUpCertificateContext } from '../context/FollowUpCertificateContext';

export const useFollowUpCertificates = () => {
  const {
    followUpCertificateState,
    setFollowUpCertificate,
    setFollowUpCertificateList,
    setPaginatorOptions,
    setModal,
    setConfirmDialog,
    setFilters,
  } = useContext(FollowUpCertificateContext);

  const {
    followUpCertificate,
    followUpCertificateList,
    paginatorOptions,
    modal,
    confirmDialog,
    filters,
  } = followUpCertificateState;

  return {
    followUpCertificate,
    setFollowUpCertificate,
    followUpCertificateList,
    setFollowUpCertificateList,
    paginatorOptions,
    setPaginatorOptions,
    modal,
    setModal,
    confirmDialog,
    setConfirmDialog,
    filters,
    setFilters,
  };
};
