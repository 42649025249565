import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContainerTable, ToolbarModule } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService, DirectorService } from '../../../../../services';
import { Campus, Director } from '../../../../../utils/models';
import { useDirectors } from '../../hooks/useDirectors';
import { DirectorRecord } from '../../modals/DirectorRecord/DirectorRecord';
import { DirectorSearch } from '../DirectorSearch/DirectorSearch';
import { DirectorsTable } from '../DirectorsTable/DirectorsTable';

export const DirectorsPage = () => {
  const directorService = new DirectorService();
  const campusService = new CampusService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [campusList, setCampusList] = useState<Campus[]>([]);
  const {
    rowsPerPage,
    page,
    search,
    paginatorOptions,
    modalRecord,
    orderBy,
    dir,
    reloadDirector,
    changePaginatorOptions,
    changePage,
    changeModalRecord,
  } = useDirectors();

  const getAllCampus = async () => {
    setLoading(true);
    await campusService
      .getAllCampus()
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Campus[] = res.data.data.map((user) =>
            new Campus().deserialize(user),
          );
          setCampusList(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const getAllDirectors = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await directorService
      .getAllDirector(filtro, pageSize, pageNumber, ordenar, dir, true)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró un director',
              type: 'info',
            });
          }
          const data: Director[] = res.data.data.map((director) =>
            new Director().deserialize(director),
          );
          reloadDirector(data);
          changePaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    changePage(valuePage);
    getAllDirectors(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllDirectors(search, rowsPerPage, page, orderBy, dir);
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllDirectors(search, pageSize, pageNumber, orderBy, asc);
  };

  useEffect(() => {
    onReloadData();
    getAllCampus();
  }, []);
  return (
    <>
      {modalRecord.show && (
        <DirectorRecord
          campusList={campusList}
          onReloadData={onReloadData}></DirectorRecord>
      )}
      <Grid container>
        <ToolbarModule
          title="Administrar directores"
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Gestiona el registro de los directores pertenecientes a los
                diversos Centros de Trabajo.
              </Typography>
            </>
          }></ToolbarModule>

        <ContainerTable
          title="Directores de Académicos y de Campus"
          actions={
            <Grid container px={0}>
              <DirectorSearch onSearch={onSearch}></DirectorSearch>
              <Grid
                item
                container
                direction="row"
                xs={12}
                md={6}
                justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    ml: { xs: 0, md: 2 },
                    my: 1,
                    py: 1,
                    width: { xs: '100%', md: 'auto' },
                    justifyContent: { xs: 'flex-start', md: 'center' },
                  }}
                  startIcon={<Add />}
                  onClick={() =>
                    changeModalRecord({
                      ...modalRecord,
                      show: true,
                      type: 'add',
                      directorEdit: null,
                    })
                  }>
                  Crear director
                </Button>
              </Grid>
            </Grid>
          }
          table={
            <Grid item md={12} sx={{ mt: 4 }}>
              <DirectorsTable
                onReloadData={onReloadData}
                campusList={campusList}
                onTableFilter={onTableFilter}
              />
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
