import { ScheduleFilIcon } from '../../../assets/icons/ScheduleFilIIcon';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';

export const Expired = () => {
  const button: ButtonOptions = {
    backgorund: ' #FBF2D5',
    color: '#BF8E1B',
    backgorundHover: ' #FBF2D5',
    colorHover: '#BF8E1B',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={
        <ScheduleFilIcon
          contentColor={'#BF8E1B'}
          exclamationColor={'#FBF2D5'}
        />
      }
      button={button}
      title={'Expirada'}
    />
  );
};
