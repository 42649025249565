import React from 'react';

export const DownloadOutlinedIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M10 13.23a.611.611 0 0 1-.229-.043.633.633 0 0 1-.208-.145L6.417 9.896a.576.576 0 0 1-.177-.448A.65.65 0 0 1 6.438 9a.611.611 0 0 1 .448-.187c.174 0 .323.062.448.187l2.042 2.063V3.957A.607.607 0 0 1 10 3.333a.607.607 0 0 1 .625.625v7.104L12.688 9a.611.611 0 0 1 .448-.187c.174 0 .323.062.448.187a.611.611 0 0 1 .188.448.611.611 0 0 1-.188.448l-3.146 3.146a.633.633 0 0 1-.208.146.61.61 0 0 1-.23.041Zm-5.416 3.437a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875v-2.354a.607.607 0 0 1 .625-.626.607.607 0 0 1 .625.626v2.354h10.833v-2.354a.607.607 0 0 1 .625-.626.607.607 0 0 1 .625.626v2.354a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H4.584Z" />
    </svg>
  );
};
