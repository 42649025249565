import React from 'react';

export const AddPersonFillIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.833 11.667a.607.607 0 0 1-.625-.625V8.959h-2.083a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h2.083V5.625A.607.607 0 0 1 15.833 5a.607.607 0 0 1 .625.625V7.71h2.083a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625h-2.083v2.083a.607.607 0 0 1-.625.625ZM7.5 9.979c-.917 0-1.667-.291-2.25-.875-.584-.583-.875-1.333-.875-2.25 0-.916.291-1.666.875-2.25.583-.583 1.333-.875 2.25-.875.916 0 1.666.292 2.25.875.583.584.875 1.334.875 2.25 0 .917-.292 1.667-.875 2.25-.584.584-1.334.875-2.25.875Zm-6.042 6.688a.607.607 0 0 1-.625-.625v-1.333c0-.486.125-.927.375-1.323.25-.396.597-.691 1.042-.886 1.041-.458 1.968-.78 2.78-.968a10.944 10.944 0 0 1 4.938 0c.813.187 1.733.51 2.76.968.445.209.796.507 1.053.896.257.389.385.826.385 1.313v1.333a.607.607 0 0 1-.625.625H1.458Z" />
    </svg>
  );
};
