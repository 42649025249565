import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  DateEndIcon,
  EditCalendarIcon,
  EditCalendarPencilIcon,
} from '../../../../../assets/icons';
import { EmptyDialog, FieldDisable, ModalBar } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { FollowUpCertificatesService } from '../../../../../services';
import { validationMsj } from '../../../../../utils/consts';
import { DateHelper } from '../../../../../utils/helpers';
import {
  FechaSolicitud,
  SolicitudSeguimiento,
} from '../../../../../utils/models';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';

interface Props {
  onReloadData: () => void;
}

const MAX_LENGTH_DESCRIPTION = 200;
const localeMap = {
  es: esLocale,
};

export const UpdateValidity: FC<Props> = ({ onReloadData }) => {
  const followUpCertificateService = new FollowUpCertificatesService();
  const { setLoading } = useLoading();
  const { modal, setModal, filters, setFilters } = useFollowUpCertificates();
  const [expirationDate, setExpirationDate] = useState<Date>(new Date(0, 0, 0));
  const [dateExpirationKey, setDateExpirationKey] = useState('');
  const [dateCertificate, setDateCertificate] = useState<FechaSolicitud>(
    new FechaSolicitud(),
  );
  const { notify, setNotify } = useNotify();
  const formik = useFormik({
    initialValues: { date: new Date() },
    validationSchema: Yup.object({
      date: Yup.date().required(validationMsj.is.required),
    }),
    onSubmit: () => handleSubmit(),
  });

  const errorDatePicker = (value: boolean) => {
    return value ? 'Ingrese una fecha válida.' : '';
  };

  const handleClose = () => {
    setModal({
      ...modal,
      show: false,
      certificateEdit: new SolicitudSeguimiento(),
    });
    onReloadData();
  };

  const isValid = () => {
    const diff = DateHelper.compareDates(
      formik.values.date,
      expirationDate,
      'ms',
    );

    if (diff < 0) {
      setNotify({
        ...notify,
        open: true,
        message: 'La fecha de vigencia debe ser menor que la fecha final',
        type: 'info',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!formik.isValid) return;

    if (!isValid()) {
      return;
    }

    setLoading(true);
    const body = DateHelper.convertDateToISO(formik.values.date);
    await followUpCertificateService
      .updatedExpirationTime(modal.certificateEdit.id, body)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Se ha actualizado la constancia correctamente',
          });
          handleClose();
          setFilters({ ...filters });
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          type: 'error',
          message: 'Error al actualizar la constancia',
        });
      });
  };

  const getDateLimit = async () => {
    setLoading(true);
    await followUpCertificateService
      .getCertificateDates(modal.certificateEdit.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        const data = new FechaSolicitud().deserialize(res.data);
        setDateCertificate(data);
        setExpirationDate(parseISO(data.expiracion));
        setDateExpirationKey(DateHelper.convertDateString(data.expiracion));
        formik.setValues({ ...formik.values, date: parseISO(data.vigencia) });
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          type: 'error',
          message: 'Error al actualizar la constancia',
        });
      });
  };

  useEffect(() => {
    if (modal.certificateEdit.id) {
      getDateLimit();
    }
  }, [modal]);

  return (
    <>
      <EmptyDialog
        dialog={{ open: modal.show }}
        dialogTitle={{
          title: 'Restablecer vigencia de la constancia',
          onClose: handleClose,
        }}
        dialogContent={{
          children: (
            <>
              <Grid container>
                <ModalBar
                  startIcon={
                    <EditCalendarIcon sx={{ color: 'grayCustom.main' }} />
                  }
                  title="Realiza el ajuste de la vigencia"
                />
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="primary.darker"
                    sx={{ fontWeight: 600, pb: 2 }}>
                    ELIGE LA FECHA DE VIGENCIA
                  </Typography>
                </Grid>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={localeMap.es}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                    <DatePicker
                      label="Nueva fecha de vigencia"
                      inputFormat="dd/MM/yyyy"
                      value={formik.values.date}
                      onChange={(event) => {
                        formik.setFieldValue('date', event);
                      }}
                      components={{
                        OpenPickerIcon: EditCalendarPencilIcon,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nueva fecha de vigencia"
                          placeholder="dd/MM/yyyy"
                          required
                          error={Boolean(formik.errors.date)}
                          helperText={errorDatePicker(
                            Boolean(formik.errors.date),
                          )}
                        />
                      )}
                      maxDate={expirationDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ pt: 3, pb: 2, pr: { xs: 0, md: 2 } }}>
                    <FieldDisable
                      icon={<DateEndIcon sx={{ color: 'grayCustom.main' }} />}
                      title="Fecha de expiración"
                      subtitle={dateExpirationKey}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </>
          ),
        }}
        dialogActions={{
          cancelText: 'Cancelar',
          onClose: handleClose,
          confirmText: 'Guardar',
          onConfirm: handleSubmit,
          isValid: formik.isValid && formik.dirty,
        }}
      />
    </>
  );
};
