import { Money, PermIdentity } from '@mui/icons-material';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { NotifyOptions } from '../../../../../components';
import { UsersService } from '../../../../../services';
import { commonRegex, validationMsj } from '../../../../../utils/consts';
import { Usuario } from '../../../../../utils/models';

interface Props {
  onUserChange: (user: Usuario) => void;
  notify: NotifyOptions;
  setNotify: (notify: NotifyOptions) => void;
  setLoading: (loading: boolean) => void;
}

export const ValidUser: FC<Props> = ({
  onUserChange,
  notify,
  setNotify,
  setLoading,
}) => {
  const usersService = new UsersService();

  const [user, setUser] = useState(new Usuario());
  const formik = useFormik({
    initialValues: { nomina: '' },
    validationSchema: Yup.object({
      nomina: Yup.string()
        .matches(commonRegex.payRoll(), validationMsj.is.payRollFormat)
        .min(9, validationMsj.is.payRollFormat)
        .max(9, validationMsj.is.payRollFormat),
    }),
    onSubmit: () => null,
  });

  const validUserIfExist = async (payRoll: string) => {
    setLoading(true);
    await usersService
      .validUserIfExist(payRoll)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setUser(res.data);
          onUserChange(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'info',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'info',
        });
      });
  };

  const handleSearchUser = () => {
    validUserIfExist(formik.values.nomina);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{ fontWeight: 600, pb: 2 }}>
            VALIDAR NÓMINA
          </Typography>
        </Grid>
        <Grid item container md={8} sx={{ pr: { md: 2, xs: 0 } }}>
          <TextField
            id="nomina"
            name="nomina"
            label="Nómina"
            placeholder="Nómina"
            variant="outlined"
            type="text"
            fullWidth
            size={'small'}
            value={formik.values.nomina}
            onChange={formik.handleChange}
            inputProps={{ maxLength: 9 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Money />
                </InputAdornment>
              ),
            }}
            helperText={formik.errors.nomina}
            error={!!formik.errors.nomina}
          />
          <TextField
            name="nombre"
            label="Nombre"
            placeholder="Nombre"
            variant="outlined"
            type="text"
            value={user.nombre || ''}
            disabled
            fullWidth
            sx={{ my: 2 }}
            size={'small'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentity sx={{ color: 'grayCustom.light' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{ py: 1, mb: 2 }}
            size={'small'}
            onClick={handleSearchUser}
            disabled={!(formik.isValid && formik.dirty)}
            fullWidth>
            Validar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
