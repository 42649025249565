import { createContext } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, Usuario } from '../../../../utils/models';
import { ModalRecordUser, UserState } from '../interfaces/interfaces';

export type UserContextProps = {
  userState: UserState;
  setUserList: (userList: Usuario[]) => void;
  setPage: (page: number) => void;
  setStatusUser: (status: boolean) => void;
  setPaginatorOptions: (paginatorOption: PageResult<Usuario[]>) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setModalRecord: (modalRecord: ModalRecordUser) => void;
  setSearch: (search: string) => void;
  setOrder: (orderBy: string, dir: DirectionOptions) => void;
  setConfirmDialog: (confirmDialog: ConfirmDialogOptions) => void;
};

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps,
);
