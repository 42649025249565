import { Route, Routes } from 'react-router-dom';
import { CertificateDetailPage } from './components/CertificateDetailPage/CertificateDetailPage';
import { FollowUpCertificatesPage } from './components/FollowUpCertificatesPage/FollowUpCertificatesPage';
import { FollowUpCertificateProvider } from './context/FollowUpCertificateProvider';

export function FollowUpCertificates() {
  return (
    <FollowUpCertificateProvider>
      <Routes>
        <Route path="/" element={<FollowUpCertificatesPage />} />
        <Route
          path="/:certificateId/detalle"
          element={<CertificateDetailPage />}
        />
      </Routes>
    </FollowUpCertificateProvider>
  );
}
