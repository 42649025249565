import { Add, ToggleOff, ToggleOn } from '@mui/icons-material';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { TrashIcon } from '../../../../../assets/icons';
import {
  ConfirmDialog,
  ContainerTable,
  StatusItem,
  ToolbarModule,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { UsersService } from '../../../../../services';
import { Usuario } from '../../../../../utils/models';
import { useUsers } from '../../hook/useUsers';
import { UserRecord } from '../../modals/UserRecord/UserRecord';
import { UserSearch } from '../UserSearch/UserSearch';
import { UserTable } from '../UserTable/UserTable';

export const UserPage = () => {
  const usersService = new UsersService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const theme = useTheme();
  const palette = theme.palette;
  const {
    userList,
    setUserList,
    search,
    modalRecord,
    setModalRecord,
    paginatorOptions,
    setPaginatorOptions,
    orderBy,
    dir,
    page,
    rowsPerPage,
    setPage,
    confirmDialog,
    setConfirmDialog,
  } = useUsers();

  const handleCreateUser = () => {
    setModalRecord({ ...modalRecord, show: true, type: 'add' });
  };

  const getAllUsers = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await usersService
      .getAllUser(filtro, pageSize, pageNumber, ordenar, dir, true)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró un usuario',
              type: 'info',
            });
          }
          const data: Usuario[] = res.data.data.map((user) =>
            new Usuario().deserialize(user),
          );
          setUserList(data);
          setPaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado al obtener los usuarios',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const changeStatus = async (user: Usuario) => {
    setLoading(true);
    await usersService
      .updateStatusUser(user.id, !user.activo)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Usuario actualizado correctamente',
          });
          setConfirmDialog({ ...confirmDialog, open: false });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const onChangeStatus = async (user: Usuario) => {
    setConfirmDialog({
      title: 'Modificar el status del usuario',
      subTitle: `¿Deseas cambiar el status del usuario a “${
        user.activo ? 'INACTIVO' : 'ACTIVO'
      }”?`,
      confirmText: user.activo ? 'Sí, desactivar' : 'Sí, activar',
      cancelText: 'Cancelar',
      startIcon: user.activo ? (
        <ToggleOn sx={{ color: 'grayCustom.main' }} />
      ) : (
        <ToggleOff sx={{ color: 'grayCustom.main' }} />
      ),
      endIcon: (
        <StatusItem
          status={!user.activo}
          bgColor={user.activo ? palette.grayCustom.darker : null}
          bgText={user.activo ? palette.grayCustom.main : null}
        />
      ),
      open: true,
      onConfirm: () => {
        changeStatus(user);
      },
    });
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllUsers(search, pageSize, pageNumber, orderBy, asc);
  };

  const onEdit = async (user: Usuario) => {
    setLoading(true);
    await usersService
      .getUserById(user.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Usuario = new Usuario().deserialize(res.data);
          setModalRecord({
            ...modalRecord,
            type: 'edit',
            show: true,
            userEdit: data,
          });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const handleDelete = async (user: Usuario) => {
    setConfirmDialog({
      title: 'Eliminar usuario',
      subTitle: `¿Deseas eliminar el usuario “${user.nombre}”?`,
      confirmText: 'Sí, eliminar',
      cancelText: 'No, Cancelar',
      open: true,
      startIcon: <TrashIcon fill={palette.grayCustom.main} />,
      endIcon: null,
      onConfirm: () => {
        setConfirmDialog({ ...confirmDialog, open: false });
        deleteUser(user);
      },
    });
  };

  const deleteUser = async (user: Usuario) => {
    setLoading(true);
    await usersService
      .deleteUser(user.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Usuario eliminado correctamente',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    setPage(valuePage);
    getAllUsers(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllUsers(search, rowsPerPage, page, orderBy, dir);
  };

  useEffect(() => {
    getAllUsers(search, rowsPerPage, page, orderBy, dir);
  }, []);

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {modalRecord.show && <UserRecord getAllUsers={() => onReloadData()} />}
      <Grid container>
        <ToolbarModule
          title={'Administración de usuarios'}
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Administra el acceso de los usuarios, define sus roles y los
                tipos de usuarios de las personas que forman parte del
                departamento de
                {'  '}
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary"
                  display={'inline'}>
                  Servicios Escolares Tecmilenio
                </Typography>
              </Typography>
            </>
          }></ToolbarModule>
        <ContainerTable
          title={'Usuarios con acceso al sistema'}
          actions={
            <>
              <Grid container px={0}>
                <UserSearch onSearch={onSearch} />
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  md={3}
                  justifyContent="flex-end">
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      ml: { xs: 0, md: 2 },
                      my: 1,
                      width: { xs: '100%', md: 'auto' },
                      justifyContent: { xs: 'flex-start', md: 'center' },
                    }}
                    onClick={handleCreateUser}
                    startIcon={<Add />}>
                    Crear usuario
                  </Button>
                </Grid>
              </Grid>
            </>
          }
          table={
            <Grid item md={12} sx={{ mt: 2 }}>
              <UserTable
                userList={userList}
                onChangeStatus={onChangeStatus}
                onEdit={onEdit}
                onDelete={handleDelete}
                paginatorOptions={paginatorOptions}
                onTableFilter={onTableFilter}
              />
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
