export const DotClockIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m9.619 8.756 2.287 2.288a.554.554 0 0 1 .188.431.554.554 0 0 1-.188.431.554.554 0 0 1-.431.188.554.554 0 0 1-.431-.188L8.569 9.431a.463.463 0 0 1-.15-.206.77.77 0 0 1-.038-.244V6c0-.175.06-.322.178-.44A.598.598 0 0 1 9 5.38c.176 0 .323.06.441.178A.598.598 0 0 1 9.62 6v2.757Zm-7.444 3.431a8.529 8.529 0 0 1-.431-1.237 7.583 7.583 0 0 1-.225-1.331h1.256c.037.35.1.684.187 1.003.088.319.194.628.32.928l-1.107.637Zm-.656-3.806c.037-.462.115-.91.234-1.34.119-.432.266-.847.44-1.247l1.088.637c-.138.3-.247.613-.328.938a7.47 7.47 0 0 0-.178 1.012H1.519Zm3.206 6.825a8.759 8.759 0 0 1-1.069-.89 6.148 6.148 0 0 1-.863-1.06l1.088-.619A5.84 5.84 0 0 0 5.344 14.1l-.62 1.106ZM3.9 5.344l-1.107-.619c.263-.388.554-.74.873-1.06.318-.318.671-.609 1.059-.871L5.344 3.9c-.288.2-.55.419-.788.656a5.76 5.76 0 0 0-.656.788ZM8.38 16.48a7.235 7.235 0 0 1-1.34-.234 9.208 9.208 0 0 1-1.247-.44l.637-1.088c.3.137.613.247.938.328.324.081.662.14 1.012.178v1.256Zm-1.95-13.2-.637-1.087c.4-.175.815-.322 1.247-.44a7.235 7.235 0 0 1 1.34-.235v1.256c-.35.038-.688.097-1.012.178-.325.081-.638.19-.938.328Zm3.188 13.2v-1.256c.35-.038.687-.097 1.012-.178.325-.081.637-.19.937-.328l.638 1.087c-.4.175-.816.322-1.247.44a7.234 7.234 0 0 1-1.34.235Zm1.95-13.2a5.335 5.335 0 0 0-.938-.328 7.425 7.425 0 0 0-1.012-.178V1.52c.462.037.909.112 1.34.225.431.112.847.262 1.247.45l-.638 1.087Zm1.706 11.925-.619-1.106c.287-.2.55-.419.788-.656.237-.238.456-.5.656-.788l1.106.619c-.262.388-.553.74-.872 1.06-.319.318-.672.609-1.06.871Zm.825-9.862a6.483 6.483 0 0 0-.666-.778 6.482 6.482 0 0 0-.778-.666l.619-1.106a7.747 7.747 0 0 1 1.912 1.912l-1.087.638Zm1.125 3.037a6.339 6.339 0 0 0-.188-1.022 6.123 6.123 0 0 0-.337-.928l1.106-.637c.188.4.338.815.45 1.247.113.43.188.878.225 1.34h-1.256Zm.581 3.825-1.088-.637c.138-.3.248-.613.329-.938a7.47 7.47 0 0 0 .178-1.012h1.256c-.037.462-.116.91-.234 1.34a9.209 9.209 0 0 1-.44 1.247Z" />
    </svg>
  );
};
