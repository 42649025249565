import {
  MoreVert,
  ToggleOffOutlined,
  ToggleOnOutlined,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { EditSquareOutlinedIcon, TrashIcon } from '../../../../../assets/icons';
import { ClaveCentroTrabajo } from '../../../../../utils/models';

interface Props {
  workPlace: ClaveCentroTrabajo;
  onEdit: () => void;
  onChangeStatus: () => void;
  onDelete: () => void;
}

export const WorkPlaceTableActions: FC<Props> = ({
  workPlace,
  onEdit,
  onChangeStatus,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  const handleChangeStatus = () => {
    onChangeStatus();
    handleClose();
  };
  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: 0.5 }}>
        <MoreVert />
      </IconButton>
      <Menu
        variant="menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{ top: 0, left: 0 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditSquareOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>
        <MenuItem onClick={handleChangeStatus}>
          <ListItemIcon>
            {workPlace.activo ? (
              <ToggleOffOutlined sx={{ color: 'secondary.main' }} />
            ) : (
              <ToggleOnOutlined sx={{ color: 'secondary.main' }} />
            )}
          </ListItemIcon>
          <ListItemText primary={workPlace.activo ? 'Desactivar' : 'Activar'} />
        </MenuItem>
        <Divider sx={{ mx: 2 }} />
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText primary="Eliminar" sx={{ color: 'red' }} />
        </MenuItem>
      </Menu>
    </Box>
  );
};
