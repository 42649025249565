import { useContext } from 'react';
import { CertificateRequestContext } from '../context/CertificateRequestContext';

export const useCertificateRequest = () => {
  const {
    certificateState,
    reloadCertificate,
    setSearch,
    setCategoriesSelected,
    setCategories,
    setCertifcateSelected,
    setPreviewFileOptions,
  } = useContext(CertificateRequestContext);
  const {
    certificateList,
    search,
    certificateSelected,
    categoriesSelected,
    categories,
    certificateListFiltered,
    previewFileOptions,
  } = certificateState;

  return {
    certificateList,
    reloadCertificate,
    search,
    setSearch,
    categoriesSelected,
    setCategoriesSelected,
    categories,
    setCategories,
    certificateSelected,
    setCertifcateSelected,
    certificateListFiltered,
    previewFileOptions,
    setPreviewFileOptions,
  };
};
