import { Deserializable } from '../interfaces';

export class PageResult<T> implements Deserializable {
  page: number;
  pageSize: number;
  totalCount: number;
  data: T;
  constructor() {
    this.page = 0;
    this.pageSize = 20;
    this.totalCount = 0;
    this.data = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
