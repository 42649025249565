export const GeneralIcon = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.5 19.5h3.75v-6.25h5.5v6.25h3.75V9.75L12 4.875 5.5 9.75v9.75Zm0 1.5c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 4 19.5V9.75c0-.233.054-.458.162-.675.109-.217.255-.392.438-.525l6.5-4.875c.133-.1.275-.175.425-.225.15-.05.308-.075.475-.075.167 0 .325.025.475.075.15.05.292.125.425.225l6.5 4.875c.183.133.33.308.438.525.108.217.162.442.162.675v9.75c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 18.5 21h-5.25v-6.25h-2.5V21H5.5Z" />
    </svg>
  );
};
