export const CancelFillIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="#B82238"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.794 12.206a.54.54 0 0 0 .787 0L9 9.788l2.438 2.437c.1.1.228.147.384.14a.553.553 0 0 0 .384-.159.54.54 0 0 0 .169-.393.54.54 0 0 0-.169-.394L9.788 9l2.437-2.438a.49.49 0 0 0 .14-.384.553.553 0 0 0-.159-.384.54.54 0 0 0-.393-.169.54.54 0 0 0-.394.169L9 8.213 6.562 5.774a.49.49 0 0 0-.384-.14.554.554 0 0 0-.384.159.54.54 0 0 0-.169.394c0 .15.056.28.169.393L8.213 9l-2.438 2.438a.49.49 0 0 0-.14.384c.006.156.059.284.159.384ZM9 16.5c-1.063 0-2.05-.19-2.963-.572a7.319 7.319 0 0 1-2.38-1.584 7.32 7.32 0 0 1-1.585-2.381A7.607 7.607 0 0 1 1.5 9c0-1.05.19-2.031.572-2.944a7.32 7.32 0 0 1 1.584-2.381 7.445 7.445 0 0 1 2.381-1.594A7.5 7.5 0 0 1 9 1.5c1.05 0 2.031.194 2.944.581a7.446 7.446 0 0 1 2.381 1.594 7.447 7.447 0 0 1 1.594 2.381c.387.913.581 1.894.581 2.944a7.5 7.5 0 0 1-.581 2.963 7.448 7.448 0 0 1-1.594 2.38 7.32 7.32 0 0 1-2.381 1.585A7.558 7.558 0 0 1 9 16.5Z" />
    </svg>
  );
};
