export const DateStartIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.354 13.542c-.57 0-1.048-.194-1.437-.583a1.954 1.954 0 0 1-.584-1.438c0-.57.195-1.049.584-1.437A1.953 1.953 0 0 1 7.354 9.5c.57 0 1.049.195 1.438.584.389.389.583.868.583 1.437 0 .57-.194 1.049-.583 1.438a1.954 1.954 0 0 1-1.438.583ZM3.75 18.334a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V4.167a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h1.354v-.583c0-.195.063-.355.188-.48a.649.649 0 0 1 .479-.187c.194 0 .357.062.49.187a.631.631 0 0 1 .197.48v.583h7.084v-.583c0-.195.062-.355.187-.48a.649.649 0 0 1 .48-.187c.194 0 .357.062.489.187a.63.63 0 0 1 .198.48v.583h1.354a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875v12.917a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H3.75Zm0-1.25h12.5V8.125H3.75v8.959Zm0-10.209h12.5V4.167H3.75v2.708Zm0 0V4.167v2.708Z" />
    </svg>
  );
};
