import { CertificateHistoryPage } from './components/CertificateHistoryPage/CertificateHistoryPage';
import { CertificateHistoryProvider } from './context/CertificateHistoryProvider';

export function CertificateHistory() {
  return (
    <CertificateHistoryProvider>
      <CertificateHistoryPage />
    </CertificateHistoryProvider>
  );
}
