import { Message, MoreVert, Payment } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { DownloadOutlinedIcon } from '../../../../../assets/icons';
import { CertificateStatus } from '../../../../../utils/enums';
import { SolicitudAlumno } from '../../../../../utils/models';

interface Props {
  request: SolicitudAlumno;
  showRejectMessage: () => void;
  onDownload: () => void;
  pay: () => void;
}

export const CertificateHistoryTableActions: FC<Props> = ({
  request,
  showRejectMessage,
  onDownload,
  pay,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [actions, setActions] = useState({
    download: false,
    rejected: false,
    pay: false,
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowRejectMessage = () => {
    showRejectMessage();
    handleClose();
  };
  const handlePay = () => {
    pay();
  };

  useEffect(() => {
    switch (request.estatusId) {
      case CertificateStatus.IN_REVIEW:
        setDisableButton(true);
        break;
      case CertificateStatus.PENDING_PAYMENT:
        setActions({ ...actions, pay: true });
        break;
      case CertificateStatus.REJECTED:
        setActions({ ...actions, rejected: true });
        break;
      case CertificateStatus.VALID:
        setActions({
          ...actions,
          download: true,
        });
        break;
      case CertificateStatus.EXPIRED:
        setDisableButton(true);
        break;
      case CertificateStatus.HISTORICAL:
        setDisableButton(true);
        break;
    }
  }, []);

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disableButton}
        onClick={handleClick}
        sx={{ p: 0.5 }}>
        <MoreVert />
      </IconButton>
      <Menu
        variant="menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{ top: 0, left: 0 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {actions.download && (
          <MenuItem onClick={onDownload}>
            <ListItemIcon>
              <DownloadOutlinedIcon sx={{ color: 'secondary.main' }} />
            </ListItemIcon>{' '}
            <ListItemText>Descargar</ListItemText>{' '}
          </MenuItem>
        )}
        {actions.rejected && (
          <MenuItem onClick={handleShowRejectMessage}>
            <ListItemIcon>
              <Message />
            </ListItemIcon>
            <ListItemText primary="Ver mensaje" />
          </MenuItem>
        )}
        {actions.pay && (
          <MenuItem onClick={handlePay}>
            <ListItemIcon>
              <Payment sx={{ color: 'secondary.main' }} />
            </ListItemIcon>
            <ListItemText primary="Pagar" />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
