import { ToggleOff, ToggleOn } from '@mui/icons-material';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { TrashIcon } from '../../../../../assets/icons';
import {
  ConfirmDialog,
  ConfirmDialogOptions,
  EmptyTable,
  FilterTableMenu,
  StatusItem,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService } from '../../../../../services';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { Campus } from '../../../../../utils/models';
import { CampusTableColumn } from '../../enums/CampusTableColumn';
import { useCampus } from '../../hooks/useCampus';
import { CampusTableActions } from '../CampusTableActions/CampusTableActions';

interface Props {
  onReloadData: () => void;
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const CampusTable: FC<Props> = ({ onReloadData, onTableFilter }) => {
  const campusService = new CampusService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const theme = useTheme();
  const palette = theme.palette;

  const {
    campusList,
    paginatorOptions,
    page,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    modalRecord,
    changeModalRecord,
    changeStatus,
    orderBy,
    dir,
    setOrder,
  } = useCampus();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogOptions>({
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  });

  const deleteCampus = async (campus: Campus) => {
    setLoading(true);
    await campusService
      .deleteCampus(campus.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se eliminó el campus correctamente.',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onAlert = async (
    campus: Campus,
    type: 'edit' | 'delete',
    subTitle: string,
  ) => {
    setConfirmDialog({
      title:
        type === 'edit'
          ? 'Modificar el status del campus'
          : 'Eliminar el campus',
      subTitle,
      confirmText:
        type === 'edit'
          ? campus.activo
            ? 'Sí, desactivar'
            : 'Sí, activar'
          : 'Sí, eliminar',
      cancelText: 'No, Cancelar',
      open: true,
      startIcon:
        type === 'edit' ? (
          campus.activo ? (
            <ToggleOn sx={{ color: 'grayCustom.main' }} />
          ) : (
            <ToggleOff sx={{ color: 'grayCustom.main' }} />
          )
        ) : (
          <TrashIcon fill={palette.grayCustom.main} />
        ),
      endIcon:
        type === 'edit' ? (
          <StatusItem
            status={!campus.activo}
            bgColor={campus.activo ? palette.grayCustom.darker : null}
            bgText={campus.activo ? palette.grayCustom.main : null}
          />
        ) : null,
      onConfirm: () => {
        setConfirmDialog({ ...confirmDialog, open: false });
        switch (type) {
          case 'delete':
            deleteCampus(campus);
            break;
          case 'edit':
            updateStatusCampus(campus, !campus.activo);
            break;
        }
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    changePage(value);
    onTableFilter(rowsPerPage, value, orderBy, dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    changeRowsPerPage(value);
    const valuePage = 1;
    changePage(valuePage);
    onTableFilter(value, valuePage, orderBy, dir);
  };

  const updateStatusCampus = async (campus: Campus, status: boolean) => {
    setLoading(true);
    await campusService
      .updateStatusCampus(campus.id, status)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Campus actualizado correctamente',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onOrderUpward = (columnName: CampusTableColumn) => {
    setOrder(columnName, DirectionOptions.ASC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.ASC);
  };

  const onOrderFalling = (columnName: CampusTableColumn) => {
    setOrder(columnName, DirectionOptions.DESC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.DESC);
  };

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                Clave
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(CampusTableColumn.CLAVE)}
                  onOrderFalling={() => onOrderFalling(CampusTableColumn.CLAVE)}
                />
              </TableCell>
              <TableCell variant="head">
                Nombre del campus
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(CampusTableColumn.NOMBRE)}
                  onOrderFalling={() =>
                    onOrderFalling(CampusTableColumn.NOMBRE)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Status
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(CampusTableColumn.ESTATUS)}
                  onOrderFalling={() =>
                    onOrderFalling(CampusTableColumn.ESTATUS)
                  }
                />
              </TableCell>
              <TableCell variant="head" align="center">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campusList.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>
                  {' '}
                  <StatusItem status={row.activo} />{' '}
                </TableCell>
                <TableCell align="center">
                  <CampusTableActions
                    campus={row}
                    onEdit={() =>
                      changeModalRecord({
                        ...modalRecord,
                        show: true,
                        type: 'edit',
                        campusEdit: row,
                      })
                    }
                    onChangeStatus={() =>
                      onAlert(
                        row,
                        'edit',
                        `¿Deseas cambiar el status del campus a “${
                          row.activo ? 'INACTIVO' : 'ACTIVO'
                        }”?`,
                      )
                    }
                    onDelete={() =>
                      onAlert(
                        row,
                        'delete',
                        `¿Deseas eliminar el campus “${row.nombre}”?`,
                      )
                    }
                  />
                </TableCell>
                {/* <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    justifyContent="center">
                    <Tooltip title="Editar">
                      <IconButton
                        color={'success'}
                        onClick={() =>
                          changeModalRecord({
                            ...modalRecord,
                            show: true,
                            type: 'edit',
                            campusEdit: row,
                          })
                        }>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={row.activo ? 'Desactivar' : 'Activar'}>
                      <IconButton
                        color={row.activo ? 'error' : 'success'}
                        onClick={() =>
                          onAlert(
                            row,
                            'edit',
                            `¿Deseas ${
                              row.activo ? 'desactivar' : 'activar'
                            } de este campus?`,
                          )
                        }>
                        <CompareArrows />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Borrar">
                      <IconButton
                        color={'error'}
                        onClick={() =>
                          onAlert(
                            row,
                            'delete',
                            `¿Deseas eliminar el campus ${row.nombre} ?`,
                          )
                        }>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable elements={campusList} />
      <Divider />
      <TablePagination
        rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
        component="div"
        count={paginatorOptions.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        SelectProps={{
          inputProps: {
            'aria-label': 'Filas por página',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
