import { removeAccentsAndLowerCase } from '../../../../utils/helpers';
import { Constancia } from '../../../../utils/models';
import { CertificateRequestState, PreviewFile } from '../interfaces/interfaces';

type CertificateAction =
  | { type: 'reloadCertificate'; certificateList: Constancia[] }
  | {
      type: 'setSearch';
      search: string;
    }
  | {
      type: 'setCategoriesSelected';
      categories: string[];
    }
  | {
      type: 'setCertifcateSelected';
      certificate: Constancia;
    }
  | {
      type: 'setCategories';
      categories: string[];
    }
  | {
      type: 'setPreviewFileOptions';
      previewFileOptions: PreviewFile;
    };

function filterCertificate(
  certificateList: Constancia[],
  search: string,
  categories: string[],
): Constancia[] {
  let certificateListFiltered = [];
  if (!search && categories.length === 0) {
    certificateListFiltered = certificateList.slice(0, 5);
    return certificateListFiltered;
  }

  if (search) {
    certificateListFiltered = certificateList.filter(
      (certificate) =>
        removeAccentsAndLowerCase(certificate.nombre).includes(
          removeAccentsAndLowerCase(search),
        ) ||
        removeAccentsAndLowerCase(certificate.descripcion).includes(
          removeAccentsAndLowerCase(search),
        ),
    );
  } else {
    certificateListFiltered = certificateList;
  }

  if (categories.length > 0) {
    certificateListFiltered = certificateListFiltered.filter((certificate) =>
      certificate.categorias.some((c: string) => categories.includes(c)),
    );
  }
  return certificateListFiltered;
}

export const certificateReducer = (
  state: CertificateRequestState,
  action: CertificateAction,
): CertificateRequestState => {
  switch (action.type) {
    case 'reloadCertificate':
      return {
        ...state,
        certificateList: action.certificateList,
        certificateListFiltered: filterCertificate(
          action.certificateList,
          state.search,
          state.categoriesSelected,
        ),
      };
    case 'setSearch':
      return {
        ...state,
        search: action.search,
        certificateListFiltered: filterCertificate(
          state.certificateList,
          action.search,
          state.categories,
        ),
      };
    case 'setCategoriesSelected':
      return {
        ...state,
        categoriesSelected: action.categories,
        certificateListFiltered: filterCertificate(
          state.certificateList,
          state.search,
          action.categories,
        ),
      };
    case 'setCertifcateSelected':
      return {
        ...state,
        certificateSelected: action.certificate,
      };
    case 'setCategories':
      return {
        ...state,
        categories: action.categories,
      };
    case 'setPreviewFileOptions':
      return {
        ...state,
        previewFileOptions: { ...action.previewFileOptions },
      };
    default:
      return state;
  }
};
