import { Add, Clear, Close, Key } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { EditSquareIcon } from '../../../../../assets/icons';
import { FieldDisable, ModalBar } from '../../../../../components';
import {
  DragNDrop,
  FileLoaded,
} from '../../../../../components/DragNDrop/DragNDrop';
import { PreviewImage } from '../../../../../components/PreviewImage/PreviewImage';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService } from '../../../../../services';
import { commonRegex, validationMsj } from '../../../../../utils/consts';
import { convertBlobToBase64, getFileSize } from '../../../../../utils/helpers';
import { DataFile } from '../../../../../utils/interfaces';
import { Campus } from '../../../../../utils/models';
import { useCampus } from '../../hooks/useCampus';

interface Props {
  onReloadData: () => void;
}

type CampusForm = {
  id: string;
  nombre: string;
  nombreCoordinador: string;
  firmaCoordinador: string;
  direccion: string;
  activo: boolean;
  correos: string[];
  email: string;
};

const MAX_LENGTH_NAME = 200;
const MAX_LENGTH_ID = 10;

const initialStateForm: CampusForm = {
  id: '',
  nombre: '',
  nombreCoordinador: '',
  firmaCoordinador: '',
  direccion: '',
  activo: true,
  correos: [],
  email: '',
};

const validationSchema = Yup.object({
  id: Yup.string()
    .required(validationMsj.is.required)
    .max(MAX_LENGTH_ID, validationMsj.is.maxLength(MAX_LENGTH_ID))
    .trim(validationMsj.is.trim()),
  nombre: Yup.string()
    .required(validationMsj.is.required)
    .max(MAX_LENGTH_NAME, validationMsj.is.maxLength(MAX_LENGTH_NAME))
    .nullable(true)
    .trim(validationMsj.is.trim()),
  direccion: Yup.string()
    .required(validationMsj.is.required)
    .nullable(true)
    .trim(validationMsj.is.trim()),
  nombreCoordinador: Yup.string()
    .required(validationMsj.is.required)
    .matches(commonRegex.name(), validationMsj.is.onlyText)
    .nullable(true)
    .trim(validationMsj.is.trim()),
  activo: Yup.boolean(),
  email: Yup.string().email(validationMsj.is.email),
});
export const CampusRecord: FC<Props> = ({ onReloadData }) => {
  const campusService = new CampusService();
  const { modalRecord, changeModalRecord } = useCampus();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [file, setFile] = useState<DataFile>({
    name: null,
    data: null,
    url: null,
    sizeDescription: null,
    blob: null,
  });
  const [previewImageModal, setPreviewImageModal] = useState({
    image: '',
    open: false,
  });

  const formik = useFormik({
    initialValues: initialStateForm,
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const createCampus = async (campus: Campus) => {
    setLoading(true);
    await campusService
      .createCampus(campus)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Campus creado correctamente',
            type: 'success',
          });
          changeModalRecord({
            ...modalRecord,
            show: false,
            campusEdit: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateCampus = async (campus: Campus) => {
    setLoading(true);
    await campusService
      .updateCampus(campus)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Campus actualizado correctamente.',
            type: 'success',
          });
          changeModalRecord({
            ...modalRecord,
            show: false,
            campusEdit: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getCampusById = async (campus: Campus) => {
    setLoading(true);
    await campusService
      .getCampusById(campus.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const campusToEdit = new Campus().deserialize(res.data);
          formik.setValues({
            ...formik.values,
            id: campusToEdit.id,
            nombre: campusToEdit.nombre,
            nombreCoordinador: campusToEdit.nombreCoordinador,
            firmaCoordinador: campusToEdit.firmaCoordinador,
            direccion: campusToEdit.direccion,
            activo: campusToEdit.activo,
            correos: campusToEdit.correos,
          });
          setFile({ ...file, name: campusToEdit.firmaCoordinador });
          if (campusToEdit.firmaCoordinador) {
            setFileByURL(campusToEdit.firmaCoordinador);
          }
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error al obtener el campus',
          type: 'error',
        });
      });
  };

  const setFileByURL = async (fileName: string) => {
    setLoading(true);
    await campusService
      .getURLElectronicSignature(modalRecord.campusEdit.id)
      .then(async (response) => {
        const res = response.data;
        if (res.success) {
          setLoading(true);
          await axios({
            url: res.data,
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(async (response) => {
            const url = URL.createObjectURL(response.data);
            const base64 = await convertBlobToBase64(response.data);
            setLoading(false);
            const size = getFileSize(response.data.size);

            setFile({
              ...file,
              name: fileName,
              url,
              data: base64,
              sizeDescription: size,
            });
          });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const addEmail = () => {
    const email: string = formik.values.email;
    const emailList: string[] = formik.values.correos.slice();
    const emailExist = emailList.find((e) => e === email);
    if (emailExist) {
      setNotify({
        ...notify,
        open: true,
        message: 'Este correo ya existe en la lista',
        type: 'info',
      });
      return;
    }
    emailList.push(email);
    formik.setValues({ ...formik.values, correos: emailList, email: '' });
  };

  const deleteEmail = (index: number) => {
    const emailList: string[] = formik.values.correos.slice();
    emailList.splice(index, 1);
    formik.setValues({ ...formik.values, correos: emailList });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter' && formik.values.email && !formik.errors.email) {
      addEmail();
    }
  };

  const handleSubmit = () => {
    const email: string = formik.values.email;
    const emailList: string[] = formik.values.correos.slice();
    if (email) {
      const emailExist = emailList.find((e) => e === email);
      if (emailExist) {
        setNotify({
          ...notify,
          open: true,
          message: 'Este correo ya existe en la lista',
          type: 'info',
        });
        return;
      }
      emailList.push(email);
    }
    const body = new Campus().deserialize(formik.values);
    body.correos = emailList;
    if (file.name === body.firmaCoordinador) {
      body.firmaCoordinador = 'sincambios:sincambios';
    } else if (!file.url) {
      body.firmaCoordinador = 'borrar:borrar';
    } else {
      body.firmaCoordinador = `agregar:${file.data.replace(
        'data:image/png;base64,',
        '',
      )}`;
    }

    switch (modalRecord.type) {
      case 'add':
        createCampus(body);
        return;
      case 'edit':
        updateCampus(body);
        return;
    }
  };

  const openPreview = () => {
    setPreviewImageModal({
      ...previewImageModal,
      image: file.data,
      open: true,
    });
  };

  const resetDataFile = () => {
    setFile({ ...file, name: '', url: null, data: null, blob: null });
  };

  const onLoaded = async (files: FileLoaded) => {
    const size = getFileSize(files.list[0].size);

    const url: string = files.list[0].preview;
    const blob: Blob = await fetch(url).then((r) => r.blob());
    const base64 = await convertBlobToBase64(blob);
    formik.setValues({ ...formik.values, firmaCoordinador: '' });
    setFile({
      ...file,
      url,
      data: base64,
      sizeDescription: size,
      name: files.list[0].name,
    });
  };

  const handleClose = () => {
    resetDataFile();

    changeModalRecord({
      ...modalRecord,
      show: false,
      campusEdit: null,
    });
    setPreviewImageModal({ ...previewImageModal, open: false, image: null });
    formik.resetForm();
  };

  const onDeleteFile = () => {
    resetDataFile();
  };

  useEffect(() => {
    if (modalRecord.campusEdit) {
      getCampusById(modalRecord.campusEdit);
    }
  }, [modalRecord]);
  return (
    <>
      {previewImageModal.open && (
        <PreviewImage
          image={previewImageModal.image}
          open={previewImageModal.open}
          handleClose={() =>
            setPreviewImageModal({ ...previewImageModal, open: false })
          }
        />
      )}
      <Dialog
        open={modalRecord.show}
        fullWidth
        onClose={handleClose}
        maxWidth={'md'}>
        <DialogTitle>
          {modalRecord.type === 'add'
            ? 'Crear un campus nuevo'
            : 'Editar los datos del campus'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'add' && (
            <>
              <ModalBar
                startIcon={<Add sx={{ color: 'grayCustom.main' }} />}
                title="Edita los datos del campus"
              />
            </>
          )}
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<EditSquareIcon />}
                title="Edita los datos del campus"
              />
            </>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="primary.darker"
                sx={{ fontWeight: 600, pb: 2 }}>
                DATOS DEL CAMPUS
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
              {modalRecord.type === 'add' && (
                <TextField
                  name="id"
                  label="Clave"
                  variant="outlined"
                  placeholder="Agrega una clave"
                  type="text"
                  fullWidth
                  size={'small'}
                  required
                  value={formik.values.id || ''}
                  onChange={formik.handleChange}
                  error={!!formik.errors.id}
                  helperText={formik.errors.id}
                  InputProps={{ inputProps: { maxLength: MAX_LENGTH_ID } }}
                />
              )}
              {modalRecord.type === 'edit' && (
                <FieldDisable
                  icon={<Key sx={{ color: 'grayCustom.main' }} />}
                  title="Clave"
                  subtitle={formik.values.id}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2 }}>
              <TextField
                name="nombre"
                label="Nombre del campus"
                placeholder="Agrega el nombre del campus"
                variant="outlined"
                type="text"
                fullWidth
                size={'small'}
                required
                value={formik.values.nombre || ''}
                onChange={formik.handleChange}
                error={!!formik.errors.nombre}
                helperText={formik.errors.nombre}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
              <TextField
                name="nombreCoordinador"
                label="Nombre del coordinador"
                placeholder="Agrega el nombre del coordinador"
                variant="outlined"
                type="text"
                fullWidth
                size={'small'}
                required
                value={formik.values.nombreCoordinador || ''}
                onChange={formik.handleChange}
                error={!!formik.errors.nombreCoordinador}
                helperText={formik.errors.nombreCoordinador}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2 }}>
              <TextField
                name="direccion"
                label="Dirección"
                placeholder="Agrega una dirección"
                variant="outlined"
                type="text"
                fullWidth
                size={'small'}
                required
                InputProps={{ inputProps: { maxLength: MAX_LENGTH_NAME } }}
                value={formik.values.direccion || ''}
                onChange={formik.handleChange}
                error={!!formik.errors.direccion}
                helperText={formik.errors.direccion}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <DragNDrop
                fileOptions={{
                  type: 'image',
                  maxSizeInMB: 1,
                  accept: { 'image/png': ['.png'] },
                }}
                existFile={file}
                title={
                  <Typography
                    variant="h6"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 600 }}>
                    Puedes arrastrar una imagen o{' '}
                    <Typography
                      variant="h6"
                      component="span"
                      color="primary.main"
                      sx={{ display: 'inline', fontWeight: 600 }}>
                      cargarla desde aquí
                    </Typography>{' '}
                  </Typography>
                }
                subtitle={
                  <Typography
                    variant="body1"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 500, fontSize: '12px' }}>
                    *El formato aceptado para la imagen de la firma es{' '}
                    <Typography
                      variant="body1"
                      component="span"
                      color="grayCustom.main"
                      sx={{
                        display: 'inline',
                        fontWeight: 500,
                        fontSize: '12px',
                      }}>
                      .png
                    </Typography>{' '}
                    <span style={{ display: 'block ' }}>
                      (Tamaño máximo 1MB)
                    </span>
                  </Typography>
                }
                onLoaded={onLoaded}
                onDelete={onDeleteFile}
                onPreview={openPreview}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ py: 2 }}>
              <Stack direction="row" sx={{ mb: 1 }}>
                <TextField
                  name="email"
                  label="Correo"
                  placeholder="Agrega los correos electrónicos"
                  variant="outlined"
                  type="text"
                  fullWidth
                  size={'small'}
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                  onKeyDown={handleKeyPress}
                />
                <Tooltip title="Agregar correo">
                  <IconButton
                    color="primary"
                    onClick={addEmail}
                    disabled={!!formik.errors.email}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Grid container>
                {formik.values.correos.map((correo: string, index: number) => (
                  <Grid item key={correo} sx={{ p: 1 }}>
                    <Chip
                      label={correo}
                      variant="outlined"
                      onDelete={() => deleteEmail(index)}
                      deleteIcon={<Clear sx={{ color: 'black !important' }} />}
                      sx={{
                        p: 1,
                        bgcolor: 'primary.light',
                        color: 'primary.dark',
                        fontWeight: 600,
                        border: 'none',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ pb: 2 }}>
            <Typography variant="subtitle2" color="grayCustom.main" sx={{}}>
              Activar el campus
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                name="activo"
                label=""
                checked={formik.values.activo}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
