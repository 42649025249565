import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, PeriodoDTO } from '../../../../utils/models';
import { PeriodTableColumn } from '../enums/PeriodTableColumn';
import { ModalRecordPeriod, PeriodState } from '../interface/periodState';

type PeriodAction =
  | { type: 'reloadPeriod'; periodList: PeriodoDTO[] }
  | { type: 'changePage'; page: number }
  | { type: 'changeStatus'; status: boolean; id: string }
  | {
      type: 'changePaginatorOptions';
      paginatorOptions: PageResult<PeriodoDTO[]>;
    }
  | {
      type: 'changeRowsPerPage';
      rowsPerPage: any;
    }
  | {
      type: 'changeSearch';
      search: string;
    }
  | {
      type: 'changeModal';
      modalRecord: ModalRecordPeriod;
    }
  | { type: 'setOrder'; orderBy: PeriodTableColumn; dir: DirectionOptions };

export const periodReducer = (
  state: PeriodState,
  action: PeriodAction,
): PeriodState => {
  let aux: PeriodoDTO[] = [];
  switch (action.type) {
    case 'reloadPeriod':
      return {
        ...state,
        periodList: action.periodList,
      };
    case 'changePage':
      return {
        ...state,
        page: action.page,
      };
    case 'changeStatus':
      aux = state.periodList;
      aux.forEach((item, index) => {
        if (item.id === action.id) {
          aux[index].activo = action.status;
        }
      });
      return {
        ...state,
        periodList: aux,
      };
    case 'changePaginatorOptions':
      return {
        ...state,
        paginatorOptions: action.paginatorOptions,
      };
    case 'changeRowsPerPage':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };
    case 'changeSearch':
      return {
        ...state,
        search: action.search,
      };
    case 'changeModal':
      return {
        ...state,
        modalRecord: { ...action.modalRecord },
      };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    default:
      return state;
      break;
  }
};
