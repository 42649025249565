export enum RoutesPath {
  DASHBOARD = '/',
  AUTH_LOGIN = '/Auth/login',
  AUTH_LOGOUT = '/Auth/logout',
  AUTH_SUCCESS = '/Auth/success',
  AUTH_ERROR = '/Auth/error-session',
  FOLLOW_UP_CERTIFICATE = '/seguimiento-constancia',
  FOLLOW_UP_CERTIFICATE_DETAIL = '/seguimiento-constancia/detalle',
  ADMIN_USERS = '/usuarios',
  ADMIN_CATALOG = '/catalogos',
  ADMIN_CATALOG_CAMPUS = '/catalogos/campus',
  ADMIN_CATALOG_CTT = '/catalogos/centro-trabajo',
  ADMIN_CATALOG_DIRECTOR = '/catalogos/directores',
  ADMIN_CATALOG_PERIOD = '/catalogos/periodos',
  ADMIN_CERTIFICATE = '/constancias',
  STUDENT_GENERAL = '/',
  STUDENT_CERTIFICATE_REQUEST = '/solicitud-constancia',
  STUDENT_CERTIFICATE_REQUEST_PROCESS = '/solicitud-constancia/proceso-solicitud',
  STUDENT_CERTIFICATE_HISTORY = '/constancias-historial',
  STUDENT_VALID_CERTIFICATE = '/constancia/:token',
  STUDENT_CERTIFICATE_PAY_SUCCESS = '/:certificateId',
  STUDENT_CERTIFICATE_PAY_ERROR = '/:certificateIdError/pago/error',
}
