import React from 'react';

export const EditCalendarPencilIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M3.75 18.333a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V4.167a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h1.354v-.584a.64.64 0 0 1 .198-.468.64.64 0 0 1 .469-.198c.194 0 .357.066.49.198a.64.64 0 0 1 .197.468v.584h7.084v-.584a.64.64 0 0 1 .198-.468.64.64 0 0 1 .468-.198c.195 0 .358.066.49.198a.64.64 0 0 1 .198.468v.584h1.354a1.2 1.2 0 0 1 .875.375c.25.25.375.541.375.875v6.27h-1.25V8.126H3.75v8.958h6.646v1.25H3.75Zm14.77-4.562-1.478-1.48.604-.604a.592.592 0 0 1 .437-.166c.18 0 .327.055.438.166l.604.605a.592.592 0 0 1 .167.437c0 .18-.056.327-.167.438l-.604.604Zm-6.874 5.083v-.916a.61.61 0 0 1 .041-.23.636.636 0 0 1 .146-.208l4.313-4.313 1.479 1.48-4.313 4.312a.636.636 0 0 1-.208.146.612.612 0 0 1-.229.042h-.917a.3.3 0 0 1-.218-.094.3.3 0 0 1-.094-.219Z" />
    </svg>
  );
};
