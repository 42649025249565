import moment, { unitOfTime } from 'moment';
import 'moment/locale/es';
moment.locale('es');
export class DateHelper {
  public static DATE_FORMAT = 'DD/MM/YYYY';

  public static convertDateString(dateString: string, format?: string): string {
    const date = moment(dateString);

    if (date.isValid()) {
      return date.format(format ? format : DateHelper.DATE_FORMAT);
    }
    return null;
  }

  public static convertDateToISO(date: Date): string {
    const d = moment(date);

    if (d.isValid()) {
      return d.toISOString();
    }
    return null;
  }

  public static convertDateToString(date: Date, format?: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.format(format ? format : DateHelper.DATE_FORMAT);
    }
    return null;
  }

  public static compareDates(
    startDate: Date,
    endDate: Date,
    unitOfTime: unitOfTime.Diff = 'ms',
  ): number {
    const start = moment(startDate);
    const end = moment(endDate);

    return end.diff(start, unitOfTime);
  }
}
