import { Deserializable } from '../interfaces';
import { DatoConstancia } from './dato-constancia';

export class DatosAlumnoConstancia implements Deserializable {
  noEditables: DatoConstancia[];
  editablesAlumno: DatoConstancia[];
  editableEscolar: DatoConstancia[];

  constructor() {
    this.noEditables = [];
    this.editablesAlumno = [];
    this.editableEscolar = [];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
