import { CertificatePage } from './components/CertificatePage/CertificatePage';
import { CertificateProvider } from './context/CertificateProvider';

export function Certificates() {
  return (
    <CertificateProvider>
      <CertificatePage />
    </CertificateProvider>
  );
}
