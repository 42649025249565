import { Deserializable } from '../interfaces';
import { DatoConstancia } from './dato-constancia';

export class SolicitudDetalle implements Deserializable {
  id: number;
  nombre: string;
  descripcion: string;
  matricula: string;
  alumno: string;
  folio: string;
  estatus: string;
  estatusId: number;
  fechaSolicitud: string;
  fechaEmision: string;
  fechaVigencia: string;
  esCosto: boolean;
  costo: number;
  esAprobacionEscolar: boolean;
  noEditables: DatoConstancia[];
  editablesAlumno: DatoConstancia[];
  editableEscolar: DatoConstancia[];
  esDescargada: boolean;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.descripcion = null;
    this.matricula = null;
    this.alumno = null;
    this.folio = null;
    this.estatus = null;
    this.estatusId = null;
    this.fechaSolicitud = null;
    this.fechaEmision = null;
    this.fechaVigencia = null;
    this.esCosto = null;
    this.costo = null;
    this.esAprobacionEscolar = null;
    this.noEditables = [];
    this.editablesAlumno = [];
    this.editableEscolar = [];
    this.esDescargada = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
