import { useContext } from 'react';
import { PeriodContext } from '../context/PeriodContext';

export const usePeriods = () => {
  const {
    periodState,
    reloadPeriod,
    changePage,
    changeStatus,
    changePaginatorOptions,
    changeRowsPerPage,
    changeSearch,
    changeModal,
    setOrder,
  } = useContext(PeriodContext);
  const {
    periodList,
    paginatorOptions,
    page,
    rowsPerPage,
    search,
    modalRecord,
    orderBy,
    dir,
  } = periodState;

  return {
    periodList,
    reloadPeriod,
    paginatorOptions,
    changePaginatorOptions,
    page,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    search,
    changeSearch,
    modalRecord,
    changeModal,
    orderBy,
    dir,
    setOrder,
    changeStatus,
  };
};
