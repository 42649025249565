import { UserPage } from './components/UserPage/UserPage';
import { UserProvider } from './context/UserProvider';

export const Users = () => {
  return (
    <UserProvider>
      <UserPage></UserPage>
    </UserProvider>
  );
};
