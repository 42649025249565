import { Button, Grid, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import { TrashFillIcon, UploadIcon } from '../../assets/icons';
import PdfIcon from '../../assets/icons/PdfIcon.svg';
import PngIcon from '../../assets/icons/PngIcon.svg';
import { useNotify } from '../../hooks';
import { DataFile } from '../../utils/interfaces';
import {
  Notification,
  NotifyDefaultOptions,
  NotifyOptions,
} from '../Notification/Notification';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface FileLoaded {
  list: Array<File & { preview: string }>;
}

interface FileOptions {
  maxSizeInMB: number;
  type: 'pdf' | 'image';
  accept: Accept;
}

interface Props {
  title?: JSX.Element;
  subtitle?: JSX.Element;
  size?: JSX.Element;
  existFile?: DataFile;
  fileOptions?: FileOptions;
  onLoaded?: (files: FileLoaded) => void;
  onPreview: () => void;
  onDelete: () => void;
}

export const DragNDrop: FC<Props> = ({
  title,
  subtitle,
  size,
  existFile,
  fileOptions,
  onLoaded,
  onPreview,
  onDelete,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [files, setFiles] = useState<FileLoaded>({ list: [] });
  const [maxSize, setMaxSize] = useState<number>(0);
  const { notify, setNotify } = useNotify();
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    accept: fileOptions.accept,
    maxFiles: 1,
    maxSize,
    onDrop: (acceptedFiles: File[], rejectedFiles) => {
      if (acceptedFiles.length === 0 && rejectedFiles.length === 0) {
        setNotify({
          ...notify,
          open: true,
          message: 'Favor de seleccionar un archivo',
          type: 'info',
        });
        return;
      }
      if (rejectedFiles.length > 0) {
        setNotify({
          ...notify,
          open: true,
          message: 'Favor de seleccionar un archivo válido',
          type: 'info',
        });
        return;
      }

      if (acceptedFiles[0].size > maxSize) {
        setNotify({
          ...notify,
          open: true,
          message: `Favor de agregar un archivo de máximo ${fileOptions.maxSizeInMB}MB`,
          type: 'info',
        });
        return;
      }

      const fileList = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles({ list: fileList });
      handleOnLoaded({ list: fileList });
    },
  });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  const handleOnLoaded = (fileList: FileLoaded) => {
    onLoaded(fileList);
  };

  const handleDeleteFile = () => {
    setFiles({ list: [] });
    onDelete();
  };

  const handleShowPreview = () => {
    onPreview();
  };

  const handleErrorLoadPDF = () => {
    setNotify({
      ...notify,
      open: true,
      message: 'Error al cargar el archivo',
      type: 'error',
    });
  };

  useEffect(() => {
    const size: number = 1e6 * fileOptions.maxSizeInMB;
    setMaxSize(size);
  }, [fileOptions.maxSizeInMB, existFile]);

  return (
    <>
      <Grid
        {...getRootProps({ className: 'dropzone' })}
        container
        alignItems="center"
        justifyContent="center"
        py={4}
        my={2}
        sx={{
          position: 'relative',
          border: '1px dashed ',
          borderColor: 'grayCustom.light',
          borderRadius: '8px',
          borderWidth: '4px',
          borderStyle: 'dashed',
          height: '200px',
          maxHeight: '400px',
          overflow: 'hidden',
        }}>
        <input {...getInputProps()} />

        {existFile.data && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                borderRadius: 50,
                fontWeight: 600,
                bgcolor: 'primary.light',
                color: 'primary.main',
                textTransform: 'uppercase',
                zIndex: 1000,
                py: 0,
                '&:hover': {
                  color: 'white',
                  bgcolor: 'primary.main',
                },
              }}
              onClick={handleShowPreview}>
              VISTA PREVIA
            </Button>
            {fileOptions.type === 'image' && (
              <Grid item container xs={12} md={8} justifyContent="center">
                <img
                  src={existFile.data}
                  alt={existFile.name}
                  style={{ maxHeight: '200px' }}
                />
              </Grid>
            )}

            {fileOptions.type === 'pdf' && (
              <Grid item xs={12}>
                <Document
                  noData="Error al obtener la vista previa"
                  file={existFile.data}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={handleErrorLoadPDF}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </Grid>
            )}
          </>
        )}
        {!existFile.data && (
          <>
            <Grid item container alignItems="center" justifyContent="center">
              <UploadIcon />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              onClick={open}
              sx={{ cursor: 'pointer' }}>
              {title && title}
            </Grid>
            <Grid item xs={6} container justifyContent="center">
              {subtitle && subtitle}
            </Grid>
          </>
        )}
      </Grid>

      {existFile.data && (
        <Grid
          container
          justifyContent="space-between"
          py={2}
          pl={1}
          mb={4}
          sx={{ bgcolor: 'grayCustom.light' }}>
          <Grid item container xs={10}>
            <Grid
              item
              xs={2}
              md={1}
              sx={{ display: { xs: 'none', md: 'block' } }}>
              {fileOptions.type === 'image' && (
                <img src={PngIcon} alt="image" style={{ minWidth: '60px' }} />
              )}
              {fileOptions.type === 'pdf' && <img src={PdfIcon} alt="pdf" />}
            </Grid>
            <Grid item xs={10} md={11} pl={1}>
              <Typography
                variant="subtitle1"
                color="grayCustom.main"
                sx={{ fontWeight: 600 }}>
                {existFile.name}
              </Typography>
              <Typography variant="body1" color="grayCustom.main">
                {existFile.sizeDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            justifyContent="flex-end"
            alignItems="center"
            pr={2}>
            <IconButton
              sx={{ width: '40px', height: '40px' }}
              onClick={handleDeleteFile}>
              <TrashFillIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
