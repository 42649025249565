import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  ConfirmDialog,
  ContainerTable,
  ToolbarModule,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CertificatesService } from '../../../../../services';
import { Constancia } from '../../../../../utils/models';
import { useCertificates } from '../../hooks/useCertificates';
import { CertificateRecord } from '../../modals/CertificateRecord/CertificateRecord';
import { CertificateSearch } from '../CertificateSearch/CertificateSearch';
import { CertificateTable } from '../CertificateTable/CertificateTable';

export const CertificatePage = () => {
  const certificatesService = new CertificatesService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    setCertificateList,
    search,
    modalRecord,
    setPaginatorOptions,
    orderBy,
    dir,
    page,
    rowsPerPage,
    setPage,
    confirmDialog,
    setConfirmDialog,
  } = useCertificates();

  const getAllCertificates = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await certificatesService
      .getAllCertificate(filtro, pageSize, pageNumber, ordenar, dir, true)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró una constancia',
              type: 'info',
            });
          }
          const data: Constancia[] = res.data.data.map(
            (certificate: Constancia) =>
              new Constancia().deserialize(certificate),
          );
          setCertificateList(data);
          setPaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado al obtener las constancias',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllCertificates(search, pageSize, pageNumber, orderBy, asc);
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    setPage(valuePage);
    getAllCertificates(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllCertificates(search, rowsPerPage, page, orderBy, dir);
  };

  useEffect(() => {
    onReloadData();
  }, []);

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {modalRecord.show && <CertificateRecord onReloadData={onReloadData} />}
      <Grid container>
        <ToolbarModule
          title={'Administrar constancias'}
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Gestiona y configura los registros de constancias disponibles en
                la plataforma.
              </Typography>
            </>
          }></ToolbarModule>
        <ContainerTable
          title={'Constancias'}
          actions={
            <>
              <Grid container px={0}>
                <CertificateSearch onSearch={onSearch} />
              </Grid>
            </>
          }
          table={
            <Grid item md={12} sx={{ mt: 2 }}>
              <CertificateTable
                onReloadData={onReloadData}
                onTableFilter={onTableFilter}
              />
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
