import { useContext } from 'react';
import { RequestProcessContext } from '../context/RequestProcessContext';

export const useRequestProcess = () => {
  const {
    requestProcessState,
    setCertificate,
    setVerifyDataTab,
    setPayTab,
    setDownloadTab,
    setPreviewData,
    setProgressBar,
    setCurrentTabIndex,
  } = useContext(RequestProcessContext);
  const {
    certificate,
    previewFile,
    verifyDataTab,
    payTab,
    downloadTab,
    progressBar,
    currentTabIndex,
  } = requestProcessState;
  return {
    certificate,
    setCertificate,
    verifyDataTab,
    setVerifyDataTab,
    payTab,
    setPayTab,
    downloadTab,
    setDownloadTab,
    previewFile,
    setPreviewData,
    progressBar,
    setProgressBar,
    currentTabIndex,
    setCurrentTabIndex,
  };
};
