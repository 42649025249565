import { useContext } from 'react';
import { DirectorContext } from '../context/DirectorsContext';

export const useDirectors = () => {
  const {
    directorState,
    reloadDirector,
    changePage,
    changeStatus,
    changePaginatorOptions,
    changeRowsPerPage,
    changeModalRecord,
    changeSearch,
    setOrder,
  } = useContext(DirectorContext);
  const {
    directorList,
    paginatorOptions,
    page,
    rowsPerPage,
    search,
    modalRecord,
    orderBy,
    dir,
  } = directorState;

  return {
    directorList,
    paginatorOptions,
    page,
    rowsPerPage,
    search,
    modalRecord,
    reloadDirector,
    changePage,
    changeStatus,
    changePaginatorOptions,
    changeRowsPerPage,
    changeModalRecord,
    changeSearch,
    orderBy,
    dir,
    setOrder,
  };
};
