import { Button } from '@mui/material';
import { FC } from 'react';

export interface ButtonOptions {
  backgorund: string;
  color: string;
  backgorundHover: string;
  colorHover: string;
  fullWidth: boolean;
  px?: number;
  py?: number;
  fontSize?: string;
}

interface Props {
  icon: JSX.Element;
  button: ButtonOptions;
  title: string;
}

export const StatusButton: FC<Props> = ({ icon, button, title }) => {
  return (
    <Button
      variant="contained"
      fullWidth={button.fullWidth}
      disableRipple
      disableElevation
      sx={{
        px: button.px ? button.px : 3,
        py: button.py ? button.py : 1,
        bgcolor: button.backgorund,
        color: button.color,
        textTransform: 'uppercase',
        fontSize: button.fontSize ? button.fontSize : '14px',
        fontWeight: 600,
        cursor: 'default',
        display: 'flex',
        justifyContent: 'left',
        '&:hover': {
          bgcolor: button.backgorundHover,
          color: button.colorHover,
          cursor: 'default',
        },
      }}
      startIcon={icon}>
      {title}
    </Button>
  );
};
