export const AdminCertificate = (props: any) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M17.8747 18.3332H5.95801C5.59134 18.3332 5.27051 18.1957 4.99551 17.9207C4.72051 17.6457 4.58301 17.3248 4.58301 16.9582V2.2915C4.58301 1.92484 4.72051 1.604 4.99551 1.329C5.27051 1.054 5.59134 0.916504 5.95801 0.916504H13.3601C13.5434 0.916504 13.7229 0.954698 13.8986 1.03109C14.0743 1.10748 14.2233 1.20678 14.3455 1.329L18.8372 5.82067C18.9594 5.94289 19.0587 6.09185 19.1351 6.26755C19.2115 6.44324 19.2497 6.62275 19.2497 6.80609V16.9582C19.2497 17.3248 19.1122 17.6457 18.8372 17.9207C18.5622 18.1957 18.2413 18.3332 17.8747 18.3332ZM13.2455 2.2915H5.95801V16.9582H17.8747V6.829H13.933C13.7344 6.829 13.5702 6.76407 13.4403 6.63421C13.3104 6.50435 13.2455 6.34011 13.2455 6.1415V2.2915ZM3.20801 21.0832C2.84134 21.0832 2.52051 20.9457 2.24551 20.6707C1.97051 20.3957 1.83301 20.0748 1.83301 19.7082V6.21025C1.83301 6.01164 1.89794 5.84741 2.0278 5.71755C2.15766 5.58768 2.3219 5.52275 2.52051 5.52275C2.71912 5.52275 2.88335 5.58768 3.01322 5.71755C3.14308 5.84741 3.20801 6.01164 3.20801 6.21025V19.7082H13.933C14.1316 19.7082 14.2959 19.7731 14.4257 19.903C14.5556 20.0328 14.6205 20.1971 14.6205 20.3957C14.6205 20.5943 14.5556 20.7585 14.4257 20.8884C14.2959 21.0182 14.1316 21.0832 13.933 21.0832H3.20801ZM5.95801 2.2915V6.829V2.2915V16.9582V2.2915Z" />
    </svg>
  );
};
