export const LabelCheckIcon = (props: any) => {
  return (
    <svg
      width={22}
      height={22}
      fill="#00AB55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.408 7.86a.673.673 0 0 1-.252-.045.697.697 0 0 1-.23-.16L14.46 6.187a.672.672 0 0 1-.206-.493.6.6 0 0 1 .206-.47.575.575 0 0 1 .47-.23c.19 0 .355.07.493.207l.985.986 2.91-2.934a.66.66 0 0 1 .482-.206c.183 0 .351.069.504.206a.731.731 0 0 1 .183.504.65.65 0 0 1-.183.482l-3.415 3.414a.698.698 0 0 1-.229.16.673.673 0 0 1-.252.046ZM5.546 18.838a.628.628 0 0 1-.654-.046.652.652 0 0 1-.309-.573V4.01c0-.366.138-.687.413-.962a1.32 1.32 0 0 1 .962-.413h7.7a4.244 4.244 0 0 0-.79 1.272c-.176.451-.264.944-.264 1.479 0 1.023.328 1.917.985 2.68a4.052 4.052 0 0 0 2.452 1.399c.26.03.49.046.688.046.198 0 .428-.016.687-.046v8.754a.652.652 0 0 1-.309.573.628.628 0 0 1-.653.046L11 16.5l-5.454 2.338Z" />
    </svg>
  );
};
