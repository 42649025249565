import { DirectionOptions } from '../../../../utils/enums';
import { Director, PageResult } from '../../../../utils/models';
import { DirectorTableColumn } from '../enums/DirectorTableColumn';
import { DirectorState, ModalRecordDirector } from '../interfaces/interfaces';

type DirectorAction =
  | { type: 'reloadDirector'; directorList: Director[] }
  | { type: 'changePage'; page: number }
  | { type: 'changeStatus'; status: boolean; director: Director }
  | {
      type: 'changePaginatorOptions';
      paginatorOptions: PageResult<Director[]>;
    }
  | {
      type: 'changeRowsPerPage';
      rowsPerPage: any;
    }
  | {
      type: 'changeSearch';
      search: string;
    }
  | {
      type: 'changeModalRecord';
      modalRecord: ModalRecordDirector;
    }
  | {
      type: 'setOrder';
      orderBy: DirectorTableColumn;
      dir: DirectionOptions;
    };

export const directorReducer = (
  state: DirectorState,
  action: DirectorAction,
): DirectorState => {
  switch (action.type) {
    case 'reloadDirector':
      return {
        ...state,
        directorList: action.directorList,
      };
    case 'changePage':
      return {
        ...state,
        page: action.page,
      };
    case 'changeStatus':
      return {
        ...state,
        directorList: state.directorList.map(({ ...item }) => {
          if (
            item.nivel === action.director.nivel &&
            item.campus === action.director.campus
          ) {
            item.activo = action.status;
          }
          return new Director().deserialize(item);
        }),
      };
    case 'changePaginatorOptions':
      return {
        ...state,
        paginatorOptions: action.paginatorOptions,
      };
    case 'changeRowsPerPage':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };
    case 'changeModalRecord':
      return {
        ...state,
        modalRecord: action.modalRecord,
      };
    case 'changeSearch':
      return {
        ...state,
        search: action.search,
      };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    default:
      return state;
  }
};
