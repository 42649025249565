import { CampusIPID } from '../../utils/enums';
import { Auth } from '../../utils/helpers';
import { Response } from '../../utils/interfaces';
import {
  DatoConstancia,
  PageResult,
  SolicitudDetalle,
} from '../../utils/models';
import http, { BASE_URL } from '../http/http';

export class RequestProcessService {
  getCertificateDetailById(certificateId: number) {
    return http.get<Response<PageResult<SolicitudDetalle>>>(
      `/Solicitud/${certificateId}`,
    );
  }

  getNewCertificateDetailById(certificateId: number) {
    return http.post<Response<PageResult<SolicitudDetalle>>>(
      `/Solicitud/Constancia/${certificateId}`,
    );
  }

  sendToSchoolApproval(certificateId: number, fieldList: DatoConstancia[]) {
    return http.post<Response<never>>(
      `/Solicitud/${certificateId}/EnviarRevision`,
      fieldList,
    );
  }

  sendToSaveStudentFields(certificateId: number, fieldList: DatoConstancia[]) {
    return http.put<Response<never>>(`/Solicitud/${certificateId}`, fieldList);
  }

  completeStudentFields(certificateId: number, fieldList: DatoConstancia[]) {
    return http.post<Response<never>>(
      `/Solicitud/${certificateId}/Completar`,
      fieldList,
    );
  }

  getCertificatePreview(certificateId: number) {
    return http.get<Blob>(`/Solicitud/${certificateId}/VistaPrevia`, {
      responseType: 'blob',
    });
  }

  getURLToDownloadCertificate(certificateId: number) {
    return http.get<Response<string>>(`/Solicitud/${certificateId}/Descargar`);
  }

  getURLToPay(certificateId: number): string {
    const session = Auth.getSession();
    if (!session) {
      return null;
    }
    return `${BASE_URL}/Pago/Solicitud/${certificateId}/${session.id}`;
  }

  getURLToQuiz(): string {
    const session = Auth.getSession();
    let ipId: string = CampusIPID.LT.toString();
    const key = CampusIPID[session.campus];

    console.log('campus', session, key);

    if (session && key) {
      ipId = key;
    }

    if (
      !!session &&
      !!session.campus &&
      ['CEL', 'CC'].find((x) => x === session.campus.toString())
    ) {
      return 'https://sitiosmiespacio.itesm.mx/sites/TecMilenio/EncuestasOnline/Pages/IServicios1.aspx';
    }

    return `https://sitiosmiespacio.itesm.mx/sites/TecMilenio/EncuestasQA/Pages/IServicios11.aspx?ip_id=${ipId}`;
  }
}
