import { ThemeProvider } from '@mui/material';
import { theme } from '../../assets/css/theme';
import { AppRouter } from '../../routes';
import '../../assets/css/style.css';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter></AppRouter>
    </ThemeProvider>
  );
}

export default App;
