export enum RequestProcessStep {
  'VERIFY_DATA' = 0,
  'MAKE_PAY' = 1,
  'DOWNLOAD_CERTIFICATE' = 2,
}

export enum ProcessBarTitle {
  'VERIFY_DATA' = '¡Falta muy poco!',
  // 'MAKE_PAY' = '¡Falta muy poco!',
  'MAKE_PAY' = 'Pago no procesado',
  'DOWNLOAD_CERTIFICATE' = '¡Ya puedes descargar tu constancia electrónica!',
}
