import { Auth } from './auth';

export const toCurrencyFormat = (value: number): string => {
  return new Intl.NumberFormat('es-Mx', {
    style: 'currency',
    currency: 'MXN',
  }).format(value) as string;
};

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeAccentsAndLowerCase = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const getFileName = (certificateName: string): string => {
  certificateName = certificateName.replace(/[.]/g, '');
  const certificateNameArr: string[] =
    removeAccents(certificateName).split(' ');
  const userNameArr: string[] = removeAccents(Auth.getSession().nombre).split(
    ' ',
  );
  return `${certificateNameArr.join('-')}-${userNameArr.join('-')}`;
};
