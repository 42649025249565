import { Add, Apartment, Close, LibraryBooks } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { EditSquareIcon } from '../../../../assets/icons';
import { FieldDisable, ModalBar } from '../../../../components';
import { useLoading, useNotify } from '../../../../hooks';
import { CampusService, WorkPlaceService } from '../../../../services';
import { validationMsj } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { Campus, ClaveCentroTrabajo } from '../../../../utils/models';
import { CampusTableColumn } from '../../Campus/enums/CampusTableColumn';
import { useWorkPlace } from '../hook/useWorkPlace';

interface Props {
  onReloadData: () => void;
}

const validationSchema = Yup.object({
  programa: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
  campus: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
  cct: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
});

export const WorkPlaceRecord: FC<Props> = ({ onReloadData }) => {
  const campusService = new CampusService();
  const workPlaceService = new WorkPlaceService();

  const { modalRecord, setModal } = useWorkPlace();
  const { setLoading } = useLoading();
  const [campusList, setCampusList] = useState<Campus[]>([]);
  const { notify, setNotify } = useNotify();

  const formik = useFormik({
    initialValues: {
      programa: '',
      campus: '',
      cct: '',
      activo: true,
    },
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    if (!formik.isValid) {
      return;
    }

    const body = new ClaveCentroTrabajo().deserialize(formik.values);

    switch (modalRecord.type) {
      case 'add':
        body.activo = true;
        createWorkPlaceKey(body);
        return;
      case 'edit':
        updateWorkPlaceKey(body);
        return;
    }
  };

  const getAllCampus = async () => {
    setLoading(true);
    await campusService
      .getAllCampus(
        '',
        -1,
        1,
        CampusTableColumn.NOMBRE,
        DirectionOptions.ASC,
        false,
      )
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Campus[] = res.data.data.map((user) =>
            new Campus().deserialize(user),
          );
          setCampusList(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const createWorkPlaceKey = async (workPlaceKey: ClaveCentroTrabajo) => {
    setLoading(true);
    await workPlaceService
      .createWorkCenterKey(workPlaceKey)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se creó el CCT correctamente.',
            type: 'success',
          });
          setModal({
            ...modalRecord,
            show: false,
            campusId: null,
            programa: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateWorkPlaceKey = async (workPlaceKey: ClaveCentroTrabajo) => {
    setLoading(true);
    await workPlaceService
      .updateWorkCenterKey(workPlaceKey)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se actualizó el CCT correctamente.',
            type: 'success',
          });
          setModal({
            ...modalRecord,
            show: false,
            campusId: null,
            programa: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getWorkCenterKeyById = async (campusId: string, program: string) => {
    setLoading(true);
    await workPlaceService
      .getWorkCenterKeyById(campusId, program)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          formik.setValues({
            ...formik.values,
            programa: res.data.programa,
            campus: res.data.campus,
            cct: res.data.cct,
            activo: res.data.activo,
          });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setError = (value: boolean) => {
    return value ? 'El campo es obligatorio' : '';
  };

  const handleClose = () => {
    setModal({
      ...modalRecord,
      show: false,
      campusId: null,
      programa: null,
    });
    formik.resetForm();
  };

  useEffect(() => {
    if (modalRecord.show) {
      formik.resetForm();
      getAllCampus();
    }

    if (modalRecord.show && modalRecord.type === 'edit') {
      getWorkCenterKeyById(modalRecord.campusId, modalRecord.programa);
    }
  }, [modalRecord]);

  return (
    <>
      <Dialog open={modalRecord.show} fullWidth maxWidth={'md'}>
        <DialogTitle>
          {modalRecord.type === 'add'
            ? 'Crear un centro de trabajo nuevo'
            : 'Editar los datos del centro de trabajo'}

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'add' && (
            <>
              <ModalBar
                startIcon={<Add sx={{ color: 'grayCustom.main' }} />}
                title="Crea un centro de trabajo nuevo"
              />
            </>
          )}
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<EditSquareIcon />}
                title="Edita los datos del centro de trabajo"
              />
            </>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{ fontWeight: 600, pb: 2 }}>
                DATOS DEL CENTRO DE TRABAJO
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
              {modalRecord.type === 'add' && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  error={Boolean(formik.errors.campus)}>
                  <InputLabel sx={{ pt: 1 }}>Campus</InputLabel>
                  <Select
                    autoWidth
                    value={formik.values.campus}
                    label="Campus"
                    name="campus"
                    required
                    onChange={(event) => {
                      formik.setFieldValue('campus', event.target.value);
                    }}
                    inputProps={{
                      readOnly: modalRecord.type === 'add' ? false : true,
                    }}>
                    {campusList.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {setError(Boolean(formik.errors.campus))}
                  </FormHelperText>
                </FormControl>
              )}
              {modalRecord.type === 'edit' && (
                <FieldDisable
                  icon={<Apartment sx={{ color: 'grayCustom.main' }} />}
                  title="Campus"
                  subtitle={formik.values.campus}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2 }}>
              {modalRecord.type === 'add' && (
                <TextField
                  value={formik.values.programa || ''}
                  onChange={formik.handleChange}
                  name="programa"
                  label="Programa"
                  placeholder="Agrega el nombre del programa"
                  variant="outlined"
                  type="text"
                  fullWidth
                  required
                  sx={{
                    '& input': {
                      paddingY: '14px',
                    },
                  }}
                  inputProps={{
                    maxLength: 15,
                    readOnly: modalRecord.type === 'add' ? false : true,
                  }}
                  error={Boolean(formik.errors.programa)}
                  helperText={formik.errors.programa}
                />
              )}
              {modalRecord.type === 'edit' && (
                <FieldDisable
                  icon={<LibraryBooks sx={{ color: 'grayCustom.main' }} />}
                  title="Programa"
                  subtitle={formik.values.programa}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
              <TextField
                value={formik.values.cct || ''}
                onChange={formik.handleChange}
                name="cct"
                label="CCT"
                placeholder="Agrega el nombre del centro"
                variant="outlined"
                type="text"
                fullWidth
                required
                sx={{
                  '& input': {
                    paddingY: '14px',
                  },
                }}
                inputProps={{
                  maxLength: 25,
                }}
                error={Boolean(formik.errors.cct)}
                helperText={formik.errors.cct}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {modalRecord.type === 'add' ? 'Crear CCT' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
