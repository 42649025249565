export const EditSquareOutlinedIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.75 18.27a1.2 1.2 0 0 1-.875-.374 1.2 1.2 0 0 1-.375-.875V4.52a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h8.438l-1.25 1.25H3.75v12.5h12.5V9.77l1.25-1.25v8.5a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H3.75ZM13.792 3.439l.896.874L8.75 10.23v1.792h1.77l5.96-5.959.874.875-5.937 5.959a1.302 1.302 0 0 1-.407.27c-.16.07-.322.105-.49.105H8.126a.607.607 0 0 1-.625-.625V10.25c0-.167.035-.33.104-.49a1.3 1.3 0 0 1 .271-.406l5.917-5.916Zm3.562 3.5-3.562-3.5 2.083-2.084c.236-.236.531-.354.885-.354.355 0 .65.125.886.375l1.75 1.77c.236.25.354.546.354.886 0 .34-.125.629-.375.865l-2.02 2.042Z" />
    </svg>
  );
};
