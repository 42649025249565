import { createContext, FC, useState } from 'react';
import {
  Notification,
  NotifyDefaultOptions,
  NotifyOptions,
} from '../components';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface NotificationContextProps {
  notify: NotifyOptions;
  setNotify: (notify: NotifyOptions) => void;
}

export const NotificationContext = createContext(
  {} as NotificationContextProps,
);

export const NotificationProvider: FC<Props> = ({ children }) => {
  const [notify, setNotify] = useState<NotifyOptions>(NotifyDefaultOptions);
  return (
    <NotificationContext.Provider value={{ notify, setNotify }}>
      <Notification notify={notify} setNotify={setNotify} />
      {children}
    </NotificationContext.Provider>
  );
};
