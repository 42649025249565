import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  icon: JSX.Element;
  title: string;
  subtitle?: string;
}

export const FieldDisable: FC<Props> = ({ icon, title, subtitle }) => {
  return (
    <Grid container py={1}>
      <Grid item container xs={2} pt={1}>
        {icon}
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant="subtitle1"
          color="grayCustom.main"
          sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            color="grayCustom.main"
            sx={{ fontWeight: 400 }}>
            {subtitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
