export enum FollowUpCertificateTableColumn {
  CLAVE = 'Clave',
  FOLIO = 'Folio',
  MATRICULA = 'Matricula',
  ALUMNO = 'Alumno',
  CAMPUS = 'Campus',
  EMISION = 'Emision',
  ESTATUS = 'Estatus',
  NOMBRE = 'Nombre',
  FECHA_CREACION = 'fechacreacion',
  VERIFICADO_POR = 'verificadoPor',
  FECHA_VERIFICAD_POR = 'fechaVerificadoPor',
  APROBADO_POR = 'aprobadoPor',
  FECHA_APROBACION = 'fechaAprobacion',
}
