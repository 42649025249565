import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { MarkChatReadIcon } from '../../assets/icons';
import { EmptyDialog, ModalBar } from '..';
import { SolicitudAlumno } from '../../utils/models';

interface Props {
  request: SolicitudAlumno;
  onClose: () => void;
}

export const RejectedMessage: FC<Props> = ({ request, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <EmptyDialog
      dialog={{ open: !!request.rechazo }}
      dialogTitle={{
        title: 'Solicitud de constancia rechazada',
        onClose: handleClose,
      }}
      dialogContent={{
        children: (
          <>
            <Grid container>
              <ModalBar
                startIcon={
                  <MarkChatReadIcon sx={{ color: 'grayCustom.main' }} />
                }
                title="Mensaje de rechazo"
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {request.rechazo}
              </Typography>
            </Grid>
          </>
        ),
      }}
      dialogActions={{
        cancelText: 'Cerrar',
        onClose: handleClose,
      }}
    />
  );
};
