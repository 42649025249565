import { Response } from '../../utils/interfaces';
import { PageResult, Director } from '../../utils/models';
import http from '../http/http';

export class DirectorService {
  getAllDirector(
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<Director[]>>>('/Director', {
      params: { filtro, pageSize, pageNumber, ordenar, dir, inactivos },
    });
  }

  getDirectorByIds(levelId: string, campusId: string) {
    return http.get<Response<Director[]>>(
      `/Director/${levelId}/campus/${campusId}`,
    );
  }

  createDirector(director: Director) {
    return http.post<Response<never>>('/Director', director);
  }

  updateDirector(director: Director) {
    return http.put<Response<never>>('/Director', director);
  }

  updateStatusDirector(levelId: string, campusId: string, status: boolean) {
    return http.post<Response<never>>(
      `/Director/${levelId}/campus/${campusId}/Estatus`,
      status,
    );
  }

  deleteDirector(levelId: string, campusId: string) {
    return http.delete<Response<never>>(
      `/Director/${levelId}/campus/${campusId}`,
    );
  }

  getURLElectronicSignature(levelId: string, campusId: string) {
    return http.get<Response<string>>(
      `/Director/${levelId}/campus/${campusId}/Firma`,
    );
  }
}
