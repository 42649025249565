import { WorkPlacePage } from './components/WorkPlacePage/WorkPlacePage';
import { WorkPlaceProvider } from './context/WorkPlaceProvider';

export function WorkPlaces() {
  return (
    <WorkPlaceProvider>
      <WorkPlacePage></WorkPlacePage>
    </WorkPlaceProvider>
  );
}
