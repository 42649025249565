import { Deserializable } from '../interfaces';

export class PeriodoDTO implements Deserializable {
  id?: string;
  nombre?: string;
  fechaVacacionesInicio?: string;
  fechaVacacionesFin?: string;
  polizaNumero?: string;
  polizaFechaVigencia?: string;
  activo: boolean;

  constructor() {
    this.id = '';
    this.nombre = '';
    this.fechaVacacionesInicio = null;
    this.fechaVacacionesFin = null;
    this.polizaNumero = '';
    this.polizaFechaVigencia = null;
    this.activo = false;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Periodo implements Deserializable {
  periodoId?: string;
  nombre?: string;
  fechaVacacionesInicio?: string;
  fechaVacacionesFin?: string;
  polizaNumero?: string;
  polizaFechaVigencia?: string;
  activo: boolean;
  fechaCreacion?: string;
  usuarioCreacion?: string;
  fechaModificacion?: string;
  usuarioModificacion?: string;

  constructor() {
    this.periodoId = '';
    this.nombre = '';
    this.fechaVacacionesInicio = '';
    this.fechaVacacionesFin = '';
    this.polizaNumero = '';
    this.polizaFechaVigencia = '';
    this.activo = false;
    this.fechaCreacion = '';
    this.usuarioCreacion = '';
    this.fechaModificacion = '';
    this.usuarioModificacion = '';
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
