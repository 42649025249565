import React from 'react';

export const UploadIcon = (props: any) => {
  return (
    <svg
      width={56}
      height={56}
      fill="#00AB55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M14.64 46.668c-3.422 0-6.329-1.196-8.72-3.588-2.392-2.392-3.588-5.298-3.588-8.72 0-3.034.972-5.698 2.917-7.992 1.944-2.295 4.414-3.675 7.408-4.142.778-3.772 2.606-6.854 5.483-9.246 2.878-2.392 6.203-3.587 9.975-3.587 4.356 0 8.03 1.584 11.025 4.754 2.995 3.17 4.492 6.97 4.492 11.404v1.4c2.8-.078 5.172.826 7.117 2.712 1.944 1.886 2.916 4.268 2.916 7.146 0 2.684-.972 4.998-2.916 6.942-1.945 1.944-4.259 2.917-6.942 2.917H29.749v-18.55l3.616 3.616c.35.35.768.515 1.255.496a1.82 1.82 0 0 0 1.254-.554c.35-.35.525-.768.525-1.254s-.175-.904-.525-1.254l-6.65-6.592c-.35-.35-.759-.525-1.225-.525-.467 0-.875.175-1.225.525l-6.65 6.65c-.35.35-.516.758-.496 1.225.02.467.204.875.554 1.225.35.35.768.525 1.254.525s.904-.175 1.254-.525l3.559-3.558v18.55H14.64Z" />
    </svg>
  );
};
