import React from 'react';

export const TecmilenioIcon = (props: any) => {
  return (
    <svg
      width={39}
      height={39}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.125 34v-8.109c0-.708.07-1.4.201-2.07a17.43 17.43 0 0 0-1.285-5.417 12.348 12.348 0 0 0-1.664 6.017l-.002.203V34h-1.75v-9.391l-.002-.188a12.353 12.353 0 0 0-1.664-6.017 17.442 17.442 0 0 0-1.286 5.416c.12.603.188 1.224.2 1.86l.002.211V34H14v-9.602c-.665-3.915-3.899-6.94-7.879-7.226l-.12-.008L6 15.388c3.587.172 6.697 2.19 8.426 5.14.323-1.328.783-2.6 1.363-3.802-2.223-2.685-5.56-4.391-9.289-4.391-.112 0-.223.001-.334.004L6 12.345V10.57c.166-.006.333-.009.5-.009 4.027 0 7.654 1.74 10.188 4.517a19.183 19.183 0 0 1 1.623-2.269c-3.095-3.112-7.356-5.035-12.06-5.035h-.126L6 7.776V6.002L6.25 6c5.163 0 9.843 2.101 13.25 5.507C22.908 8.1 27.587 6 32.75 6l.25.002v1.774a17.202 17.202 0 0 0-.25-.002c-4.705 0-8.966 1.923-12.061 5.035a19.147 19.147 0 0 1 1.623 2.269c2.534-2.778 6.16-4.517 10.188-4.517.167 0 .334.003.5.009v1.775a12.21 12.21 0 0 0-.5-.01c-3.73 0-7.066 1.706-9.29 4.391a19.125 19.125 0 0 1 1.365 3.802c1.728-2.949 4.838-4.968 8.425-5.14v1.776c-4.035.234-7.328 3.28-8 7.234V34h-1.875ZM19.5 14l-.145.178A15.62 15.62 0 0 0 18 16.17c.614.87 1.12 1.819 1.5 2.83.38-1.01.886-1.96 1.5-2.83a15.57 15.57 0 0 0-1.361-2L19.5 14Z"
      />
    </svg>
  );
};
