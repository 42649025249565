import { CancelFillIcon } from '../../../assets/icons';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';

export const NotAccepted = () => {
  const button: ButtonOptions = {
    backgorund: ' #FFE2E1',
    color: '#B82238',
    backgorundHover: ' #FFE2E1',
    colorHover: '#B82238',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={<CancelFillIcon />}
      button={button}
      title={'NO ACEPTADO'}
    />
  );
};
