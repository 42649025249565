import React from 'react';

export const OrderAscIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.875 4h-3.75a.607.607 0 0 0-.625.625.607.607 0 0 0 .625.625h3.75a.607.607 0 0 0 .625-.625A.607.607 0 0 0 5.875 4Zm10 10.75H2.125a.607.607 0 0 0-.625.625.607.607 0 0 0 .625.625h13.75a.607.607 0 0 0 .625-.625.607.607 0 0 0-.625-.625Zm-5-5.375h-8.75A.607.607 0 0 0 1.5 10a.607.607 0 0 0 .625.625h8.75A.607.607 0 0 0 11.5 10a.607.607 0 0 0-.625-.625ZM15.92 2.163l2.905 2.7a.505.505 0 0 1 .175.38.505.505 0 0 1-.175.381.583.583 0 0 1-.41.163.55.55 0 0 1-.39-.144l-1.93-1.795v8.608a.51.51 0 0 1-.166.39.592.592 0 0 1-.42.154.592.592 0 0 1-.418-.154.51.51 0 0 1-.166-.39V3.848l-1.93 1.795a.583.583 0 0 1-.41.163.583.583 0 0 1-.41-.163.505.505 0 0 1-.175-.381c0-.145.052-.266.156-.362L15.1 2.163a.597.597 0 0 1 .195-.127A.611.611 0 0 1 15.51 2c.078 0 .15.012.214.036.065.024.13.067.195.127Z" />
    </svg>
  );
};
