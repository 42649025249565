import {
  Description,
  EventBusy,
  FiberManualRecord,
  Pending,
  People,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DateStartIcon,
  EditSquareOutlinedIcon,
  FilePageIcon,
  TabFilledIcon,
} from '../../../../../assets/icons';
import {
  DynamicForm,
  PreviewPdf,
  ReadDocument,
} from '../../../../../components';
import { CertificateStatusItem } from '../../../../../components/certificateStatus/CertificateStatusItem';
import { useLoading, useNotify } from '../../../../../hooks';
import {
  FollowUpCertificatesService,
  RequestProcessService,
} from '../../../../../services';
import { CertificateStatus, RoutesPath } from '../../../../../utils/enums';
import { convertBlobToBase64, DateHelper } from '../../../../../utils/helpers';
import { DataFile } from '../../../../../utils/interfaces';
import {
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../../../../utils/models';
import { FollowUpCertificateModalOption } from '../../enums/FollowUpCertificateModalOption';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
import { RejectedCertificate } from '../../modals/RejectedCertificate/RejectedCertificate';
import { UpdateFieldsCertificate } from '../../modals/UpdateFieldsCertificate/UpdateFieldsCertificate';
import { UpdateValidity } from '../../modals/UpdateValidity/UpdateValidity';
import { DataDetailItem } from '../DataDetailItem/DataDetailItem';
interface CertificateDetailState {
  certificate: SolicitudSeguimiento;
}

export const CertificateDetailPage = () => {
  const followUpCertificatesService = new FollowUpCertificatesService();
  const requestProcessService = new RequestProcessService();
  const { modal, setModal, followUpCertificate, setFollowUpCertificate } =
    useFollowUpCertificates();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { certificate } = state as CertificateDetailState;
  const theme = useTheme();
  const palette = theme.palette;
  const [file, setFile] = useState<DataFile>({
    name: null,
    data: null,
    url: null,
    sizeDescription: null,
    blob: null,
  });
  const [previewFileModal, setPreviewFileModal] = useState({
    file: '',
    open: false,
  });

  const rejected = () => {
    setModal({
      ...modal,
      type: FollowUpCertificateModalOption.REJECT,
      show: true,
      certificateEdit: certificate,
    });
  };

  const reset = () => {
    setFollowUpCertificate(new SolicitudDetalle());
  };

  const getCertificateDetailById = async () => {
    setLoading(true);
    await followUpCertificatesService
      .getCertificateById(certificate.id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data = new SolicitudDetalle().deserialize(res.data);
          setFollowUpCertificate(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const approve = () => {
    const emptyField = followUpCertificate.editableEscolar.find(
      (field) => !field.valor,
    );
    if (emptyField) {
      setNotify({
        ...notify,
        open: true,
        message:
          'Favor de completar los datos de las constancia antes de aprovar',
        type: 'info',
      });
      return;
    }
    setLoading(true);
    followUpCertificatesService
      .approveCertificate(certificate.id)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia aprobada correctamente',
            type: 'success',
          });
          navigate(RoutesPath.FOLLOW_UP_CERTIFICATE);
          reset();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const setFileByURL = async (certificateId: number) => {
    setLoading(true);
    await requestProcessService
      .getCertificatePreview(certificateId)
      .then(async (response) => {
        setLoading(false);
        const url = URL.createObjectURL(response.data);
        const base64 = await convertBlobToBase64(response.data);
        setLoading(false);

        setFile({
          ...file,
          name: 'Vista previa',
          url,
          data: base64,
        });
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const openPreview = () => {
    setPreviewFileModal({
      ...previewFileModal,
      file: file.data,
      open: true,
    });
  };

  const handleEditCertificate = () => {
    setModal({
      ...modal,
      type: FollowUpCertificateModalOption.UPDATE_FIELDS,
      show: true,
      certificateEdit: certificate,
    });
  };

  useEffect(() => {
    if (!certificate) {
      navigate(RoutesPath.FOLLOW_UP_CERTIFICATE);
      return;
    }

    getCertificateDetailById();
    setFileByURL(certificate.id);
  }, []);

  return (
    <>
      <PreviewPdf
        file={file.url}
        open={previewFileModal.open}
        handleClose={() =>
          setPreviewFileModal({ ...previewFileModal, open: false })
        }
      />
      {modal.type === FollowUpCertificateModalOption.REJECT && (
        <RejectedCertificate onReloadData={() => getCertificateDetailById()} />
      )}
      {modal.type === FollowUpCertificateModalOption.UPDATE_VALIDITY && (
        <UpdateValidity onReloadData={() => getCertificateDetailById()} />
      )}
      {modal.type === FollowUpCertificateModalOption.UPDATE_FIELDS && (
        <UpdateFieldsCertificate
          onReloadData={() => getCertificateDetailById()}
        />
      )}
      <Grid container px={2}>
        <Grid item md={12}>
          <Typography variant="h1" color="secondary">
            Detalle de la constancia
          </Typography>
        </Grid>
        <Grid item md={12} py={2}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle2"
              component="div"
              color="grayCustom.dark"
              sx={{ fontWeight: 600 }}>
              Seguimiento
            </Typography>
            <FiberManualRecord
              sx={{ fontSize: '12px', color: 'grayCustom.main', mx: 2 }}
            />
            <Typography
              variant="subtitle2"
              component="div"
              color="grayCustom.main"
              sx={{ fontWeight: 600 }}>
              Detalle de la constancia
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} px={4} sx={{ py: 2 }}>
          <Box sx={{ boxShadow: 2 }}>
            <ReadDocument file={file.url} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ py: 2 }}>
          <Paper>
            <>
              <Grid
                item
                container
                xs={12}
                pt={2}
                px={4}
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  variant="subtitle2"
                  color="grayCustom.dark"
                  sx={{ fontWeight: 600 }}>
                  Datos generales
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ borderRadius: 50, fontWeight: 500, py: 0 }}
                  onClick={openPreview}>
                  Vista previa
                </Button>
              </Grid>
              <Stack
                direction="row"
                sx={{ py: 2 }}
                px={4}
                alignItems="center"
                justifyContent="flex-start">
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="grayCustom.dark"
                  sx={{ fontWeight: 600 }}>
                  Folio de emisión:{' '}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="grayCustom.main"
                  sx={{
                    ml: 2,
                    px: 2,
                    py: 1,
                    bgcolor: 'grayCustom.light',
                    borderRadius: '16px',
                  }}>
                  {followUpCertificate.folio}
                </Typography>
              </Stack>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container xs={12} p={2}>
                <Grid item xs={12} md={6}>
                  <DataDetailItem
                    icon={<People sx={{ color: 'grayCustom.main' }} />}
                    title={'Alumno'}
                    subtitle={followUpCertificate.alumno}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataDetailItem
                    icon={<TabFilledIcon />}
                    title={'Matrícula'}
                    subtitle={followUpCertificate.matricula}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} py={1} px={2}>
                <Divider />
              </Grid>

              <Grid container px={2}>
                <Grid item xs={12}>
                  <DataDetailItem
                    icon={<FilePageIcon />}
                    title={'Tipo'}
                    subtitle={followUpCertificate.nombre}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <DataDetailItem
                    icon={<Pending sx={{ color: 'grayCustom.main' }} />}
                    title={'Status'}
                    subtitleTmpl={
                      <Grid item xs={12} md={6}>
                        <CertificateStatusItem
                          statusId={followUpCertificate.estatusId}
                        />
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6}>
                    <DataDetailItem
                      icon={<DateStartIcon />}
                      title={'Fecha de solicitud'}
                      subtitle={DateHelper.convertDateString(
                        followUpCertificate.fechaSolicitud,
                        'LL',
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DataDetailItem
                      icon={<EventBusy sx={{ color: 'grayCustom.main' }} />}
                      title={'Fecha de expiración'}
                      subtitle={DateHelper.convertDateString(
                        followUpCertificate.fechaVigencia,
                        'LL',
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {(followUpCertificate.editablesAlumno.length > 0 ||
                followUpCertificate.editableEscolar.length > 0) && (
                <>
                  <Grid item xs={12} py={1} px={2}>
                    <Divider />
                  </Grid>
                  <Grid container px={4}>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-between">
                      <Grid item container xs={6} alignItems="center">
                        <Description sx={{ color: 'grayCustom.main' }} />
                        <Typography
                          variant="subtitle2"
                          color="grayCustom.dark"
                          sx={{
                            fontWeight: 600,
                            px: 2,
                            py: 2,
                            display: 'inline',
                          }}>
                          Datos de la constancia
                        </Typography>
                      </Grid>
                      <Grid item container xs={6} justifyContent="flex-end">
                        {followUpCertificate.estatusId ===
                          CertificateStatus.IN_REVIEW && (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ fontWeight: 500, py: 0 }}
                            endIcon={
                              <EditSquareOutlinedIcon
                                fill={palette.primary.main}
                              />
                            }
                            onClick={handleEditCertificate}>
                            Editar
                          </Button>
                        )}
                      </Grid>
                    </Grid>

                    {followUpCertificate.editablesAlumno.length > 0 && (
                      <Grid item xs={12} py={1}>
                        <Typography
                          variant="subtitle2"
                          color="primary.main"
                          sx={{
                            fontWeight: 600,
                            textTransform: 'uppercase',
                          }}>
                          Datos complementarios del Alumno
                        </Typography>
                        <DynamicForm
                          disabled={true}
                          dataList={followUpCertificate.editablesAlumno}
                        />
                      </Grid>
                    )}

                    {followUpCertificate.editableEscolar.length > 0 && (
                      <Grid item xs={12} py={1}>
                        <Typography
                          variant="subtitle2"
                          color="primary.main"
                          sx={{
                            fontWeight: 600,
                            textTransform: 'uppercase',
                          }}>
                          Datos complementarios de Escolares
                        </Typography>
                        <DynamicForm
                          disabled={true}
                          dataList={followUpCertificate.editableEscolar}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </>
          </Paper>

          {followUpCertificate.estatusId === CertificateStatus.IN_REVIEW && (
            <Grid container justifyContent="space-between">
              <Grid item xs={5} sx={{ py: 2, pr: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ px: 3, py: 1 }}
                  fullWidth
                  onClick={rejected}>
                  RECHAZAR
                </Button>
              </Grid>
              <Grid item xs={5} sx={{ py: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ px: 3, py: 1 }}
                  fullWidth
                  onClick={approve}>
                  APROBAR
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
