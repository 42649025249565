export const SellIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.675 16.5c-.15 0-.294-.022-.431-.066a.984.984 0 0 1-.394-.253L1.819 9.15a.984.984 0 0 1-.253-.394 1.414 1.414 0 0 1-.066-.431v-5.7c0-.325.106-.594.319-.806.212-.213.481-.319.806-.319h5.7c.15 0 .3.022.45.066.15.043.287.128.412.253l6.994 6.994c.25.25.375.528.375.834 0 .306-.125.584-.375.834l-5.7 5.7c-.1.1-.222.178-.365.235-.144.056-.291.084-.441.084Zm.037-1.088 5.7-5.7-7.087-7.087h-5.7v5.7l7.088 7.088ZM4.595 5.55c.262 0 .49-.097.684-.29a.936.936 0 0 0 .29-.685.936.936 0 0 0-.29-.684.936.936 0 0 0-.684-.291.936.936 0 0 0-.685.29.936.936 0 0 0-.29.685c0 .263.097.49.29.684.194.194.422.291.685.291Z"
        fill="#00AB55"
      />
    </svg>
  );
};
