import { Response } from '../../utils/interfaces/response';
import { ClaveCentroTrabajo, PageResult } from '../../utils/models';
import http from '../http/http';

export class WorkPlaceService {
  getAllWorkCenterKeys(
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    filtro?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<ClaveCentroTrabajo[]>>>(
      '/ClaveCentroTrabajo',
      {
        params: { pageSize, pageNumber, ordenar, dir, filtro, inactivos },
      },
    );
  }

  createWorkCenterKey(workCenterKey: ClaveCentroTrabajo) {
    return http.post<Response<ClaveCentroTrabajo>>(
      '/ClaveCentroTrabajo',
      workCenterKey,
    );
  }

  updateWorkCenterKey(workCenterKey: ClaveCentroTrabajo) {
    return http.put<Response<ClaveCentroTrabajo>>(
      '/ClaveCentroTrabajo',
      workCenterKey,
    );
  }

  getWorkCenterKeyById(campusId: string, program: string) {
    return http.get<Response<ClaveCentroTrabajo>>(
      `/ClaveCentroTrabajo/${campusId}/programa/${program}`,
    );
  }

  updateStatusWorkCenterKey(
    campusId: string,
    program: string,
    status: boolean,
  ) {
    return http.post<Response<never>>(
      `/ClaveCentroTrabajo/${campusId}/programa/${program}/Estatus`,
      status,
    );
  }

  deleteWorkCenterKey(campusId: string, program: string) {
    return http.delete<Response<never>>(
      `/ClaveCentroTrabajo/${campusId}/programa/${program}`,
    );
  }
}
