import { ToggleOff, ToggleOn } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC } from 'react';
interface Props {
  status: boolean;
  bgColor?: string;
  bgText?: string;
}
export const StatusItem: FC<Props> = ({ status, bgColor, bgText }) => {
  return (
    <Button
      fullWidth
      disableRipple
      disableFocusRipple
      sx={{
        maxWidth: '150px',
        bgcolor: bgColor
          ? bgColor
          : status
          ? 'primary.light'
          : 'grayCustom.light',
        fontWeight: 600,
        color: bgText ? bgText : status ? 'primary.main' : 'grayCustom.main',
        display: 'flex',
        justifyContent: 'left',
        px: 2,
        '&:hover': {
          bgcolor: bgColor
            ? bgColor
            : status
            ? 'primary.light'
            : 'grayCustom.light',
          color: bgText ? bgText : status ? 'primary.main' : 'grayCustom.main',
          cursor: 'inherit',
        },
      }}
      startIcon={
        status ? (
          <ToggleOn sx={{ color: 'primary.main' }} />
        ) : (
          <ToggleOff sx={{ color: 'grayCustom.main' }} />
        )
      }>
      {status ? 'Activo' : 'Inactivo'}
    </Button>
  );
};
