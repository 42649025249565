import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { Constancia, PageResult } from '../../../../utils/models';
import { CertificateTableColumn } from '../enums/CertificateTableColumn';
import {
  CertificateState,
  ModalRecordCertificate,
} from '../interfaces/interfaces';

type CertificateAction =
  | { type: 'setCertificateList'; certificateList: Constancia[] }
  | { type: 'setPage'; page: number }
  | { type: 'setStatus'; status: boolean; id: number }
  | { type: 'setPaginatorOptions'; paginatorOption: PageResult<Constancia[]> }
  | { type: 'setRowsPerPage'; rowsPerPage: number }
  | { type: 'setModalRecord'; modalRecord: ModalRecordCertificate }
  | { type: 'setSearch'; search: string }
  | { type: 'setOrder'; orderBy: CertificateTableColumn; dir: DirectionOptions }
  | { type: 'setConfirmDialog'; confirmDialog: ConfirmDialogOptions };

export const certificateReducer = (
  state: CertificateState,
  action: CertificateAction,
): CertificateState => {
  switch (action.type) {
    case 'setCertificateList':
      return { ...state, certificateList: action.certificateList };
    case 'setPage':
      return { ...state, page: action.page };
    case 'setStatus':
      return {
        ...state,
        certificateList: state.certificateList.map(({ ...item }) => {
          if (item.id === action.id) {
            item.activo = action.status;
          }
          return new Constancia().deserialize(item);
        }),
      };
    case 'setPaginatorOptions':
      return { ...state, paginatorOptions: action.paginatorOption };
    case 'setRowsPerPage':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'setModalRecord':
      return { ...state, modalRecord: action.modalRecord };
    case 'setSearch':
      return { ...state, search: action.search };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    case 'setConfirmDialog':
      return { ...state, confirmDialog: { ...action.confirmDialog } };
    default:
      return { ...state };
  }
};
