import { Grid, Typography } from '@mui/material';

interface Props {
  elements: any[];
}

export function EmptyTable(props: Props) {
  if (props.elements.length !== 0) {
    return <></>;
  }
  return (
    <Grid item md={12} justifyContent="center" alignItems="center">
      <Typography variant="subtitle1" sx={{ my: 2 }} align={'center'}>
        No hay registros
      </Typography>
    </Grid>
  );
}
