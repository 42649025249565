import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../hooks';
import { AuthService } from '../services';
import { Perfil, SessionToken } from '../utils/models';

interface Props {
  children: JSX.Element;
}

export const authContext = createContext(null);

export function AuthProvider(props: Props): JSX.Element {
  const profile = new Perfil();
  const [user, setUser] = useState<Perfil>(profile);
  const [session, setSession] = useState<SessionToken>(new SessionToken());
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const authService = new AuthService();

  const checkSession = async () => {
    setLoading(true);
    await authService.getProfileUser().then((response) => {
      setLoading(false);
      const res = response.data;
      const data = new Perfil().deserialize(res.data);
      setUser(data);
    });
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <authContext.Provider
      value={{
        user,
        session,
        setSession,
        setUser,
      }}>
      {props.children}
    </authContext.Provider>
  );
}
