import { Grid, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { DatoConstancia } from '../../utils/models';
interface Props {
  dataList: DatoConstancia[];
  setDataList?: (data: DatoConstancia[]) => void;
  disabled?: boolean;
}

export const DynamicForm: FC<Props> = ({ dataList, setDataList, disabled }) => {
  const handleFormChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const data: DatoConstancia[] = [...dataList];
    const value = event.target.value;
    data[index].valor = value;
    setDataList(data);
  };

  return (
    <>
      {dataList.map((data, index) => (
        <Grid item xs={12} md={6} py={1} key={data.codigo}>
          <TextField
            name={data.codigo}
            label={data.etiqueta}
            variant="outlined"
            type="text"
            fullWidth
            size={'small'}
            disabled={disabled}
            required
            sx={{
              maxHeight: 'none',
              height: 'none',
              '& MuiOutlinedInput': {
                maxHeight: 'none',
                height: 'none',
              },
            }}
            maxRows={10}
            value={data.valor}
            onChange={(event) => handleFormChange(index, event)}
          />
        </Grid>
      ))}
    </>
  );
};
