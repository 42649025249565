import { Dialpad } from '@mui/icons-material';
import { Button, Chip, Grid, Typography } from '@mui/material';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DotClockIcon } from '../../../assets/icons';
import certificateDashboardIcon from '../../../assets/icons/certificateDashboardIcon.svg';
import { RejectedMessage } from '../../../components/RejectedMessage/RejectedMessage';
import { useLoading, useNotify } from '../../../hooks';
import {
  CertificateHistoryService,
  RequestProcessService,
} from '../../../services';
import { RoutesPath } from '../../../utils/enums';
import { getFileName } from '../../../utils/helpers';
import { SolicitudAlumno } from '../../../utils/models';
import { CertificateHistoryTableColumn } from '../CertificateHistory/components/enums/CertificateHistoryTableColumn';
import { DashboardCertificateCard } from './components/DashboardCertificateCard/DashboardCertificateCard';

export function StudentsDashboard() {
  const certificatesService = new CertificateHistoryService();
  const requestProcessService = new RequestProcessService();
  const navigate = useNavigate();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [certificateList, setCertificateList] = useState<SolicitudAlumno[]>([]);
  const [showRequest, setShowRequest] = useState<SolicitudAlumno>(
    new SolicitudAlumno(),
  );
  const getAllCertificates = async () => {
    setLoading(true);
    await certificatesService
      .getAllCertificate(
        '',
        3,
        1,
        CertificateHistoryTableColumn.FECHA_CREACION,
        'desc',
        false,
      )
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: SolicitudAlumno[] = res.data.data.map(
            (request: SolicitudAlumno) =>
              new SolicitudAlumno().deserialize(request),
          );
          setCertificateList(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado al obtener las constancias',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const sendToPay = (request: SolicitudAlumno) => {
    setLoading(true);
    window.location.replace(requestProcessService.getURLToPay(request.id));
  };

  const sendToDownload = async (request: SolicitudAlumno) => {
    setLoading(true);
    await requestProcessService
      .getURLToDownloadCertificate(request.id)
      .then(async (r) => {
        const res = r.data;
        setLoading(false);
        if (res.success) {
          axios
            .get(res.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream',
              });
              const fileName = getFileName(request.nombre);
              const file = new File([blob], fileName, {
                type: 'application/pdf',
              });
              FileSaver.saveAs(file, fileName);
            })
            .catch(() => {
              setNotify({
                ...notify,
                open: true,
                message: 'Error al obtener el archivo',
                type: 'error',
              });
            });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const showRejectMessage = (request: SolicitudAlumno) => {
    setShowRequest(request);
  };

  const requestAgain = (request: SolicitudAlumno) => {
    navigate(RoutesPath.STUDENT_CERTIFICATE_REQUEST_PROCESS, {
      state: { certificateId: request.constanciaId },
    });
  };

  const handleCloseRejectMessage = () => {
    setShowRequest(new SolicitudAlumno());
  };

  useEffect(() => {
    getAllCertificates();
  }, []);
  return (
    <>
      <RejectedMessage
        request={showRequest}
        onClose={handleCloseRejectMessage}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: 'primary.light',
            borderRadius: '16px',
            maxWidth: '1200px !important',
          }}>
          <Grid container py={6} sx={{ px: { xs: 2, lg: 4 } }}>
            <Grid item xs={12} lg={6}>
              <Typography
                component="div"
                variant="h1"
                color="secondary.dark"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: '20px', lg: '32px' },
                  pt: { xs: 0, lg: 4 },
                  textAlign: { xs: 'center', lg: 'left' },
                  display: { xs: 'block', lg: 'inline' },
                }}>
                ¡Obtén de forma rápida tu constancia electrónica!
              </Typography>
              <Grid item xs={12} lg={8} sx={{ py: 4 }}>
                <Typography
                  component="p"
                  variant="subtitle2"
                  color="grayColor.dark"
                  paragraph
                  sx={{
                    textAlign: { xs: 'center', lg: 'left' },
                    fontWeight: 400,
                  }}>
                  ¡Ahora puedes solicitar la expedición de la constancia que
                  requieras de forma rápida y en un solo lugar!
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  px: 3,
                  py: 1,
                  color: 'white',
                  display: { xs: 'none', lg: 'inline' },
                }}
                component={Link}
                to={RoutesPath.STUDENT_CERTIFICATE_REQUEST}>
                Solicita Tu Constancia
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                justifyContent: 'center',
                display: { xs: 'none', lg: 'flex' },
              }}>
              <div>
                <img src={certificateDashboardIcon} alt="icon" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  px: 3,
                  color: 'white',
                  display: { xs: 'flex', lg: 'none' },
                  width: { xs: '100%', md: '50%' },
                }}
                fullWidth
                component={Link}
                to={RoutesPath.STUDENT_CERTIFICATE_REQUEST}>
                Solicita Tu Constancia
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={4}>
          <Chip
            icon={<DotClockIcon />}
            label={
              <Typography
                variant="body2"
                display={'inline'}
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: '#00AB55',
                }}>
                Recientes
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          <Typography
            variant="h6"
            component="span"
            color="primary.dark"
            pr={1}
            display={'inline'}
            sx={{ fontWeight: 600 }}>
            Constancias que has solicitado
          </Typography>
          <Typography
            variant="caption"
            color="gray"
            px={1}
            sx={{
              borderLeft: '1px solid',
              fontWeight: 600,
              my: { xs: 2, lg: 0 },
              display: { xs: 'block', lg: 'inline' },
            }}>
            {certificateList.length === 0 && 'No se encontraron solicitudes'}
            {certificateList.length === 1 && 'Última solicitud'}
            {certificateList.length > 1 &&
              `${certificateList.length} últimas solicitudes`}
          </Typography>
        </Grid>
        <Grid container alignItems="strech" direction="row" px={0}>
          {certificateList.map((certificate) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              py={2}
              pr={2}
              key={certificate.id}
              sx={{ maxWidth: { xs: 'auto', md: '290px' } }}>
              <DashboardCertificateCard
                certificate={certificate}
                download={() => sendToDownload(certificate)}
                pay={() => sendToPay(certificate)}
                reject={() => showRejectMessage(certificate)}
                requestAgain={() => requestAgain(certificate)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          py={2}
          pr={2}>
          <Dialpad sx={{ color: '#3CE14C' }} />
          <Typography
            variant="subtitle2"
            color="grayCustom.main"
            sx={{ pl: 2 }}>
            No hay más elementos que mostrar...
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
