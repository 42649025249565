import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import './PreviewPdfStyle.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type PreviewPdfProps = {
  file: File | string | Blob;
  open: boolean;
  handleClose: () => void;
};

export const PreviewPdf: FC<PreviewPdfProps> = ({
  file,
  open,
  handleClose,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  const changePage = (offSet: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  const changePageBack = () => {
    changePage(-1);
  };

  return (
    <Dialog
      style={{ zIndex: 1800 }}
      open={open}
      onClose={() => handleClose}
      fullWidth
      maxWidth={'lg'}>
      <DialogTitle>
        Vista previa
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="pdf__container">
          <Document
            noData="Error al obtener la vista previa"
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </DialogContent>

      <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: 'uppercase',
            width: { xs: '100%', md: 'auto' },
            py: 1,
            m: 1,
          }}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
