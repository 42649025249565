export const TrashIcon = (props: any) => {
  return (
    <svg
      width={14}
      height={16}
      fill="#FF4842"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.438 15.5a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V2.375H.96a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h3.292A.607.607 0 0 1 4.876.5h4.25a.607.607 0 0 1 .625.625h3.291a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625h-.229V14.25a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H2.438Zm0-13.125V14.25h9.125V2.375H2.438Zm2.208 9.458a.607.607 0 0 0 .625.625.607.607 0 0 0 .625-.625V4.771a.607.607 0 0 0-.625-.625.607.607 0 0 0-.625.625v7.062Zm3.459 0a.607.607 0 0 0 .625.625.607.607 0 0 0 .625-.625V4.771a.607.607 0 0 0-.625-.625.607.607 0 0 0-.625.625v7.062ZM2.438 2.375V14.25 2.375Z" />
    </svg>
  );
};
