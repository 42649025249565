import { Deserializable } from '../interfaces';

export class ClaveCentroTrabajo implements Deserializable {
  campus?: string;
  programa?: string;
  cct?: string;
  activo: boolean;

  constructor() {
    this.campus = '';
    this.programa = '';
    this.cct = null;
    this.activo = false;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
