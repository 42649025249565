import { createContext } from 'react';
import { DirectionOptions } from '../../../../utils/enums';
import { Campus, PageResult } from '../../../../utils/models';
import { CampusTableColumn } from '../enums/CampusTableColumn';
import { CampusState, ModalRecordCampus } from '../interfaces/interfaces';

export type CampusContextProps = {
  campusState: CampusState;
  reloadCampus: (campusList: Campus[]) => void;
  changePage: (page: number) => void;
  changeStatus: (status: boolean, campus: Campus) => void;
  changePaginatorOptions: (paginatorOptions: PageResult<Campus[]>) => void;
  changeRowsPerPage: (rowsPerPage: any) => void;
  changeModalRecord: (modalRecord: ModalRecordCampus) => void;
  changeSearch: (search: string) => void;
  setOrder: (orderBy: CampusTableColumn, dir: DirectionOptions) => void;
};

export const CampusContext = createContext<CampusContextProps>(
  {} as CampusContextProps,
);
