import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContainerTable, ToolbarModule } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService } from '../../../../../services';
import { Campus } from '../../../../../utils/models';
import { useCampus } from '../../hooks/useCampus';
import { CampusRecord } from '../../modals/CampusRecord/CampusRecord';
import { CampusSearch } from '../CampusSearch/CampusSearch';
import { CampusTable } from '../CampusTable/CampusTable';

export const CampusPage = () => {
  const campusService = new CampusService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    reloadCampus,
    rowsPerPage,
    page,
    changePage,
    search,
    changePaginatorOptions,
    modalRecord,
    changeModalRecord,
    orderBy,
    dir,
  } = useCampus();

  const getAllCampus = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await campusService
      .getAllCampus(filtro, pageSize, pageNumber, ordenar, dir, true)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró un campus',
              type: 'info',
            });
          }
          const data: Campus[] = res.data.data.map((campus) =>
            new Campus().deserialize(campus),
          );
          reloadCampus(data);
          changePaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    changePage(valuePage);
    getAllCampus(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllCampus(search, rowsPerPage, page, orderBy, dir);
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllCampus(search, pageSize, pageNumber, orderBy, asc);
  };

  useEffect(() => {
    onReloadData();
  }, []);
  return (
    <>
      {modalRecord.show && (
        <CampusRecord onReloadData={onReloadData}></CampusRecord>
      )}
      <Grid container>
        <ToolbarModule
          title="Administrar campus"
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Gestiona los coordinadores por campus y la(s) cuenta(s) de
                correo de notificaciones para solicitudes de constancia nuevas
                para cada campus.
              </Typography>
            </>
          }
        />
        <ContainerTable
          title="Campus"
          actions={
            <Grid container px={0}>
              <CampusSearch onSearch={onSearch}></CampusSearch>
              <Grid
                item
                container
                direction="row"
                xs={12}
                md={6}
                justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    ml: { xs: 0, md: 2 },
                    my: 1,
                    py: 1,
                    width: { xs: '100%', md: 'auto' },
                    justifyContent: { xs: 'flex-start', md: 'center' },
                  }}
                  startIcon={<Add />}
                  onClick={() =>
                    changeModalRecord({
                      ...modalRecord,
                      show: true,
                      type: 'add',
                      campusEdit: null,
                    })
                  }>
                  Crear campus
                </Button>
              </Grid>
            </Grid>
          }
          table={
            <Grid item md={12} sx={{ mt: 4 }}>
              <CampusTable
                onReloadData={onReloadData}
                onTableFilter={onTableFilter}></CampusTable>
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
