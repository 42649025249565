import { CampusIPID } from '../enums';
import { Deserializable } from '../interfaces';

export class SessionToken implements Deserializable {
  nombre: string;
  rol: string;
  id: string;
  campus: CampusIPID;
  constructor() {
    this.nombre = null;
    this.rol = null;
    this.id = null;
    this.campus = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
