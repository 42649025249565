import { useReducer } from 'react';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, PeriodoDTO } from '../../../../utils/models';
import { PeriodTableColumn } from '../enums/PeriodTableColumn';
import { ModalRecordPeriod, PeriodState } from '../interface/periodState';
import { PeriodContext } from './PeriodContext';
import { periodReducer } from './periodReducer';

const INITIAL_STATE_PERIOD: PeriodState = {
  periodList: [],
  page: 1,
  paginatorOptions: new PageResult<PeriodoDTO[]>(),
  rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
  search: '',
  orderBy: PeriodTableColumn.CLAVE,
  dir: DirectionOptions.ASC,
  modalRecord: {
    show: false,
    type: 'add',
    id: null,
  },
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const PeriodProvider = ({ children }: props) => {
  const [periodState, dispatch] = useReducer(
    periodReducer,
    INITIAL_STATE_PERIOD,
  );

  const reloadPeriod = (periodList: PeriodoDTO[]) => {
    dispatch({ type: 'reloadPeriod', periodList });
  };

  const changePage = (page: number) => {
    dispatch({ type: 'changePage', page });
  };

  const changeStatus = (status: boolean, id: string) => {
    dispatch({ type: 'changeStatus', status, id });
  };

  const changePaginatorOptions = (
    paginatorOptions: PageResult<PeriodoDTO[]>,
  ) => {
    dispatch({ type: 'changePaginatorOptions', paginatorOptions });
  };

  const changeRowsPerPage = (rowsPerPage: any) => {
    dispatch({ type: 'changeRowsPerPage', rowsPerPage });
  };

  const changeSearch = (search: string) => {
    dispatch({ type: 'changeSearch', search });
  };

  const changeModal = (modalRecord: ModalRecordPeriod) => {
    dispatch({ type: 'changeModal', modalRecord });
  };
  const setOrder = (orderBy: PeriodTableColumn, dir: DirectionOptions) => {
    dispatch({ type: 'setOrder', orderBy, dir });
  };

  return (
    <PeriodContext.Provider
      value={{
        periodState,
        reloadPeriod,
        changePage,
        changeStatus,
        changePaginatorOptions,
        changeRowsPerPage,
        changeSearch,
        changeModal,
        setOrder,
      }}>
      {children}
    </PeriodContext.Provider>
  );
};
