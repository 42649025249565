import { Response } from '../../utils/interfaces';
import { Campus, PageResult } from '../../utils/models';
import http from '../http/http';

export class CampusService {
  getAllCampus(
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<Campus[]>>>('/Campus', {
      params: { filtro, pageSize, pageNumber, ordenar, dir, inactivos },
    });
  }

  getCampusById(campusId: string) {
    return http.get<Response<PageResult<Campus[]>>>(`/Campus/${campusId}`);
  }

  createCampus(campus: Campus) {
    return http.post<Response<never>>('/Campus', campus);
  }

  updateCampus(campus: Campus) {
    return http.put<Response<never>>('/Campus', campus);
  }

  updateStatusCampus(campusId: string, status: boolean) {
    return http.post<Response<never>>(`/Campus/${campusId}/Estatus`, status);
  }

  deleteCampus(campusId: string) {
    return http.delete<Response<never>>(`/Campus/${campusId}`);
  }

  getURLElectronicSignature(campusId: string) {
    return http.get<Response<string>>(`/Campus/${campusId}/Firma`);
  }
}
