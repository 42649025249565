import { Close, Money, Person } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { EditSquareIcon } from '../../../../../assets/icons';
import { AddPersonFillIcon } from '../../../../../assets/icons/AddPersonFillIcon';
import { FieldDisable, ModalBar, MultiSelect } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService, UsersService } from '../../../../../services';
import { validationMsj } from '../../../../../utils/consts';
import { RoleType } from '../../../../../utils/enums';
import { Campus, Usuario } from '../../../../../utils/models';
import { ValidUser } from '../../components/ValidUser/ValidUser';
import { useUsers } from '../../hook/useUsers';

interface Props {
  getAllUsers: () => void;
}

type UserForm = {
  id: string;
  nombre: string;
  nomina: string;
  esAdmin: boolean;
  activo: boolean;
  rol: string;
  campuses: string[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = Yup.object({
  id: Yup.string().required(),
  nombre: Yup.string(),
  nomina: Yup.string(),
  esAdmin: Yup.boolean(),
  activo: Yup.boolean(),
  rol: Yup.string().required(validationMsj.is.required).nullable(true),
  campuses: Yup.array().when('esAdmin', {
    is: (val: boolean) => val === false,
    then: (schema) => schema.required(validationMsj.is.required),
  }),
});

const initialUserStateForm: UserForm = {
  id: '',
  nombre: '',
  nomina: '',
  esAdmin: false,
  activo: true,
  rol: RoleType.COLLABORATOR,
  campuses: [],
};

export const UserRecord: FC<Props> = ({ getAllUsers }) => {
  const usersService = new UsersService();
  const campusService = new CampusService();

  const formik = useFormik({
    initialValues: initialUserStateForm,
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const { modalRecord, setModalRecord } = useUsers();

  const [user, setUser] = useState<Usuario>(new Usuario());
  const [campusList, setCampusList] = useState<Campus[]>([]);
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();

  const title =
    modalRecord.type === 'edit'
      ? 'Configuración de permisos de usuarios'
      : 'Crear un usuario nuevo';

  const isValidForm = (): boolean => {
    if (formik.values.campuses.length === 0 && !formik.values.esAdmin) {
      setNotify({
        ...notify,
        open: true,
        message: 'Favor seleccionar uno ó mas campus',
        type: 'info',
      });
      return false;
    }
    return true;
  };

  const handleSelectRoleChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const role: string = value;
    if (role === RoleType.ADMIN) {
      formik.setValues({
        ...formik.values,
        rol: role,
        campuses: [],
        esAdmin: true,
      });
    } else {
      formik.setValues({ ...formik.values, rol: role, esAdmin: false });
    }
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      return;
    }
    const userSave = new Usuario();
    userSave.deserialize(formik.values);
    userSave.esAdmin = formik.values.rol === RoleType.ADMIN;

    setLoading(true);
    if (modalRecord.userEdit.id) {
      userSave.id = modalRecord.userEdit.id;
      await usersService
        .updateUser(userSave)
        .then((response) => {
          setLoading(false);
          const res = response.data;
          if (res.success) {
            setNotify({
              ...notify,
              open: true,
              message: 'Usuario guardado correctamente',
              type: 'success',
            });
            handleClose();
          } else {
            setNotify({
              ...notify,
              open: true,
              message: res.message,
              type: 'error',
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setNotify({
            ...notify,
            open: true,
            message: 'Error inesperado',
            type: 'error',
          });
        });
    } else {
      userSave.id = user.id;
      await usersService
        .createUser(userSave)
        .then((response) => {
          setLoading(false);
          const res = response.data;
          if (res.success) {
            setNotify({
              ...notify,
              open: true,
              message: 'Usuario creado correctamente',
              type: 'success',
            });
            handleClose();
          } else {
            setNotify({
              ...notify,
              open: true,
              message: res.message,
              type: 'error',
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setNotify({
            ...notify,
            open: true,
            message: 'Error inesperado',
            type: 'error',
          });
        });
    }
  };

  const onUserChange = async (userChange: Usuario) => {
    formik.setValues({
      ...formik.values,
      id: userChange.id,
      nombre: userChange.nombre,
      nomina: userChange.nomina,
    });
    setUser(userChange);
  };

  const getAllCampus = async () => {
    setLoading(true);
    await campusService
      .getAllCampus()
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Campus[] = res.data.data.map((user) =>
            new Campus().deserialize(user),
          );
          setCampusList(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const handleClose = () => {
    formik.resetForm();
    setUser(new Usuario());
    getAllUsers();
    setModalRecord({ ...modalRecord, show: false, userEdit: new Usuario() });
  };

  const handleMultiselectChange = (
    selected: { id: string | number; name: string }[],
  ) => {
    formik.setFieldValue(
      'campuses',
      selected.map((item) => item.id) as string[],
    );
  };

  useEffect(() => {
    formik.resetForm();
    if (modalRecord.userEdit.id) {
      setUser(modalRecord.userEdit);
      const rol: RoleType = modalRecord.userEdit.esAdmin
        ? RoleType.ADMIN
        : RoleType.COLLABORATOR;
      formik.setValues({
        ...formik.values,
        rol,
        id: modalRecord.userEdit.id,
        activo: modalRecord.userEdit.activo,
        esAdmin: modalRecord.userEdit.esAdmin,
        nombre: modalRecord.userEdit.nombre,
        nomina: modalRecord.userEdit.nomina,
        campuses: modalRecord.userEdit.campuses,
      });
    }
  }, [modalRecord]);

  useEffect(() => {
    getAllCampus();
  }, []);

  return (
    <>
      <Dialog
        open={modalRecord.show}
        onClose={() => handleClose}
        fullWidth
        maxWidth={'md'}>
        <DialogTitle>
          {title}{' '}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'add' && (
            <>
              <ModalBar
                startIcon={<AddPersonFillIcon />}
                title="Valida los datos del usuario"
              />
              <ValidUser
                onUserChange={onUserChange}
                notify={notify}
                setNotify={setNotify}
                setLoading={setLoading}
              />
            </>
          )}
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<EditSquareIcon />}
                title="Edita los privilegios del usuario"
              />
              <Grid container pb={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="grayCustom.main"
                    sx={{ fontWeight: 600, pb: 2 }}>
                    DATOS GENERALES
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldDisable
                    icon={<Person sx={{ color: 'grayCustom.main' }} />}
                    title="Usuario"
                    subtitle={modalRecord.userEdit.nombre}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldDisable
                    icon={<Money sx={{ color: 'grayCustom.main' }} />}
                    title="Nómina"
                    subtitle={modalRecord.userEdit.nomina}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ fontWeight: 600, pb: 1 }}>
                  ELEGIR ROL DEL USUARIO
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ py: 1 }}>
                  <RadioGroup
                    aria-labelledby="rol"
                    name="rol"
                    value={formik.values.rol}
                    onChange={handleSelectRoleChange}>
                    <FormControlLabel
                      value={RoleType.ADMIN}
                      control={<Radio />}
                      label="Administrador"
                    />
                    <FormControlLabel
                      value={RoleType.COLLABORATOR}
                      control={<Radio />}
                      label="Escolar"
                    />
                  </RadioGroup>
                  <FormHelperText id="rolHelperText">
                    {formik.errors.rol as string}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={8} alignItems="flex-end">
                {formik.values.rol === RoleType.COLLABORATOR && (
                  <MultiSelect
                    title="Campus"
                    elementList={campusList
                      .sort((a, b) => a.nombre.localeCompare(b.nombre))
                      .map((campus) => {
                        return {
                          id: campus.id.toString(),
                          name: campus.nombre,
                        };
                      })}
                    selectedStart={formik.values.campuses}
                    onChange={(selected) => handleMultiselectChange(selected)}
                    errors={formik.errors.campuses as string}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {modalRecord.type === 'add' ? 'Crear usuario' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
