import { DirectorsPage } from './components/DirectorsPage/DirectorsPage';
import { DirectorProvider } from './context/DirectorsProvider';

export const Directors = () => {
  return (
    <DirectorProvider>
      <DirectorsPage />
    </DirectorProvider>
  );
};
