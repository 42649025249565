import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useNotify } from '../../hooks';
import { EmptyPreviewFile } from '../EmptyPreviewFile/EmptyPreviewFile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type Props = {
  file: File | string | Blob;
};

export const ReadDocument: FC<Props> = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [isLoading, setIsLoading] = useState(true);
  const { notify, setNotify } = useNotify();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  const changePage = (offSet: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  const changePageBack = () => {
    changePage(-1);
  };

  const handleErrorLoadPDF = () => {
    setNotify({
      ...notify,
      open: true,
      message: 'Error al cargar el archivo',
      type: 'error',
    });
  };

  return (
    <Document
      noData="Error al obtener la vista previa"
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={handleErrorLoadPDF}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};
