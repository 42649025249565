import { useContext } from 'react';
import { CampusContext } from '../context/CampusContext';

export const useCampus = () => {
  const {
    campusState,
    reloadCampus,
    changePage,
    changeStatus,
    changePaginatorOptions,
    changeRowsPerPage,
    changeModalRecord,
    changeSearch,
    setOrder,
  } = useContext(CampusContext);
  const {
    campusList,
    paginatorOptions,
    page,
    rowsPerPage,
    search,
    modalRecord,
    orderBy,
    dir,
  } = campusState;

  return {
    campusList,
    reloadCampus,
    paginatorOptions,
    changePaginatorOptions,
    page,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    search,
    changeSearch,
    modalRecord,
    changeModalRecord,
    orderBy,
    dir,
    setOrder,
    changeStatus,
  };
};
