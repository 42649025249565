import { useContext } from 'react';
import { CertificateContext } from '../context/CertificateContext';

export const useCertificates = () => {
  const {
    certificateState,
    setCertificateList,
    setPage,
    setStatus,
    setPaginatorOptions,
    setRowsPerPage,
    setModalRecord,
    setSearch,
    setOrder,
    setConfirmDialog,
  } = useContext(CertificateContext);

  const {
    certificateList,
    page,
    paginatorOptions,
    rowsPerPage,
    search,
    modalRecord,
    orderBy,
    dir,
    confirmDialog,
  } = certificateState;

  return {
    certificateList,
    setCertificateList,
    page,
    setPage,
    paginatorOptions,
    setPaginatorOptions,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    modalRecord,
    setModalRecord,
    setStatus,
    orderBy,
    dir,
    setOrder,
    confirmDialog,
    setConfirmDialog,
  };
};
