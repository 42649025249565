import Button from '@mui/material/Button';
import { FC, useEffect } from 'react';
import { useCertificateRequest } from '../../hooks/useCertificateRequest';

interface Props {
  category: string;
}

export const CertificateCategoryItem: FC<Props> = ({ category }) => {
  const { setCategoriesSelected, categoriesSelected } = useCertificateRequest();

  const handleClick = () => {
    const finded = categoriesSelected.find((item) => item === category);
    if (finded) {
      setCategoriesSelected([]);
    } else {
      setCategoriesSelected([category]);
    }
  };

  return (
    <Button
      variant={categoriesSelected.includes(category) ? 'contained' : 'outlined'}
      disableElevation={!!categoriesSelected.includes(category)}
      sx={{
        my: 1,
        mr: 1,
        ml: 0,
        borderColor: 'grayCustom.dark',
        borderWidth: '1px',
        bgcolor: categoriesSelected.includes(category)
          ? 'grayCustom.light'
          : 'white',
        color: categoriesSelected.includes(category)
          ? 'primary.main'
          : 'grayCustom.dark',
        fontWeight: categoriesSelected.includes(category) ? 700 : 400,
        '&:hover': {
          bgcolor: 'grayCustom.light',
          color: 'primary.main',
          borderColor: 'grayCustom.light',
          fontWeight: 700,
        },
      }}
      onClick={handleClick}>
      {category}
    </Button>
  );
};
