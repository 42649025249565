export const AlphanumericIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.275 13.5a.627.627 0 0 1-.46-.19.627.627 0 0 1-.19-.46V8.3H8.65a.627.627 0 0 1-.46-.19.627.627 0 0 1-.19-.46c0-.18.063-.334.19-.46A.627.627 0 0 1 8.65 7h1.463a.79.79 0 0 1 .812.813v5.037c0 .18-.063.334-.19.46a.627.627 0 0 1-.46.19ZM12.73 13.27a.79.79 0 0 0 .582.23h3.413c.18 0 .334-.063.46-.19a.627.627 0 0 0 .19-.46.627.627 0 0 0-.19-.46.627.627 0 0 0-.46-.19H13.8v-1.3h2.654c.271 0 .492-.086.664-.257a.898.898 0 0 0 .257-.664V7.921a.898.898 0 0 0-.257-.664.898.898 0 0 0-.664-.257H13.15a.627.627 0 0 0-.46.19.627.627 0 0 0-.19.46c0 .18.063.334.19.46.126.127.28.19.46.19h2.925v1.3h-2.654a.898.898 0 0 0-.664.257.898.898 0 0 0-.257.664v2.166a.79.79 0 0 0 .23.583ZM1 13.5V7.92a.91.91 0 0 1 .25-.663A.863.863 0 0 1 1.898 7h3.668c.264 0 .48.086.647.257a.91.91 0 0 1 .25.664V13.5H5.197v-2.085h-2.93V13.5H1Zm1.267-3.385h2.929V8.3h-2.93v1.815Z" />
    </svg>
  );
};
