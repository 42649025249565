import { useReducer } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, SolicitudAlumno } from '../../../../utils/models';
import { CertificateHistoryTableColumn } from '../components/enums/CertificateHistoryTableColumn';
import { CertificateHistoryState } from '../interfaces/interfaces';
import { CertificateHistoryContext } from './CertificateHistoryContext';
import { certificateReducer } from './certificateHistoryReducer';

const INITIAL_STATE: CertificateHistoryState = {
  certificateList: [],
  page: 1,
  paginatorOptions: new PageResult<SolicitudAlumno[]>(),
  rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
  search: '',
  orderBy: CertificateHistoryTableColumn.EMPTY,
  dir: DirectionOptions.DESC,
  confirmDialog: {
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  },
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const CertificateHistoryProvider = ({ children }: Props) => {
  const [certificateState, dispatch] = useReducer(
    certificateReducer,
    INITIAL_STATE,
  );

  const setCertificateList = (certificateList: SolicitudAlumno[]) => {
    dispatch({ type: 'setCertificateList', certificateList });
  };

  const setPage = (page: number) => {
    dispatch({ type: 'setPage', page });
  };

  const setPaginatorOptions = (
    paginatorOption: PageResult<SolicitudAlumno[]>,
  ) => {
    dispatch({ type: 'setPaginatorOptions', paginatorOption });
  };

  const setRowsPerPage = (rowsPerPage: number) => {
    dispatch({ type: 'setRowsPerPage', rowsPerPage });
  };

  const setSearch = (search: string) => {
    dispatch({ type: 'setSearch', search });
  };

  const setOrder = (
    orderBy: CertificateHistoryTableColumn,
    dir: DirectionOptions,
  ) => {
    dispatch({ type: 'setOrder', orderBy, dir });
  };

  const setConfirmDialog = (confirmDialog: ConfirmDialogOptions) => {
    dispatch({ type: 'setConfirmDialog', confirmDialog });
  };

  return (
    <CertificateHistoryContext.Provider
      value={{
        certificateState,
        setCertificateList,
        setPage,
        setPaginatorOptions,
        setRowsPerPage,
        setSearch,
        setOrder,
        setConfirmDialog,
      }}>
      {children}
    </CertificateHistoryContext.Provider>
  );
};
