export const FollowCertificateIcon = (props: any) => {
  return (
    <svg
      width={22}
      height={22}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M5.042 20.167a1.32 1.32 0 0 1-.963-.413 1.32 1.32 0 0 1-.412-.962V3.208c0-.366.137-.687.412-.962a1.32 1.32 0 0 1 .963-.413h7.792l5.5 5.5v3.575h-1.375V8.25h-5.042V3.208H5.042v15.584h6.875v1.375H5.042Z" />
      <path d="M19.7 20.023V13.1a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v6.923c0 .185.071.325.216.417.144.093.299.088.464-.015l2.32-1.33 2.319 1.33c.165.103.32.108.464.015a.463.463 0 0 0 .216-.417Z" />
    </svg>
  );
};
