import React from 'react';

export const MarkFillIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.648 12.085 6.402 9.84a.686.686 0 0 0-.504-.206.686.686 0 0 0-.505.207.737.737 0 0 0-.229.538.7.7 0 0 0 .207.516l2.795 2.796a.65.65 0 0 0 .482.183.65.65 0 0 0 .48-.183l5.5-5.5a.686.686 0 0 0 .207-.505.724.724 0 0 0-.23-.527.7.7 0 0 0-.515-.206.737.737 0 0 0-.538.23l-4.904 4.903ZM10 19.167c-1.299 0-2.506-.233-3.621-.7a8.945 8.945 0 0 1-2.91-1.936 8.947 8.947 0 0 1-1.937-2.91C1.066 12.505.833 11.299.833 10c0-1.283.233-2.483.699-3.598a8.946 8.946 0 0 1 1.936-2.91 9.1 9.1 0 0 1 2.91-1.948C7.495 1.07 8.702.834 10 .834c1.283 0 2.482.236 3.598.71a9.1 9.1 0 0 1 2.91 1.948 9.1 9.1 0 0 1 1.948 2.91 9.109 9.109 0 0 1 .71 3.598c0 1.299-.236 2.505-.71 3.62a9.1 9.1 0 0 1-1.948 2.911 8.945 8.945 0 0 1-2.91 1.937 9.238 9.238 0 0 1-3.598.699Z" />
    </svg>
  );
};
