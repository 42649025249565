import { createContext } from 'react';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, PeriodoDTO } from '../../../../utils/models';
import { PeriodTableColumn } from '../enums/PeriodTableColumn';
import { ModalRecordPeriod, PeriodState } from '../interface/periodState';

export type PeriodContextProps = {
  periodState: PeriodState;
  reloadPeriod: (periodList: PeriodoDTO[]) => void;
  changePage: (page: number) => void;
  changeStatus: (status: boolean, id: string) => void;
  changePaginatorOptions: (paginatorOptions: PageResult<PeriodoDTO[]>) => void;
  changeRowsPerPage: (rowsPerPage: any) => void;
  changeSearch: (search: string) => void;
  changeModal: (modalRecord: ModalRecordPeriod) => void;
  setOrder: (orderBy: PeriodTableColumn, dir: DirectionOptions) => void;
};

export const PeriodContext = createContext<PeriodContextProps>(
  {} as PeriodContextProps,
);
