import { createContext } from 'react';
import { DirectionOptions } from '../../../../utils/enums';
import { ClaveCentroTrabajo, PageResult } from '../../../../utils/models';
import { WorkPlaceTableColumn } from '../enums/WorkPlaceTableColumn';
import {
  ModalRecordWorkPlace,
  WorkPlaceState,
} from '../interface/workPlaceState';

export type WorkPlaceContextProps = {
  workPlaceState: WorkPlaceState;
  setSearch: (search: string) => void;
  setWorkCenterKeyList: (workCenterKeyList: ClaveCentroTrabajo[]) => void;
  setPage: (page: number) => void;
  setPaginatorOptions: (
    paginatorOptions: PageResult<ClaveCentroTrabajo[]>,
  ) => void;
  setRowsPerPage: (rowsPerPage: any) => void;
  setModal: (modalRecord: ModalRecordWorkPlace) => void;
  setOrder: (orderBy: WorkPlaceTableColumn, dir: DirectionOptions) => void;
};

export const WorkPlaceContext = createContext<WorkPlaceContextProps>(
  {} as WorkPlaceContextProps,
);
