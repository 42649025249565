import { Box, CssBaseline, Drawer, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  AuthProvider,
  LoadingProvider,
  NotificationProvider,
} from '../../context';
import {
  Campus,
  Certificates,
  Directors,
  FollowUpCertificates,
  Periods,
} from '../../feature/collaborators';
import { Users } from '../../feature/collaborators/Users/Users';
import { WorkPlaces } from '../../feature/collaborators/WorkPlaces/WorkPlaces';
import {
  CertificateHistory,
  CertificateRequest,
  RequestProcess,
  StudentsDashboard,
} from '../../feature/students';
import { RoleType, RoutesPath } from '../../utils/enums';
import { RoutesWithNotFound } from '../../utils/helpers';
import { Nav } from '../Nav/Nav';
import { ProtectedRoute } from '../ProtectedRoutes/ProtectedRoutes';
import { SideBar } from '../Sidebar/SideBar';

const drawerWidth = 314;

export function Dashboard(): JSX.Element {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  useEffect(() => {
    // TODO: Add logif if needed
  });

  return (
    <LoadingProvider>
      <NotificationProvider>
        <AuthProvider>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Nav
              drawerWidth={drawerWidth}
              mobileOpen={mobileOpen}
              setMobileOpen={(open) => {
                setMobileOpen(open);
              }}
            />
            <Box
              component="nav"
              sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(!mobileOpen)}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { md: 'block', lg: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}>
                <Toolbar />
                <SideBar />
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: 'none', lg: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}>
                <Toolbar />
                <SideBar />
              </Drawer>
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: { lg: `calc(100% - ${drawerWidth}px)` },
                overflow: 'auto',
              }}>
              <Toolbar />
              <Box>
                <ProtectedRoute roles={[RoleType.ADMIN]}>
                  <RoutesWithNotFound>
                    <Route path={RoutesPath.ADMIN_USERS} element={<Users />} />
                    <Route
                      path={RoutesPath.ADMIN_CATALOG_CAMPUS}
                      element={<Campus />}
                    />
                    <Route
                      path={RoutesPath.ADMIN_CATALOG_CTT}
                      element={<WorkPlaces />}
                    />
                    <Route
                      path={RoutesPath.ADMIN_CATALOG_DIRECTOR}
                      element={<Directors />}
                    />
                    <Route
                      path={RoutesPath.ADMIN_CATALOG_PERIOD}
                      element={<Periods />}
                    />
                    <Route
                      path={RoutesPath.ADMIN_CERTIFICATE}
                      element={<Certificates />}
                    />
                    <Route
                      path={RoutesPath.DASHBOARD}
                      element={
                        <Navigate
                          to={RoutesPath.FOLLOW_UP_CERTIFICATE}
                          replace
                        />
                      }
                    />
                    <Route
                      path={`${RoutesPath.FOLLOW_UP_CERTIFICATE}/*`}
                      element={<FollowUpCertificates />}
                    />
                  </RoutesWithNotFound>
                </ProtectedRoute>
                <ProtectedRoute roles={[RoleType.COLLABORATOR]}>
                  <RoutesWithNotFound>
                    <Route
                      path={RoutesPath.DASHBOARD}
                      element={
                        <Navigate
                          to={RoutesPath.FOLLOW_UP_CERTIFICATE}
                          replace
                        />
                      }
                    />
                    <Route
                      path={`${RoutesPath.FOLLOW_UP_CERTIFICATE}/*`}
                      element={<FollowUpCertificates />}
                    />
                  </RoutesWithNotFound>
                </ProtectedRoute>
                <ProtectedRoute roles={[RoleType.STUDENT]}>
                  <RoutesWithNotFound>
                    <Route
                      path={RoutesPath.STUDENT_CERTIFICATE_REQUEST}
                      element={<CertificateRequest />}
                    />
                    <Route
                      path={`${RoutesPath.STUDENT_CERTIFICATE_REQUEST_PROCESS}/*`}
                      element={<RequestProcess />}
                    />
                    <Route
                      path={RoutesPath.STUDENT_CERTIFICATE_HISTORY}
                      element={<CertificateHistory />}
                    />
                    <Route
                      path={RoutesPath.DASHBOARD}
                      element={<StudentsDashboard />}
                    />
                  </RoutesWithNotFound>
                </ProtectedRoute>
              </Box>
            </Box>
          </Box>
        </AuthProvider>
      </NotificationProvider>
    </LoadingProvider>
  );
}
