import { PendingActionIcon } from '../../../assets/icons';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';

export const InReview = () => {
  const button: ButtonOptions = {
    backgorund: ' #E4F8DD',
    color: '#229A16',
    backgorundHover: ' #E4F8DD',
    colorHover: '#229A16',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={<PendingActionIcon />}
      button={button}
      title={'En revisión'}
    />
  );
};
