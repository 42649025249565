import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesPath } from '../../../../../utils/enums';

export const PaySuccess = () => {
  const navigate = useNavigate();
  const { certificateId } = useParams();

  useEffect(() => {
    if (certificateId) {
      navigate(RoutesPath.STUDENT_CERTIFICATE_REQUEST_PROCESS, {
        state: { certificateStudentId: certificateId },
      });
      return;
    }
    navigate(RoutesPath.STUDENT_GENERAL);
  });
  return <div></div>;
};
