import { FC } from 'react';
import { useAuth } from '../../hooks';
import { RoleType } from '../../utils/enums';

interface Props {
  roles: RoleType[];
  children: JSX.Element | JSX.Element[];
}

export const ProtectedRoute: FC<Props> = ({ roles, children }) => {
  const { user } = useAuth();
  if (!roles.includes(user.rol)) {
    return <></>;
  }
  return <>{children}</>;
};
