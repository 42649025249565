import { useContext } from 'react';
import { WorkPlaceContext } from '../context/WorkPlaceContext';

export const useWorkPlace = () => {
  const {
    workPlaceState,
    setSearch,
    setWorkCenterKeyList,
    setPage,
    setPaginatorOptions,
    setRowsPerPage,
    setModal,
    setOrder,
  } = useContext(WorkPlaceContext);
  const {
    search,
    workCenterKeyList,
    page,
    paginatorOptions,
    rowsPerPage,
    modalRecord,
    orderBy,
    dir,
  } = workPlaceState;

  return {
    search,
    setSearch,
    workCenterKeyList,
    setWorkCenterKeyList,
    page,
    setPage,
    paginatorOptions,
    setPaginatorOptions,
    rowsPerPage,
    setRowsPerPage,
    modalRecord,
    setModal,
    orderBy,
    dir,
    setOrder,
  };
};
