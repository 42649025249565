export const TabFilledIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M2.917 16.667a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V4.583a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h14.167a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875v10.834a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H2.917Zm8-9.73c0 .348.122.643.365.886s.538.365.885.365h4.917V4.583h-6.167v2.355Z" />
    </svg>
  );
};
