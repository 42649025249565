import { Response } from '../../utils/interfaces';
import http, { BASE_URL_API } from '../http/http';

export class CertificateRequestService {
  getPreviewFile(certificateId: number) {
    return http.get<Blob>(`/Constancia/${certificateId}/VistaPrevia`, {
      responseType: 'arraybuffer',
    });
  }

  getPreviewFileURL(certificateId: number): string {
    return `${BASE_URL_API}/Constancia/${certificateId}/VistaPrevia`;
  }

  getAllCategories() {
    return http.get<Response<string[]>>('/Categoria');
  }
}
