import { CalendarToday, Close, Key } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import {
  AlphanumericIcon,
  DateEditIcon,
  DateEndIcon,
  DateStartIcon,
} from '../../../../assets/icons';
import { FieldDisable, ModalBar } from '../../../../components';
import { useLoading, useNotify } from '../../../../hooks';
import { PeriodService } from '../../../../services';
import { validationMsj } from '../../../../utils/consts';
import { DateHelper } from '../../../../utils/helpers';
import { PeriodoDTO } from '../../../../utils/models';
import { usePeriods } from '../hooks/usePeriods';

interface Props {
  onReloadData: () => void;
}

const localeMap = {
  es: esLocale,
};

const validationSchema = Yup.object({
  id: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
  nombre: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
  fechaVacacionesInicio: Yup.date().required(validationMsj.is.required),
  fechaVacacionesFin: Yup.date().required(validationMsj.is.required),
  polizaNumero: Yup.string()
    .required(validationMsj.is.required)
    .trim(validationMsj.is.trim()),
  polizaFechaVigencia: Yup.date().required(validationMsj.is.required),
});

export const PeriodRecord: FC<Props> = ({ onReloadData }) => {
  const { modalRecord, changeModal } = usePeriods();
  const { setLoading } = useLoading();
  const periodService = new PeriodService();
  const { notify, setNotify } = useNotify();

  const formik = useFormik({
    initialValues: {
      id: '',
      nombre: '',
      fechaVacacionesInicio: new Date(),
      fechaVacacionesFin: new Date(),
      polizaNumero: '',
      polizaFechaVigencia: new Date(),
      activo: true,
    },
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const isValid = () => {
    const diffDate = DateHelper.compareDates(
      formik.values.fechaVacacionesInicio,
      formik.values.fechaVacacionesFin,
    );

    if (diffDate <= 0) {
      setNotify({
        ...notify,
        open: true,
        message: 'La fecha de inicio debe ser menor que la fecha final',
        type: 'info',
      });
      return false;
    }

    const diffPolicy = DateHelper.compareDates(
      formik.values.fechaVacacionesFin,
      formik.values.polizaFechaVigencia,
    );

    if (diffPolicy <= 0) {
      setNotify({
        ...notify,
        open: true,
        message: 'La fecha de la póliza debe ser mayor que la fecha final',
        type: 'info',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!formik.isValid) {
      return;
    }

    if (!isValid()) {
      return;
    }

    const body = new PeriodoDTO().deserialize(formik.values);

    switch (modalRecord.type) {
      case 'add':
        body.activo = true;
        createPeriod(body);
        return;
      case 'edit':
        updatePeriod(body);
        return;
    }
  };

  const createPeriod = async (period: PeriodoDTO) => {
    setLoading(true);
    await periodService
      .createPeriod(period)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se creó el periodo correctamente.',
            type: 'success',
          });
          changeModal({ ...modalRecord, show: false, id: null });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updatePeriod = async (period: PeriodoDTO) => {
    setLoading(true);
    await periodService
      .updatePeriod(period)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se actualizó el periodo correctamente.',
            type: 'success',
          });
          changeModal({ ...modalRecord, show: false, id: null });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getPeriodById = async (periodId: string) => {
    setLoading(true);
    await periodService
      .getPeriodById(periodId)
      .then((response) => {
        setLoading(false);
        const res = response.data;

        formik.setValues({
          ...formik.values,
          id: res.data.id,
          nombre: res.data.nombre,
          fechaVacacionesInicio: parseISO(res.data.fechaVacacionesInicio),
          fechaVacacionesFin: parseISO(res.data.fechaVacacionesFin),
          polizaNumero: res.data.polizaNumero,
          polizaFechaVigencia: parseISO(res.data.polizaFechaVigencia),
          activo: res.data.activo,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const errorDatePicker = (value: boolean) => {
    return value ? 'Ingrese una fecha válida.' : '';
  };

  const handleClose = () => {
    formik.resetForm();
    changeModal({ ...modalRecord, show: false, id: null });
  };

  useEffect(() => {
    if (modalRecord.id) {
      getPeriodById(modalRecord.id);
    }
  }, [modalRecord]);

  return (
    <>
      <Dialog open={modalRecord.show} fullWidth maxWidth={'md'}>
        <DialogTitle>
          {modalRecord.type === 'add'
            ? 'Crear un periodo nuevo'
            : 'Editar los datos del periodo'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'add' && (
            <>
              <ModalBar
                startIcon={<CalendarToday sx={{ color: 'grayCustom.main' }} />}
                title="Define el intervalo de los periodos y los datos de la póliza"
              />
            </>
          )}
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<DateEditIcon />}
                title="Edita el intervalo de los periodos y los datos de la póliza"
              />
            </>
          )}
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap.es}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="primary.darker"
                  sx={{ fontWeight: 600, pb: 2 }}>
                  DATOS DEL PERIODO
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                {modalRecord.type === 'add' && (
                  <TextField
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    name="id"
                    label="Clave"
                    variant="outlined"
                    type="text"
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 10,
                      readOnly: modalRecord.type === 'add' ? false : true,
                    }}
                    error={Boolean(formik.errors.id)}
                    helperText={formik.errors.id}
                  />
                )}
                {modalRecord.type === 'edit' && (
                  <FieldDisable
                    icon={<Key sx={{ color: 'grayCustom.main' }} />}
                    title="Clave"
                    subtitle={formik.values.id}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                <TextField
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  name="nombre"
                  label="Descripción"
                  variant="outlined"
                  type="text"
                  fullWidth
                  required
                  inputProps={{ maxLength: 150 }}
                  error={Boolean(formik.errors.nombre)}
                  helperText={formik.errors.nombre}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                <DatePicker
                  label="Inicio del periodo vacacional"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.fechaVacacionesInicio}
                  onChange={(event) => {
                    formik.setFieldValue('fechaVacacionesInicio', event);
                  }}
                  components={{
                    OpenPickerIcon: DateStartIcon,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="dd/MM/yyyy"
                      required
                      error={Boolean(formik.errors.fechaVacacionesInicio)}
                      helperText={errorDatePicker(
                        Boolean(formik.errors.fechaVacacionesInicio),
                      )}
                    />
                  )}
                  maxDate={formik.values.fechaVacacionesFin}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                <DatePicker
                  label="Fin del periodo vacacional"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.fechaVacacionesFin}
                  onChange={(event) => {
                    formik.setFieldValue('fechaVacacionesFin', event);
                  }}
                  components={{
                    OpenPickerIcon: DateEndIcon,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Fin del periodo vacacional"
                      placeholder="dd/MM/yyyy"
                      required
                      error={Boolean(formik.errors.fechaVacacionesFin)}
                      helperText={errorDatePicker(
                        Boolean(formik.errors.fechaVacacionesFin),
                      )}
                    />
                  )}
                  minDate={formik.values.fechaVacacionesInicio}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="primary.darker"
                  sx={{ fontWeight: 600, pb: 2 }}>
                  DATOS DE LA PÓLIZA
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                <TextField
                  value={formik.values.polizaNumero}
                  onChange={formik.handleChange}
                  name="polizaNumero"
                  label="Póliza de seguros vigente"
                  variant="outlined"
                  type="text"
                  fullWidth
                  required
                  inputProps={{ maxLength: 50 }}
                  error={Boolean(formik.errors.polizaNumero)}
                  helperText={formik.errors.polizaNumero}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AlphanumericIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ py: 2, pr: { xs: 0, md: 2 } }}>
                <DatePicker
                  onChange={(event) => {
                    formik.setFieldValue('polizaFechaVigencia', event);
                  }}
                  value={formik.values.polizaFechaVigencia}
                  inputFormat="dd/MM/yyyy"
                  label="Vencimiento de la póliza de seguros"
                  components={{
                    OpenPickerIcon: DateEndIcon,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="dd/MM/yyyy"
                      required
                      error={Boolean(formik.errors.polizaFechaVigencia)}
                      helperText={errorDatePicker(
                        Boolean(formik.errors.polizaFechaVigencia),
                      )}
                    />
                  )}
                  minDate={formik.values.fechaVacacionesFin}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {modalRecord.type === 'add' ? 'Crear periodo' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
