import { ToggleOn } from '@mui/icons-material';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';

export const Valid = () => {
  const button: ButtonOptions = {
    backgorund: '#D8BBFF',
    color: '#6B00FF',
    backgorundHover: '#D8BBFF',
    colorHover: '#6B00FF',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={<ToggleOn sx={{ color: '#6B00FF' }} />}
      button={button}
      title={'VIGENTE'}
    />
  );
};
