import { Box, Grid, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
interface Props {
  title: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  children?: ReactNode;
}

export const ModalBar: FC<Props> = ({
  title,
  startIcon,
  endIcon,
  children,
}) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          my: 2,
          px: 2,
          py: 1,
          bgcolor: 'grayCustom.light',
          borderRadius: '8px',
        }}>
        {!children && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}>
            <Stack
              direction="row"
              alignItems={{ xs: 'flex-start', md: 'center' }}>
              <Box sx={{ width: '20px', height: '20px' }}>{startIcon}</Box>
              <Typography
                variant="subtitle1"
                component="span"
                px={2}
                sx={{
                  display: 'inline',
                  color: 'grayCustom.main',
                  fontSize: '14px',
                  fontWeight: 600,
                }}>
                {title}
              </Typography>
            </Stack>

            <Box sx={{ pl: { xs: 4, md: 0 } }}>{endIcon}</Box>
          </Stack>
        )}
        {children && children}
      </Grid>
    </Grid>
  );
};
