import { Deserializable } from '../interfaces';

export class Constancia implements Deserializable {
  id: number;
  nombre: string;
  descripcion: string;
  esCosto: boolean;
  costo: number;
  esAprobacionEscolar: boolean;
  plantilla: string;
  activo: boolean;
  campuses: string[];
  categorias: string[];

  constructor() {
    this.id = null;
    this.nombre = null;
    this.descripcion = null;
    this.esCosto = null;
    this.costo = null;
    this.esAprobacionEscolar = null;
    this.plantilla = null;
    this.activo = null;
    this.campuses = [];
    this.categorias = [];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
