import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';

export interface DialogTitleProps {
  children?: ReactNode;
  title?: string;
  onClose?: () => void;
}

export interface DialogContentProps {
  children?: React.ReactNode;
}

export interface DialogActionsProps {
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  isValid?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export interface EmptyDialogProps {
  dialog: DialogProps;
  dialogTitle: DialogTitleProps;
  dialogContent: DialogContentProps;
  dialogActions: DialogActionsProps;
  children?: React.ReactNode;
}
export const EmptyDialog: FC<EmptyDialogProps> = ({
  dialog,
  dialogTitle,
  dialogContent,
  dialogActions,
}) => {
  return (
    <Dialog {...dialog}>
      <DialogTitle sx={{ m: 0 }}>
        {dialogTitle.children && dialogTitle.children}

        {dialogTitle.title && (
          <Typography
            variant="h6"
            component="span"
            sx={{ color: 'white', fontWeight: 600 }}>
            {dialogTitle.title}
          </Typography>
        )}

        {dialogTitle.onClose ? (
          <IconButton
            aria-label="close"
            onClick={dialogActions.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ m: 2 }}>
        <Typography
          variant="subtitle2"
          color="grayCustom.main"
          sx={{ fontWeight: 600 }}>
          {dialogContent && dialogContent.children}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 0 }}>
        {dialogActions.children && dialogActions.children}

        {dialogActions.onClose && (
          <Button
            variant="outlined"
            autoFocus
            onClick={dialogActions.onClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {dialogActions.cancelText}
          </Button>
        )}

        {dialogActions.onConfirm && (
          <Button
            variant="contained"
            disabled={!dialogActions.isValid}
            onClick={dialogActions.onConfirm}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {dialogActions.confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
