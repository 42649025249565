export const AdminUserIcon = (props: any) => {
  return (
    <svg
      width={22}
      height={22}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M15.675 13.269c-.596 0-1.104-.21-1.524-.63-.42-.42-.63-.929-.63-1.524 0-.596.21-1.104.63-1.524.42-.42.928-.63 1.524-.63.595 0 1.104.21 1.524.63.42.42.63.928.63 1.524 0 .595-.21 1.103-.63 1.524-.42.42-.929.63-1.524.63Zm-3.805 5.064a.668.668 0 0 1-.687-.687v-.481c0-.398.084-.738.252-1.02.168-.283.405-.5.71-.653a7.586 7.586 0 0 1 1.72-.642 7.83 7.83 0 0 1 1.81-.206c.626 0 1.23.065 1.81.194.58.13 1.154.348 1.719.654.305.137.542.351.71.641.168.29.252.634.252 1.032v.48a.668.668 0 0 1-.687.688H11.87Zm-2.704-7.356c-1.008 0-1.833-.32-2.475-.962-.641-.642-.962-1.467-.962-2.475 0-1.009.32-1.834.962-2.475.642-.642 1.467-.963 2.475-.963 1.009 0 1.834.32 2.475.963.642.641.963 1.466.963 2.475 0 1.008-.321 1.833-.963 2.475-.641.641-1.466.962-2.475.962Zm-6.645 7.356a.668.668 0 0 1-.688-.687v-1.467c0-.52.13-.997.39-1.432.26-.436.649-.768 1.168-.997a17.223 17.223 0 0 1 2.945-1.031c.94-.23 1.883-.344 2.83-.344.382 0 .749.015 1.1.046.352.03.634.069.848.114v.894a3.473 3.473 0 0 0-.31.172 1.59 1.59 0 0 0-.263.218 5.44 5.44 0 0 0-.641-.058c-.23-.007-.474-.011-.734-.011-.87 0-1.718.088-2.543.264-.825.175-1.72.5-2.682.974-.214.106-.39.274-.527.504-.137.229-.206.458-.206.687v.78h6.6v1.374H2.521Zm6.645-8.73c.596 0 1.089-.196 1.479-.585.389-.39.584-.883.584-1.478 0-.596-.195-1.089-.584-1.479-.39-.39-.883-.584-1.479-.584-.595 0-1.088.195-1.478.584-.39.39-.584.883-.584 1.479 0 .595.195 1.088.584 1.478.39.39.883.584 1.478.584Z" />
    </svg>
  );
};
