import { Close, Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useWorkPlace } from '../../hook/useWorkPlace';

interface Props {
  onSearch: (search: string) => void;
}

export const SearchWorkPlace: FC<Props> = ({ onSearch }) => {
  const { search, setSearch } = useWorkPlace();

  const handleSearchPeriodChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    onSearch(value);
  };

  const filterCleaning = () => {
    if (search === '') {
      return;
    }
    setSearch('');
    onSearch('');
  };

  return (
    <Grid item xs={12} md={6}>
      <TextField
        label="Buscar"
        variant="outlined"
        fullWidth
        size={'small'}
        value={search}
        sx={{ py: 1 }}
        onChange={handleSearchPeriodChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
              <Close onClick={filterCleaning} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
