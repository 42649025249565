import React from 'react';

export const VerifiedUserIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.231 9.881 6.75 8.4a.561.561 0 0 0-.412-.169c-.163 0-.3.056-.413.169a.561.561 0 0 0-.169.412c0 .163.056.3.169.413l1.856 1.856a.55.55 0 0 0 .403.169.55.55 0 0 0 .404-.169l3.468-3.469c.1-.1.15-.23.15-.393a.532.532 0 0 0-.15-.394.52.52 0 0 0-.384-.15.52.52 0 0 0-.385.15L8.232 9.88ZM9 16.444c-.05 0-.097-.003-.14-.01a.701.701 0 0 1-.122-.028c-1.675-.5-3.05-1.528-4.125-3.084C3.536 11.766 3 10.056 3 8.194V4.519c0-.238.069-.453.206-.647a1.19 1.19 0 0 1 .525-.422L8.606 1.63c.138-.05.269-.075.394-.075s.256.025.394.075l4.875 1.819c.212.087.387.228.525.422.137.194.206.41.206.647v3.675c0 1.862-.537 3.571-1.613 5.128-1.075 1.556-2.45 2.584-4.125 3.084L9 16.444Zm0-1.125c1.438-.475 2.61-1.372 3.516-2.691.906-1.319 1.359-2.797 1.359-4.434V4.519L9 2.68 4.125 4.52v3.675c0 1.637.453 3.115 1.36 4.434.906 1.319 2.077 2.216 3.515 2.69Z"
        fill="#00AB55"
      />
    </svg>
  );
};
