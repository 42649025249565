import { Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import { MarkChatReadIcon } from '../../../../../assets/icons';
import { EmptyDialog, ModalBar } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { FollowUpCertificatesService } from '../../../../../services';
import { validationMsj } from '../../../../../utils/consts';
import { SolicitudSeguimiento } from '../../../../../utils/models';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
interface Props {
  onReloadData?: () => void;
}
const MAX_LENGTH_DESCRIPTION = 200;

export const RejectedCertificate: FC<Props> = ({ onReloadData }) => {
  const followUpCertificateService = new FollowUpCertificatesService();
  const { modal, setModal } = useFollowUpCertificates();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const formik = useFormik({
    initialValues: { message: '' },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(validationMsj.is.required)
        .max(
          MAX_LENGTH_DESCRIPTION,
          validationMsj.is.maxLength(MAX_LENGTH_DESCRIPTION),
        )
        .trim(validationMsj.is.trim()),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleClose = () => {
    formik.resetForm();
    setModal({
      ...modal,
      show: false,
      certificateEdit: new SolicitudSeguimiento(),
    });
  };

  const handleSubmit = async () => {
    if (!formik.isValid) return;

    setLoading(true);
    await followUpCertificateService
      .rejectCertificate(modal.certificateEdit.id, formik.values.message)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Se ha rechazado la constancia correctamente',
          });
          onReloadData();
          handleClose();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          type: 'error',
          message: 'Error al rechazar la constancia',
        });
      });
  };

  return (
    <>
      <EmptyDialog
        dialog={{ open: modal.show }}
        dialogTitle={{
          title: 'Rechazar esta solicitud de constancia',
          onClose: handleClose,
        }}
        dialogContent={{
          children: (
            <>
              <Grid container>
                <ModalBar
                  startIcon={
                    <MarkChatReadIcon sx={{ color: 'grayCustom.main' }} />
                  }
                  title="Escribe el motivo de rechazo para esta constancia"
                />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600 }}></Typography>
                <Grid item md={12} sx={{ pt: 2 }}>
                  <TextField
                    name="message"
                    placeholder="Escribe el motivo..."
                    variant="outlined"
                    type="text"
                    fullWidth
                    size={'small'}
                    required
                    multiline
                    minRows={4}
                    maxRows={10}
                    InputProps={{
                      inputProps: { maxLength: MAX_LENGTH_DESCRIPTION },
                    }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={!!formik.errors.message}
                    helperText={formik.errors.message}
                  />
                </Grid>
              </Grid>
            </>
          ),
        }}
        dialogActions={{
          cancelText: 'Cancelar',
          onClose: handleClose,
          confirmText: 'Rechazar',
          onConfirm: handleSubmit,
          isValid: formik.isValid && formik.dirty,
        }}
      />
    </>
  );
};
