import { Add, Apartment, Close, Contacts } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { EditSquareIcon } from '../../../../../assets/icons';
import { FieldDisable, ModalBar } from '../../../../../components';
import {
  DragNDrop,
  FileLoaded,
} from '../../../../../components/DragNDrop/DragNDrop';
import { PreviewImage } from '../../../../../components/PreviewImage/PreviewImage';
import { useLoading, useNotify } from '../../../../../hooks';
import { DirectorService } from '../../../../../services';
import {
  commonRegex,
  levelStudyList,
  validationMsj,
} from '../../../../../utils/consts';
import { convertBlobToBase64, getFileSize } from '../../../../../utils/helpers';
import { DataFile } from '../../../../../utils/interfaces';
import { Campus, Director } from '../../../../../utils/models';
import { useDirectors } from '../../hooks/useDirectors';

interface Props {
  onReloadData: () => void;
  campusList: Campus[];
}

type DirectorForm = {
  campus: string;
  nivel: string;
  nombreDirector: string;
  firmaDirector: string;
  activo: boolean;
};

const MAX_LENGTH_NAME = 200;

const initialStateForm: DirectorForm = {
  campus: '',
  nivel: '',
  nombreDirector: '',
  firmaDirector: '',
  activo: true,
};

const validationSchema = Yup.object({
  nombreDirector: Yup.string()
    .required(validationMsj.is.required)
    .max(MAX_LENGTH_NAME, validationMsj.is.maxLength(MAX_LENGTH_NAME))
    .matches(commonRegex.name(), validationMsj.is.onlyText)
    .trim(validationMsj.is.trim()),
  nivel: Yup.string().required(validationMsj.is.required),
  campus: Yup.string().required(validationMsj.is.required),
  activo: Yup.boolean(),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const DirectorRecord: FC<Props> = ({ onReloadData, campusList }) => {
  const directorService = new DirectorService();
  const { modalRecord, changeModalRecord } = useDirectors();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [file, setFile] = useState<DataFile>({
    name: null,
    data: null,
    url: null,
    sizeDescription: null,
    blob: null,
  });
  const [previewImageModal, setPreviewImageModal] = useState({
    image: '',
    open: false,
  });

  const formik = useFormik({
    initialValues: initialStateForm,
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    if (!formik.isValid) {
      return;
    }
    const body = new Director().deserialize(formik.values);
    if (file.name === body.firmaDirector) {
      body.firmaDirector = 'sincambios:sincambios';
    } else if (!file.url) {
      body.firmaDirector = 'borrar:borrar';
    } else {
      body.firmaDirector = `agregar:${file.data.replace(
        'data:image/png;base64,',
        '',
      )}`;
    }

    switch (modalRecord.type) {
      case 'add':
        createDirector(body);
        return;
      case 'edit':
        updateDirector(body);
        return;
    }
  };

  const createDirector = async (director: Director) => {
    setLoading(true);
    await directorService
      .createDirector(director)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Director creado correctamente',
            type: 'success',
          });
          changeModalRecord({
            ...modalRecord,
            show: false,
            directorEdit: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateDirector = async (director: Director) => {
    setLoading(true);
    await directorService
      .updateDirector(director)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Director actualizado correctamente.',
            type: 'success',
          });
          changeModalRecord({
            ...modalRecord,
            show: false,
            directorEdit: null,
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDirectorById = async (director: Director) => {
    setLoading(true);
    await directorService
      .getDirectorByIds(director.nivel, director.campus)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const directorToEdit = new Director().deserialize(res.data);
          formik.setValues({
            ...formik.values,
            campus: directorToEdit.campus,
            nivel: directorToEdit.nivel,
            nombreDirector: directorToEdit.nombreDirector,
            firmaDirector: directorToEdit.firmaDirector,
            activo: directorToEdit.activo,
          });
          setFile({ ...file, name: directorToEdit.firmaDirector });
          if (directorToEdit.firmaDirector) {
            setFileByURL(directorToEdit.firmaDirector);
          }
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error al obtener el director',
          type: 'error',
        });
      });
  };

  const setFileByURL = async (fileName: string) => {
    setLoading(true);
    await directorService
      .getURLElectronicSignature(
        modalRecord.directorEdit.nivel,
        modalRecord.directorEdit.campus,
      )
      .then(async (responseUrl) => {
        const res = responseUrl.data;
        setLoading(false);
        if (res.success) {
          setLoading(true);
          await axios
            .get<Blob>(res.data, {
              responseType: 'blob',
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(async (response) => {
              const url = URL.createObjectURL(response.data);
              const base64 = await convertBlobToBase64(response.data);
              setLoading(false);
              const size = getFileSize(response.data.size);

              setFile({
                ...file,
                name: fileName,
                url,
                data: base64,
                sizeDescription: size,
              });
            });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado al obtener el archivo',
          type: 'error',
        });
      });
  };

  const openPreview = () => {
    setPreviewImageModal({
      ...previewImageModal,
      image: file.data,
      open: true,
    });
  };

  const resetDataFile = () => {
    setFile({ ...file, name: '', url: null, data: null, blob: null });
  };

  const onLoaded = async (files: FileLoaded) => {
    const size = getFileSize(files.list[0].size);

    const url: string = files.list[0].preview;
    const blob: Blob = await fetch(url).then((r) => r.blob());
    const base64 = await convertBlobToBase64(blob);
    formik.setValues({ ...formik.values, firmaDirector: '' });
    setFile({
      ...file,
      url,
      data: base64,
      sizeDescription: size,
      name: files.list[0].name,
    });
  };

  const handleClose = () => {
    resetDataFile();

    changeModalRecord({
      ...modalRecord,
      show: false,
      directorEdit: null,
    });
    setPreviewImageModal({ ...previewImageModal, open: false, image: null });
    formik.resetForm();
  };

  const onDeleteFile = () => {
    resetDataFile();
  };

  useEffect(() => {
    if (modalRecord.directorEdit) {
      getDirectorById(modalRecord.directorEdit);
    }
  }, [modalRecord]);
  return (
    <>
      {previewImageModal.open && (
        <PreviewImage
          image={previewImageModal.image}
          open={previewImageModal.open}
          handleClose={() =>
            setPreviewImageModal({ ...previewImageModal, open: false })
          }
        />
      )}
      <Dialog
        open={modalRecord.show}
        fullWidth
        onClose={handleClose}
        maxWidth={'md'}>
        <DialogTitle>
          {modalRecord.type === 'add'
            ? 'Crear un director nuevo'
            : 'Editar los datos del director'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'add' && (
            <>
              <ModalBar
                startIcon={<Add sx={{ color: 'grayCustom.main' }} />}
                title="Crea un director nuevo"
              />
            </>
          )}
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<EditSquareIcon />}
                title="Edita los datos del director"
              />
            </>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="primary.darker"
                sx={{ fontWeight: 600, pb: 2 }}>
                DATOS DEL DIRECTOR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 2 } }}>
              {modalRecord.type === 'add' && (
                <FormControl
                  fullWidth
                  required
                  variant="outlined"
                  size={'small'}
                  sx={{ my: 2 }}
                  error={!!formik.errors.campus}>
                  <InputLabel sx={{ pt: 1 }}>Campus</InputLabel>
                  <Select
                    name="campus"
                    autoWidth
                    label="Campus"
                    placeholder="Selecciona el campus"
                    value={formik.values.campus}
                    onChange={formik.handleChange}
                    MenuProps={MenuProps}
                    aria-describedby="campusHelperText">
                    {campusList
                      .sort((a, b) => a.nombre.localeCompare(b.nombre))
                      .map((campus) => (
                        <MenuItem key={campus.id} value={campus.id}>
                          {campus.nombre}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText id="campusHelperText">
                    {formik.errors.campus as string}
                  </FormHelperText>
                </FormControl>
              )}
              {modalRecord.type === 'edit' && (
                <FieldDisable
                  icon={<Apartment sx={{ color: 'grayCustom.main' }} />}
                  title="Campus"
                  subtitle={formik.values.campus}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {modalRecord.type === 'add' && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  sx={{ my: 2 }}
                  required
                  error={!!formik.errors.nivel}>
                  <InputLabel sx={{ pt: 1 }}>Nivel</InputLabel>
                  <Select
                    name="nivel"
                    autoWidth
                    label="Nivel"
                    placeholder="Selecciona el nivel"
                    value={formik.values.nivel}
                    onChange={formik.handleChange}
                    MenuProps={MenuProps}
                    aria-describedby="levelHelperText">
                    {levelStudyList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((levelStudy) => (
                        <MenuItem key={levelStudy.key} value={levelStudy.key}>
                          {levelStudy.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText id="levelHelperText">
                    {formik.errors.nivel as string}
                  </FormHelperText>
                </FormControl>
              )}
              {modalRecord.type === 'edit' && (
                <FieldDisable
                  icon={<Contacts sx={{ color: 'grayCustom.main' }} />}
                  title="Nivel"
                  subtitle={formik.values.nivel}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} sx={{ py: 2 }}>
              <TextField
                name="nombreDirector"
                label="Nombre del director"
                placeholder="Agrega el nombre del director"
                variant="outlined"
                type="text"
                fullWidth
                required
                InputProps={{ inputProps: { maxLength: MAX_LENGTH_NAME } }}
                value={formik.values.nombreDirector}
                onChange={formik.handleChange}
                error={!!formik.errors.nombreDirector}
                helperText={formik.errors.nombreDirector}
              />
            </Grid>
            <Grid item xs={12}>
              <DragNDrop
                fileOptions={{
                  type: 'image',
                  maxSizeInMB: 1,
                  accept: { 'image/png': ['.png'] },
                }}
                existFile={file}
                title={
                  <Typography
                    variant="h6"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 600 }}>
                    Puedes arrastrar una imagen o{' '}
                    <Typography
                      variant="h6"
                      component="span"
                      color="primary.main"
                      sx={{ display: 'inline', fontWeight: 600 }}>
                      cargarla desde aquí
                    </Typography>{' '}
                  </Typography>
                }
                subtitle={
                  <Typography
                    variant="body1"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 500, fontSize: '12px' }}>
                    *El formato aceptado para la imagen de la firma es{' '}
                    <Typography
                      variant="body1"
                      component="span"
                      color="grayCustom.main"
                      sx={{
                        display: 'inline',
                        fontWeight: 500,
                        fontSize: '12px',
                      }}>
                      .png
                    </Typography>{' '}
                    <span style={{ display: 'block ' }}>
                      (Tamaño máximo 1MB)
                    </span>
                  </Typography>
                }
                onLoaded={onLoaded}
                onDelete={onDeleteFile}
                onPreview={openPreview}
              />
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ pb: 2 }}>
            <Typography variant="subtitle2" color="grayCustom.main" sx={{}}>
              Activar el director
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                name="activo"
                label=""
                checked={formik.values.activo}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            {modalRecord.type === 'add' ? 'Crear director' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
