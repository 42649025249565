import { Box, Grid, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import { LabelCheckIcon, MarkFillIcon } from '../../../../../assets/icons';
import { CertificateStatus } from '../../../../../utils/enums';
import { ProcessBarTitle, RequestProcessStep } from '../../enums/enums';
import { useRequestProcess } from '../../hooks/useRequestProcess';
import { DownloadCertificate } from '../DownloadCertificate/DownloadCertificate';
import { HeadTab } from '../HeadTab/HeadTab';
import { MakePay } from '../MakePay/MakePay';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { VerifyData } from '../VerifyData/VerifyData';
interface Props {
  errorPay: boolean;
}
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const RequestProcessTabs: FC<Props> = ({ errorPay }) => {
  const theme = useTheme();
  const iconColor = theme.palette.primary.dark;
  const iconDisabledColor = theme.palette.grayCustom.main;
  const {
    certificate,
    verifyDataTab,
    payTab,
    downloadTab,
    setProgressBar,
    currentTabIndex,
    setCurrentTabIndex,
    setVerifyDataTab,
    setPayTab,
    setDownloadTab,
  } = useRequestProcess();

  const handleChangeTab = (index: RequestProcessStep) => {
    switch (index) {
      case RequestProcessStep.VERIFY_DATA:
        setCurrentTabIndex(index);
        setProgressBar({
          title: ProcessBarTitle.VERIFY_DATA,
          percentage: certificate.esCosto ? 33 : 50,
        });
        setVerifyDataTab({
          ...verifyDataTab,
          tab: { ...verifyDataTab.tab, show: true },
        });
        break;
      case RequestProcessStep.MAKE_PAY:
        setCurrentTabIndex(index);
        setProgressBar({
          title: ProcessBarTitle.MAKE_PAY,
          percentage: 66,
        });
        setPayTab({ ...payTab, tab: { ...payTab.tab, show: true } });
        break;
      case RequestProcessStep.DOWNLOAD_CERTIFICATE:
        setCurrentTabIndex(index);
        setProgressBar({
          title: ProcessBarTitle.DOWNLOAD_CERTIFICATE,
          percentage: 100,
        });
        setDownloadTab({
          ...downloadTab,
          tab: { ...downloadTab.tab, show: true, valid: true },
        });

        setPayTab({ ...payTab, tab: { ...payTab.tab, valid: true } });

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (errorPay) {
      handleChangeTab(RequestProcessStep.MAKE_PAY);
      return;
    }
    if (certificate.estatusId === CertificateStatus.VALID) {
      handleChangeTab(RequestProcessStep.DOWNLOAD_CERTIFICATE);
      return;
    }

    if (certificate.esCosto) {
      setProgressBar({ title: ProcessBarTitle.VERIFY_DATA, percentage: 33 });
    } else {
      setProgressBar({ title: ProcessBarTitle.VERIFY_DATA, percentage: 50 });
    }
  }, [certificate.id]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container justifyContent="space-around">
          <Grid
            item
            lg={4}
            sx={{
              display: {
                xs: verifyDataTab.tab.show ? 'block' : 'none',
                md: 'block',
              },
            }}>
            <HeadTab
              icon={<LabelCheckIcon fill={iconColor} />}
              title="Verificar datos"
              description="Completa los campos requeridos"
            />
          </Grid>
          {certificate.esCosto && (
            <Grid
              item
              lg={4}
              sx={{
                display: {
                  xs: payTab.tab.show ? 'block' : 'none',
                  md: 'block',
                },
              }}>
              <HeadTab
                icon={
                  payTab.tab.valid ? (
                    <LabelCheckIcon fill={iconColor} />
                  ) : (
                    <MarkFillIcon fill={iconDisabledColor} />
                  )
                }
                title="Realizar pago"
                description="Completa los datos del pago"
              />
            </Grid>
          )}
          <Grid
            item
            lg={4}
            sx={{
              display: {
                xs: downloadTab.tab.show ? 'block' : 'none',
                md: 'block',
              },
            }}>
            <HeadTab
              icon={
                downloadTab.tab.valid ? (
                  <LabelCheckIcon fill={iconColor} />
                ) : (
                  <MarkFillIcon fill={iconDisabledColor} />
                )
              }
              title="Descargar constancia"
              description="Descarga el formato electrónico"
            />
          </Grid>
        </Grid>
      </Box>
      <ProgressBar />
      <TabPanel value={currentTabIndex} index={RequestProcessStep.VERIFY_DATA}>
        <VerifyData handleChangeTab={handleChangeTab} />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={RequestProcessStep.MAKE_PAY}>
        <MakePay handleChangeTab={handleChangeTab} />
      </TabPanel>
      <TabPanel
        value={currentTabIndex}
        index={RequestProcessStep.DOWNLOAD_CERTIFICATE}>
        <DownloadCertificate />
      </TabPanel>
    </Box>
  );
};
