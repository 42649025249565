import { ToggleOff, ToggleOn } from '@mui/icons-material';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { TrashIcon } from '../../../../../assets/icons';
import {
  ConfirmDialog,
  ConfirmDialogOptions,
  EmptyTable,
  FilterTableMenu,
  StatusItem,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { DirectorService } from '../../../../../services';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import {
  getCampusNameById,
  getLevelNameById,
} from '../../../../../utils/helpers';
import { Campus, Director } from '../../../../../utils/models';
import { DirectorTableColumn } from '../../enums/DirectorTableColumn';
import { useDirectors } from '../../hooks/useDirectors';
import { DirectorTableActions } from '../DirectorTableActions/DirectorTableActions';

interface Props {
  onReloadData: () => void;
  campusList: Campus[];
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const DirectorsTable: FC<Props> = ({
  onReloadData,
  campusList,
  onTableFilter,
}) => {
  const directorService = new DirectorService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const theme = useTheme();
  const palette = theme.palette;
  const {
    directorList,
    paginatorOptions,
    page,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    modalRecord,
    changeModalRecord,
    orderBy,
    dir,
    setOrder,
    changeStatus,
  } = useDirectors();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogOptions>({
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  });

  const deleteDirector = async (director: Director) => {
    setLoading(true);
    await directorService
      .deleteDirector(director.nivel, director.campus)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Se eliminó el director correctamente.',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onAlert = async (
    director: Director,
    type: 'edit' | 'delete',
    subTitle: string,
  ) => {
    setConfirmDialog({
      title:
        type === 'edit'
          ? 'Modificar el status del director'
          : 'Eliminar el director',
      subTitle,
      confirmText:
        type === 'edit'
          ? director.activo
            ? 'Sí, desactivar'
            : 'Sí, activar'
          : 'Sí, eliminar',
      cancelText: 'No, Cancelar',
      open: true,
      startIcon:
        type === 'edit' ? (
          director.activo ? (
            <ToggleOn sx={{ color: 'grayCustom.main' }} />
          ) : (
            <ToggleOff sx={{ color: 'grayCustom.main' }} />
          )
        ) : (
          <TrashIcon fill={palette.grayCustom.main} />
        ),
      endIcon:
        type === 'edit' ? (
          <StatusItem
            status={!director.activo}
            bgColor={director.activo ? palette.grayCustom.darker : null}
            bgText={director.activo ? palette.grayCustom.main : null}
          />
        ) : null,
      onConfirm: () => {
        setConfirmDialog({ ...confirmDialog, open: false });
        switch (type) {
          case 'delete':
            deleteDirector(director);
            break;
          case 'edit':
            updateStatusDirector(director, !director.activo);
            break;
        }
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    changePage(value);
    onTableFilter(rowsPerPage, value, orderBy, dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    changeRowsPerPage(value);
    const valuePage = 1;
    changePage(valuePage);
    onTableFilter(value, valuePage, orderBy, dir);
  };

  const updateStatusDirector = async (director: Director, status: boolean) => {
    setLoading(true);
    await directorService
      .updateStatusDirector(director.nivel, director.campus, status)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Director actualizado correctamente',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onOrderUpward = (columnName: DirectorTableColumn) => {
    setOrder(columnName, DirectionOptions.ASC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.ASC);
  };

  const onOrderFalling = (columnName: DirectorTableColumn) => {
    setOrder(columnName, DirectionOptions.DESC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.DESC);
  };

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                Nombre del director
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(DirectorTableColumn.NOMBRE)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(DirectorTableColumn.NOMBRE)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Nivel
                <FilterTableMenu
                  onOrderUpward={() => onOrderUpward(DirectorTableColumn.NIVEL)}
                  onOrderFalling={() =>
                    onOrderFalling(DirectorTableColumn.NIVEL)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Campus
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(DirectorTableColumn.CAMPUS)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(DirectorTableColumn.CAMPUS)
                  }
                />
              </TableCell>
              <TableCell variant="head">
                Status
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(DirectorTableColumn.ESTATUS)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(DirectorTableColumn.ESTATUS)
                  }
                />
              </TableCell>
              <TableCell variant="head" align="center">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {directorList.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.nombreDirector}</TableCell>
                <TableCell>{getLevelNameById(row.nivel)}</TableCell>
                <TableCell>
                  {getCampusNameById(row.campus, campusList)}
                </TableCell>
                <TableCell>
                  {' '}
                  <StatusItem status={row.activo} />{' '}
                </TableCell>
                <TableCell align="center">
                  <DirectorTableActions
                    director={row}
                    onEdit={() =>
                      changeModalRecord({
                        ...modalRecord,
                        show: true,
                        type: 'edit',
                        directorEdit: row,
                      })
                    }
                    onChangeStatus={() =>
                      onAlert(
                        row,
                        'edit',
                        `¿Deseas cambiar el status del director a “${
                          row.activo ? 'INACTIVO' : 'ACTIVO'
                        }”?`,
                      )
                    }
                    onDelete={() =>
                      onAlert(
                        row,
                        'delete',
                        `¿Deseas eliminar el director “${row.nombreDirector}”?`,
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable elements={directorList} />
      <Divider />
      <TablePagination
        rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
        component="div"
        count={paginatorOptions.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        SelectProps={{
          inputProps: {
            'aria-label': 'Filas por página',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
