export const DateEditIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={19}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.75 17.334a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V3.167a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h1.354v-.583a.64.64 0 0 1 .198-.47.64.64 0 0 1 .469-.197c.194 0 .357.066.49.198a.64.64 0 0 1 .197.469v.583h7.084v-.583a.64.64 0 0 1 .198-.47.64.64 0 0 1 .468-.197c.195 0 .358.066.49.198a.64.64 0 0 1 .198.469v.583h1.354a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875v6.27h-1.25V7.126H1.75v8.959h6.646v1.25H1.75Zm14.77-4.563-1.478-1.479.604-.604a.592.592 0 0 1 .437-.167c.18 0 .327.056.438.167l.604.604a.592.592 0 0 1 .167.438c0 .18-.056.326-.167.437l-.604.604Zm-6.874 5.084v-.917c0-.083.014-.16.041-.23a.635.635 0 0 1 .146-.208l4.313-4.312 1.479 1.479-4.313 4.313a.636.636 0 0 1-.208.145.612.612 0 0 1-.229.042h-.917a.3.3 0 0 1-.218-.094.3.3 0 0 1-.094-.218Z" />
    </svg>
  );
};
