import { Route, Routes } from 'react-router-dom';
import { RoutesPath } from '../../../utils/enums';
import { PayError } from './components/PayError/PayError';
import { PaySuccess } from './components/PaySuccess/PaySuccess';
import { RequestProcessPage } from './components/RequestProcessPage/RequestProcessPage';
import { ValidatePay } from './components/ValidatePay/ValidatePay';
import { RequestProcessProvider } from './context/RequestProcessProvider';

export const RequestProcess = () => {
  return (
    <RequestProcessProvider>
      <Routes>
        <Route path="/" element={<RequestProcessPage />} />
        <Route
          path={RoutesPath.STUDENT_VALID_CERTIFICATE}
          element={<ValidatePay />}
        />
        <Route
          path={RoutesPath.STUDENT_CERTIFICATE_PAY_SUCCESS}
          element={<PaySuccess />}
        />
        <Route
          path={RoutesPath.STUDENT_CERTIFICATE_PAY_ERROR}
          element={<PayError />}
        />
      </Routes>
    </RequestProcessProvider>
  );
};
