export const OrderDescIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.875 16h-3.75a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h3.75a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625Zm10-10.75H2.125a.607.607 0 0 1-.625-.625A.607.607 0 0 1 2.125 4h13.75a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625Zm-5 5.375h-8.75A.607.607 0 0 1 1.5 10a.607.607 0 0 1 .625-.625h8.75A.607.607 0 0 1 11.5 10a.607.607 0 0 1-.625.625ZM15.92 17.837l2.905-2.7a.504.504 0 0 0 .175-.38.505.505 0 0 0-.175-.381.583.583 0 0 0-.41-.163.55.55 0 0 0-.39.145l-1.93 1.794V7.544a.509.509 0 0 0-.166-.39.592.592 0 0 0-.42-.154.592.592 0 0 0-.418.154.509.509 0 0 0-.166.39v8.608l-1.93-1.795a.583.583 0 0 0-.41-.163.583.583 0 0 0-.41.163.505.505 0 0 0-.175.381c0 .145.052.266.156.363l2.944 2.736c.065.06.13.103.195.127a.613.613 0 0 0 .215.036c.078 0 .15-.012.214-.036a.598.598 0 0 0 .195-.127Z" />
    </svg>
  );
};
