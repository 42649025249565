import { Route } from 'react-router-dom';
import { Dashboard } from '../components';
import { LoadingProvider, NotificationProvider } from '../context';
import { ValidCertificate } from '../feature/ValidCertificate/ValidCertificate';
import { RoutesPath } from '../utils/enums';
import { RoutesWithNotFound } from '../utils/helpers';
import { AuthRouter } from './AuthRouter';

export function AppRouter() {
  return (
    <RoutesWithNotFound>
      <Route path="/Auth/*" element={<AuthRouter />} />
      <Route
        path={RoutesPath.STUDENT_VALID_CERTIFICATE}
        element={
          <>
            <LoadingProvider>
              <NotificationProvider>
                <ValidCertificate />
              </NotificationProvider>
            </LoadingProvider>
          </>
        }
      />
      <Route path="/*" element={<Dashboard />} />
    </RoutesWithNotFound>
  );
}
