import { FC } from 'react';
import { CertificateStatus } from '../../utils/enums';
import { Expired } from './Expired/Expired';
import { Historical } from './Historical/Historical';
import { InReview } from './InReview/InReview';
import { NotAccepted } from './NotAccepted/NotAccepted';
import { PendingPayment } from './PendingPayment/PendingPayment';
import { Valid } from './Valid/Valid';

interface Props {
  statusId: number;
}

export const CertificateStatusItem: FC<Props> = ({ statusId }) => {
  switch (statusId) {
    case CertificateStatus.IN_REVIEW:
      return <InReview />;
    case CertificateStatus.PENDING_PAYMENT:
      return <PendingPayment />;
    case CertificateStatus.REJECTED:
      return <NotAccepted />;
    case CertificateStatus.VALID:
      return <Valid />;
    case CertificateStatus.EXPIRED:
      return <Expired />;
    case CertificateStatus.HISTORICAL:
      return <Historical />;
    default:
      return <p>No disponible</p>;
  }
};
