import { useTheme } from '@mui/material';
import { CreditScoreIcon } from '../../../assets/icons';
import { ButtonOptions, StatusButton } from '../StatusButton/StatusButton';

export const PendingPayment = () => {
  const theme = useTheme();
  const palette = theme.palette;
  const button: ButtonOptions = {
    backgorund: palette.primary.main,
    color: 'white',
    backgorundHover: palette.primary.main,
    colorHover: 'white',
    fullWidth: true,
  };
  return (
    <StatusButton
      icon={<CreditScoreIcon fill="white" />}
      button={button}
      title={'PAGO PENDIENTE'}
    />
  );
};
