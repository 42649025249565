import { Route, Routes } from 'react-router-dom';
import { AuthError, AuthLogin, AuthSuccessPage } from '../feature/auth';

export const AuthRouter = () => {
  return (
    <Routes>
      <Route path="success" element={<AuthSuccessPage />} />
      <Route path="error-session" element={<AuthError />} />
      <Route path="login" element={<AuthLogin />} />
      <Route path="*" element={<AuthError />} />
    </Routes>
  );
};
