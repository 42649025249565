import { Response } from '../../utils/interfaces';
import { PageResult, Usuario } from '../../utils/models';
import http from '../http/http';

export class UsersService {
  getAllUser(
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<Usuario[]>>>('/Usuario', {
      params: { filtro, pageSize, pageNumber, ordenar, dir, inactivos },
    });
  }

  getUserById(userId: string) {
    return http.get<Response<PageResult<Usuario[]>>>(`/Usuario/${userId}`);
  }

  createUser(user: Usuario) {
    return http.post<Response<never>>('/Usuario', user);
  }

  updateUser(user: Usuario) {
    return http.put<Response<never>>('/Usuario', user);
  }

  updateStatusUser(userId: string, status: boolean) {
    return http.post<Response<never>>(`/Usuario/${userId}/Estatus`, status);
  }

  deleteUser(userId: string) {
    return http.delete<Response<never>>(`/Usuario/${userId}`);
  }

  validUserIfExist(payRoll: string) {
    return http.get<Response<Usuario>>(`/Usuario/${payRoll}/ValidaExistencia`);
  }
}
