import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { ContainerTable, ToolbarModule } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { PeriodService } from '../../../../../services';
import { PeriodoDTO } from '../../../../../utils/models';
import { usePeriods } from '../../hooks/usePeriods';
import { PeriodRecord } from '../../modals/PeriodRecord';
import { PeriodTable } from '../PeriodTable/PeriodTable';
import { SearchPeriod } from '../SearchPeriod/SearchPeriod';

export const PeriodPage = () => {
  const periodService = new PeriodService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    reloadPeriod,
    changePaginatorOptions,
    rowsPerPage,
    page,
    search,
    changeModal,
    modalRecord,
    orderBy,
    dir,
  } = usePeriods();

  const getAllPeriods = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await periodService
      .getAllPeriods(pageSize, pageNumber, ordenar, dir, filtro, true)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró una periodo',
              type: 'info',
            });
          }
          const data: PeriodoDTO[] = res.data.data.map((period) =>
            new PeriodoDTO().deserialize(period),
          );
          reloadPeriod(data);
          changePaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = (value: string) => {
    const valuePage = 1;
    getAllPeriods(value, rowsPerPage, valuePage, orderBy, dir);
  };

  const onReloadData = () => {
    getAllPeriods(search, rowsPerPage, page, orderBy, dir);
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllPeriods(search, pageSize, pageNumber, orderBy, asc);
  };

  useEffect(() => {
    onReloadData();
  }, []);

  return (
    <>
      {modalRecord.show && (
        <PeriodRecord onReloadData={onReloadData}></PeriodRecord>
      )}

      <Grid container>
        <ToolbarModule
          title="Configura intervalos de tiempo y periodos"
          descriptionTmp={
            <>
              <Typography variant="subtitle2" color="grayCustom.main">
                Define periodos de vaciones y pólizas de seguros, estableciendo
                un intervalo de tiempo personalizado mediante las
                {'  '}
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="primary"
                  display={'inline'}>
                  fechas de inicio y finalización.
                </Typography>
              </Typography>
            </>
          }
        />
        <ContainerTable
          title="Periodos"
          actions={
            <Grid container px={0}>
              <SearchPeriod onSearch={onSearch} />
              <Grid
                item
                container
                direction="row"
                xs={12}
                md={6}
                justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    ml: { xs: 0, md: 2 },
                    my: 1,
                    py: 1,
                    width: { xs: '100%', md: 'auto' },
                    justifyContent: { xs: 'flex-start', md: 'center' },
                  }}
                  startIcon={<Add />}
                  onClick={() =>
                    changeModal({
                      ...modalRecord,
                      show: true,
                      type: 'add',
                      id: null,
                    })
                  }>
                  Crear periodo
                </Button>
              </Grid>
            </Grid>
          }
          table={
            <Grid item md={12} sx={{ mt: 4 }}>
              <PeriodTable
                onReloadData={onReloadData}
                onTableFilter={onTableFilter}
              />
            </Grid>
          }
        />
      </Grid>
    </>
  );
};
