import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../../services';

export const AuthError = () => {
  const authService = new AuthService();
  const navigate = useNavigate();
  const handleLogin = () => {
    window.location.replace(authService.getLoginURL());
  };
  return (
    <Grid
      sx={{ width: '100%', height: '100%' }}
      container
      alignItems="center"
      justifyContent="center">
      <Grid item xs={6} container justifyContent="center">
        <Typography
          variant="h1"
          color="secondary"
          align="center"
          sx={{ fontSize: '32px', display: 'block', width: '100%' }}>
          Sesión no encontrada
        </Typography>
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{ my: 2, width: '200px' }}>
          Iniciar sesión
        </Button>
      </Grid>
    </Grid>
  );
};
