import { ToggleOff, ToggleOn } from '@mui/icons-material';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import {
  ConfirmDialog,
  ConfirmDialogOptions,
  FilterTableMenu,
  StatusItem,
} from '../../../../../components';
import { EmptyTable } from '../../../../../components/EmptyTable/EmptyTable';
import { useLoading, useNotify } from '../../../../../hooks';
import { CertificatesService } from '../../../../../services';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { toCurrencyFormat } from '../../../../../utils/helpers';
import { Constancia } from '../../../../../utils/models';
import { CertificateTableColumn } from '../../enums/CertificateTableColumn';
import { useCertificates } from '../../hooks/useCertificates';
import { CertificateTableActions } from '../CertificateTableActions/CertificateTableActions';
interface Props {
  onReloadData: () => void;
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const CertificateTable: FC<Props> = ({
  onReloadData,
  onTableFilter,
}) => {
  const certificateService = new CertificatesService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const theme = useTheme();
  const palette = theme.palette;

  const {
    setStatus,
    certificateList,
    paginatorOptions,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setModalRecord,
    modalRecord,
    orderBy,
    dir,
    setOrder,
  } = useCertificates();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogOptions>({
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  });

  const onAlert = async (
    certificate: Constancia,
    type: 'edit',
    subTitle: string,
  ) => {
    setConfirmDialog({
      title: type === 'edit' ? 'Modificar el status del constancia' : null,
      subTitle,
      confirmText: certificate.activo ? 'Sí, desactivar' : 'Sí, activar',
      cancelText: 'No, Cancelar',
      open: true,
      startIcon: certificate.activo ? (
        <ToggleOn sx={{ color: 'grayCustom.main' }} />
      ) : (
        <ToggleOff sx={{ color: 'grayCustom.main' }} />
      ),
      endIcon: (
        <StatusItem
          status={!certificate.activo}
          bgColor={certificate.activo ? palette.grayCustom.darker : null}
          bgText={certificate.activo ? palette.grayCustom.main : null}
        />
      ),
      onConfirm: () => {
        setConfirmDialog({ ...confirmDialog, open: false });
        switch (type) {
          case 'edit':
            updateStatusCertificate(certificate.id, !certificate.activo);
            break;
        }
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    setPage(value);
    onTableFilter(rowsPerPage, value, orderBy, dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    const valuePage = 1;
    setPage(valuePage);
    onTableFilter(value, valuePage, orderBy, dir);
  };

  const updateStatusCertificate = async (id: number, status: boolean) => {
    setLoading(true);
    await certificateService
      .updateStatusCertificate(id, status)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia actualizada correctamente',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onOrderUpward = (columnName: CertificateTableColumn) => {
    setOrder(columnName, DirectionOptions.ASC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.ASC);
  };

  const onOrderFalling = (columnName: CertificateTableColumn) => {
    setOrder(columnName, DirectionOptions.DESC);
    onTableFilter(rowsPerPage, page, columnName, DirectionOptions.DESC);
  };

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                Nombre{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateTableColumn.NOMBRE)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateTableColumn.NOMBRE)
                  }
                />
              </TableCell>
              <TableCell variant="head" sx={{ minWidth: '200px' }}>
                Descripción{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateTableColumn.DESCRIPCION)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateTableColumn.DESCRIPCION)
                  }
                />
              </TableCell>
              <TableCell variant="head" sx={{ minWidth: '150px' }}>
                Costo{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateTableColumn.COSTO)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateTableColumn.COSTO)
                  }
                />
              </TableCell>
              <TableCell variant="head" sx={{ minWidth: '200px' }}>
                Aprobación{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateTableColumn.APROBACION)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateTableColumn.APROBACION)
                  }
                />
              </TableCell>
              <TableCell variant="head" sx={{ minWidth: '150px' }}>
                Status{' '}
                <FilterTableMenu
                  onOrderUpward={() =>
                    onOrderUpward(CertificateTableColumn.STATUS)
                  }
                  onOrderFalling={() =>
                    onOrderFalling(CertificateTableColumn.STATUS)
                  }
                />
              </TableCell>
              <TableCell variant="head" align="center" sx={{ pt: 1 }}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificateList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>{row.descripcion}</TableCell>
                <TableCell align="center">
                  {row.esCosto ? toCurrencyFormat(row.costo) : 'Gratuita'}
                </TableCell>
                <TableCell align="center">
                  {row.esAprobacionEscolar ? 'Sí' : 'No'}
                </TableCell>
                <TableCell>
                  {' '}
                  <StatusItem status={row.activo} />{' '}
                </TableCell>

                <TableCell align="center">
                  <CertificateTableActions
                    certificate={row}
                    onEdit={() =>
                      setModalRecord({
                        ...modalRecord,
                        show: true,
                        type: 'edit',
                        certificateEdit: row,
                      })
                    }
                    onChangeStatus={() =>
                      onAlert(
                        row,
                        'edit',
                        `¿Deseas cambiar el status del constancia a “${
                          row.activo ? 'INACTIVO' : 'ACTIVO'
                        }”?`,
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable elements={certificateList} />
      <Divider />
      <TablePagination
        rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
        component="div"
        count={paginatorOptions.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        SelectProps={{
          inputProps: {
            'aria-label': 'Filas por página',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
