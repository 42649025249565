export const ContactSupportFillIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#232D38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m10.73 18.333-.21-2.291h-.832c-1.987 0-3.681-.702-5.084-2.105C3.201 12.535 2.5 10.84 2.5 8.854c0-1.986.705-3.68 2.115-5.083 1.41-1.403 3.114-2.104 5.114-2.104.986 0 1.9.177 2.74.53a6.66 6.66 0 0 1 2.198 1.49 6.947 6.947 0 0 1 1.468 2.26c.355.869.532 1.817.532 2.844 0 1.862-.525 3.657-1.573 5.386-1.049 1.73-2.504 3.114-4.365 4.156Zm-1-4.646c.221 0 .409-.076.562-.229a.765.765 0 0 0 .229-.562.765.765 0 0 0-.23-.563.765.765 0 0 0-.562-.229.765.765 0 0 0-.562.23.764.764 0 0 0-.23.562c0 .222.077.41.23.562.152.153.34.23.562.23Zm-.043-2.833c.14 0 .26-.049.365-.146a.659.659 0 0 0 .198-.375c.042-.194.129-.382.26-.562.132-.18.337-.41.615-.688.375-.375.639-.719.792-1.031.152-.313.229-.65.229-1.01 0-.625-.212-1.14-.636-1.542-.423-.403-.99-.604-1.697-.604-.487 0-.935.107-1.344.323-.41.215-.74.517-.99.906a.387.387 0 0 0-.042.385.58.58 0 0 0 .313.302c.111.042.23.042.354 0a.636.636 0 0 0 .313-.229c.152-.222.343-.385.573-.49.229-.104.482-.156.76-.156.417 0 .743.101.98.302.235.202.353.47.353.803 0 .263-.076.53-.229.802-.153.27-.423.607-.812 1.01-.292.306-.5.559-.625.76a1.657 1.657 0 0 0-.23.72.447.447 0 0 0 .126.364.49.49 0 0 0 .374.156Zm2.084 3.938v1.291a10.499 10.499 0 0 0 2.677-3.385c.646-1.299.969-2.6.969-3.907 0-1.722-.538-3.131-1.615-4.229-1.076-1.097-2.434-1.646-4.073-1.646-1.666 0-3.08.58-4.24 1.74-1.16 1.16-1.739 2.56-1.739 4.198 0 1.639.58 3.038 1.74 4.198 1.16 1.16 2.559 1.74 4.197 1.74h2.084Z" />
    </svg>
  );
};
