import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  actions: ReactNode;
  table: ReactNode;
}

export const ContainerTable: FC<Props> = ({ title, actions, table }) => {
  return (
    <Grid container sx={{ boxShadow: 1, borderRadius: '16px' }}>
      <Grid
        item
        xs={12}
        py={1}
        sx={{
          bgcolor: '#F4F6F8',
          borderTopLeftRadius: '16px',
          borderTopLRightRadius: '16px',
        }}
      >
        <Typography
          color="secondary"
          px={2}
          py={1}
          sx={{
            fontWeight: { xs: 500, lg: 600 },
            fontSize: { xs: '15px', lg: '18px' },
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} pt={2} px={2}>
        {actions}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ py: 1, display: { xs: 'none', lg: 'block' } }}
      ></Grid>
      <Grid item xs={12} px={2}>
        {table}
      </Grid>
    </Grid>
  );
};
