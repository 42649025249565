import { SolicitudDetalle } from './../../../../utils/models/solicitud-detalle';
import { Constancia } from '../../../../utils/models';
import {
  DownloadTab,
  PayTab,
  PreviewFileRequestProcess,
  RequestProcessState,
  RequestProgressBar,
  VerifyDataTab,
} from '../interfaces/interfaces';

type RequestProcessAction =
  | { type: 'setCertificate'; certificate: SolicitudDetalle }
  | { type: 'setVerifyDataTab'; verifyDataTab: VerifyDataTab }
  | { type: 'setPayTab'; payTab: PayTab }
  | { type: 'setDownloadTab'; downloadTab: DownloadTab }
  | { type: 'setPreviewData'; previewOptions: PreviewFileRequestProcess }
  | { type: 'setProgressBar'; progressBar: RequestProgressBar }
  | { type: 'setCurrentTabIndex'; index: number };

export const requestProcessReducer = (
  state: RequestProcessState,
  action: RequestProcessAction,
): RequestProcessState => {
  switch (action.type) {
    case 'setCertificate':
      return {
        ...state,
        certificate: action.certificate,
      };
    case 'setVerifyDataTab':
      return {
        ...state,
        verifyDataTab: action.verifyDataTab,
      };
    case 'setPayTab':
      return {
        ...state,
        payTab: action.payTab,
      };
    case 'setDownloadTab':
      return {
        ...state,
        downloadTab: action.downloadTab,
      };
    case 'setPreviewData':
      return {
        ...state,
        previewFile: action.previewOptions,
      };
    case 'setProgressBar':
      return {
        ...state,
        progressBar: action.progressBar,
      };
    case 'setCurrentTabIndex':
      return {
        ...state,
        currentTabIndex: action.index,
      };
    default:
      return state;
  }
};
