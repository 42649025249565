const BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { Response } from '../../utils/interfaces';
import { Perfil } from '../../utils/models';
import http from '../http/http';

export class AuthService {
  getLoginURL() {
    return `${BASE_URL}/auth/login`;
  }

  getLogoutURL() {
    return `${BASE_URL}/auth/ssologout`;
  }

  getProfileUser() {
    return http.get<Response<Perfil>>('/Perfil');
  }
}
