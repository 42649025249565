import { Response } from '../../utils/interfaces/response';
import { PageResult, Periodo, PeriodoDTO } from '../../utils/models';
import http from '../http/http';

export class PeriodService {
  getAllPeriods(
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    filtro?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<PeriodoDTO[]>>>('/Periodo', {
      params: { pageSize, pageNumber, ordenar, dir, filtro, inactivos },
    });
  }

  updateStatusPeriod(id: string, status: boolean) {
    return http.post<Response<never>>(`/Periodo/${id}/Estatus`, status);
  }

  deletePeriod(id: string) {
    return http.delete<Response<never>>(`/Periodo/${id}`);
  }

  createPeriod(period: PeriodoDTO) {
    return http.post<Response<Periodo>>('/Periodo', period);
  }

  updatePeriod(period: PeriodoDTO) {
    return http.put<Response<Periodo>>('/Periodo', period);
  }

  getPeriodById(periodId: string) {
    return http.get<Response<PeriodoDTO>>(`/Periodo/${periodId}`);
  }
}
