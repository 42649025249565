import React, { FC } from 'react';

interface Props {
  contentColor: string;
  exclamationColor: string;
}

export const ScheduleFilIcon: FC<Props> = ({
  contentColor,
  exclamationColor,
}) => {
  return (
    <svg width={18} height={18} fill="" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16.5a7.258 7.258 0 0 1-2.906-.59 7.61 7.61 0 0 1-2.39-1.613 7.607 7.607 0 0 1-1.613-2.39A7.257 7.257 0 0 1 1.5 9c0-1.025.197-1.994.59-2.906a7.607 7.607 0 0 1 1.613-2.39 7.607 7.607 0 0 1 2.39-1.613A7.257 7.257 0 0 1 9 1.5c1.025 0 1.994.197 2.906.59.913.394 1.71.932 2.39 1.613a7.61 7.61 0 0 1 1.613 2.39c.394.913.591 1.882.591 2.907a7.258 7.258 0 0 1-.59 2.906 7.609 7.609 0 0 1-1.613 2.39 7.609 7.609 0 0 1-2.39 1.613A7.258 7.258 0 0 1 9 16.5Zm0-1.125c1.75 0 3.25-.625 4.5-1.875s1.875-2.75 1.875-4.5-.625-3.25-1.875-4.5S10.75 2.625 9 2.625 5.75 3.25 4.5 4.5 2.625 7.25 2.625 9s.625 3.25 1.875 4.5 2.75 1.875 4.5 1.875Z"
        fill={contentColor}
      />
      <path
        d="M10.756 12.375a.54.54 0 0 1-.394-.169L7.644 9.394a.746.746 0 0 1-.113-.188A.55.55 0 0 1 7.494 9V5.57a.546.546 0 0 1 .562-.563.546.546 0 0 1 .563.544v3.225l2.568 2.569c.125.112.185.25.179.412a.629.629 0 0 1-.197.432.573.573 0 0 1-.413.187Z"
        fill={contentColor}
      />
      <path
        d="M13.5 8.25c-.637 0-1.23.114-1.777.343a4.392 4.392 0 0 0-1.43.95 4.391 4.391 0 0 0-.95 1.43A4.564 4.564 0 0 0 9 12.75c0 .63.114 1.219.343 1.766a4.391 4.391 0 0 0 2.38 2.385 4.5 4.5 0 0 0 1.777.349 4.471 4.471 0 0 0 3.195-1.305c.405-.405.724-.881.956-1.429A4.472 4.472 0 0 0 18 12.75a4.5 4.5 0 0 0-1.305-3.206 4.392 4.392 0 0 0-1.429-.95A4.535 4.535 0 0 0 13.5 8.25Z"
        fill={contentColor}
      />
      <path
        d="M13.32 14.365c.194 0 .363.063.5.192.138.132.206.3.206.496a.716.716 0 0 1-.202.508.672.672 0 0 1-.504.215.673.673 0 0 1-.504-.215.716.716 0 0 1-.201-.508c0-.196.068-.364.206-.496a.703.703 0 0 1 .5-.192ZM13.372 9.365a.638.638 0 0 1 .653.654v3.128c0 .176-.068.33-.197.452a.647.647 0 0 1-.456.184.638.638 0 0 1-.653-.653V10c0-.176.068-.33.197-.452a.647.647 0 0 1 .456-.184Z"
        fill={exclamationColor}
        stroke={exclamationColor}
        strokeWidth={0.27}
      />
    </svg>
  );
};
