import { Deserializable } from '../interfaces';

export class Usuario implements Deserializable {
  id: string;
  nombre: string;
  nomina: string;
  activo: boolean;
  esAdmin: boolean;
  campuses: string[];

  constructor() {
    this.id = '';
    this.nombre = '';
    this.nomina = '';
    this.activo = false;
    this.esAdmin = false;
    this.campuses = [];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
