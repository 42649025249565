import {
  Dialpad, KeyboardArrowLeft,
  KeyboardArrowRight,
  Search
} from '@mui/icons-material';
import {
  Button,
  Grid,
  InputAdornment,
  MobileStepper,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { theme } from '../../../../../assets/css/theme';
import { PreviewPdf } from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import {
  CertificateRequestService,
  CertificatesService
} from '../../../../../services';
import { convertBlobToBase64 } from '../../../../../utils/helpers';
import { Constancia } from '../../../../../utils/models';
import { CertificateTableColumn } from '../../../../collaborators/Certificates/enums/CertificateTableColumn';
import { useCertificateRequest } from '../../hooks/useCertificateRequest';
import { CertificateCard } from '../CertificateCard/CertificateCard';
import { CertificateCategoryList } from '../CertificateCategoryList/CertificateCategoryList';

export const CertificateRequestPage = () => {
  const certificateService = new CertificatesService();
  const certificateRequestService = new CertificateRequestService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [activeStep, setActiveStep] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const {
    search,
    setSearch,
    reloadCertificate,
    certificateListFiltered,
    categoriesSelected,
    setCategoriesSelected,
    previewFileOptions,
    setPreviewFileOptions,
    setCategories,
  } = useCertificateRequest();

  const showTemplateByURL = async (certificateId: number) => {
    setLoading(true);
    await certificateRequestService
      .getPreviewFile(certificateId)
      .then(async (response) => {
        setLoading(false);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        await convertBlobToBase64(blob);
        setLoading(false);

        setPreviewFileOptions({
          ...previewFileOptions,
          file: url,
          open: true,
        });
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const getAllCategories = async () => {
    setCategoriesSelected([]);
    await certificateRequestService.getAllCategories().then((response) => {
      const res = response.data;
      if (res.success) {
        const data: string[] = res.data;
        setCategories(data);
      }
    });
  };

  const getAllCertificates = async (
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setLoading(true);
    await certificateService
      .getAllCertificate(filtro, pageSize, pageNumber, ordenar, dir, false)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró una constancia disponible',
              type: 'info',
            });
          }
          const data: Constancia[] = res.data.data.map((user) =>
            new Constancia().deserialize(user),
          );
          reloadCertificate(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleNext = () => {
    const step = activeStep + 1;
    setActiveStep(step);
    swiper.slideTo(step);
  };

  const handleBack = () => {
    const step = activeStep - 1;
    setActiveStep(step);
    swiper.slideTo(step);
  };

  useEffect(() => {
    if (previewFileOptions.certificateId && !previewFileOptions.file) {
      showTemplateByURL(previewFileOptions.certificateId);
    }
  }, [previewFileOptions]);

  useEffect(() => {
    getAllCertificates('', -1, 1, CertificateTableColumn.SOLICITUDES);
    getAllCategories();
  }, []);

  useEffect(() => {
    if (certificateListFiltered.length === 0) {
      setNotify({
        ...notify,
        open: true,
        message: 'No se encontró una constancia disponible, intente de nuevo',
        type: 'info',
      });
    } else {
      setNotify({
        ...notify,
        open: false,
        message: '',
        type: 'info',
      });
    }
  }, [certificateListFiltered]);
  return (
    <>
      {previewFileOptions.open && (
        <PreviewPdf
          file={previewFileOptions.file}
          open={previewFileOptions.open}
          handleClose={() =>
            setPreviewFileOptions({
              file: null,
              open: false,
              certificateId: null,
            })
          }
        />
      )}
      <Grid container>
        <Grid item md={12}>
          <Typography
            variant="h1"
            color="secondary"
            sx={{ fontSize: { xs: '20px', lg: '32px' } }}>
            Solicitud de constancia aquí
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} sx={{ py: 2 }}>
          <p>
            <Typography
              variant="subtitle2"
              component="span"
              color="grayCustom.main">
                A continuación te presentamos los tipos de constancias disponibles en el Catálogo que ofrece el departamento de 
              {'  '}
              <Typography
                variant="subtitle2"
                component="span"
                color="primary"
                display={'inline'}>
                Servicios Escolares Tecmilenio.
              </Typography>
            </Typography>
          </p>
          <p>
            <Typography
              variant="subtitle2"
              component="span"
              color="grayCustom.main">
                
                <b>Importante:</b>
                {'  '}
                <Typography
                variant="subtitle2"
                component="span"
                color="prigrayCustom.mainmary"
                display={'inline'}><i> Las constancias que podrás solicitar en este portal contienen la información de tu plan de estudios vigente (que podrás confirmar en la vista previa); si existiera alguna solicitud específica favor de contactar a Servicios Escolares de tu campus.</i></Typography>
            </Typography>
          </p>
        </Grid>
        <Grid item xs={0} md={4}></Grid>
        <Grid item xs={12} md={4} sx={{ py: 2 }}>
          <TextField
            name="searchCertificate"
            label="Encuentra una constancia"
            variant="outlined"
            type="text"
            size={'small'}
            fullWidth
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item md={6} py={2}>
          <CertificateCategoryList />
        </Grid>
        <Grid item md={12} py={2}>
          {!search && categoriesSelected.length === 0 && (
            <Typography
              variant="h6"
              component="span"
              color="primary.dark"
              pr={1}
              display={'inline'}
              sx={{ fontWeight: 600 }}>
              Constancias más solicitadas
            </Typography>
          )}
          {search && categoriesSelected.length === 0 && (
            <Typography
              variant="h6"
              component="span"
              color="primary.dark"
              pr={1}
              sx={{
                fontWeight: 600,
                display: { xs: 'block', md: 'inline' },
                my: { xs: 2, md: 0 },
              }}>
              Constancias con la búsqueda “{search}”
            </Typography>
          )}
          {!search && categoriesSelected.length > 0 && (
            <Typography
              variant="h6"
              component="span"
              color="primary.dark"
              pr={1}
              sx={{
                fontWeight: 600,
                display: { xs: 'block', md: 'inline' },
                my: { xs: 2, md: 0 },
              }}>
              Constancias con la categoría “{categoriesSelected.join('')}”
            </Typography>
          )}
          {search && categoriesSelected.length > 0 && (
            <Typography
              variant="h6"
              component="span"
              color="primary.dark"
              pr={1}
              sx={{
                fontWeight: 600,
                display: { xs: 'block', md: 'inline' },
                my: { xs: 2, md: 0 },
              }}>
              Constancias con la categoría “{categoriesSelected.join('')}” y
              búsqueda “{search}”
            </Typography>
          )}
          <Typography
            variant="caption"
            component="span"
            color="gray"
            px={1}
            sx={{
              borderLeft: '1px solid',
              fontWeight: 600,
              display: { xs: 'block', md: 'inline' },
              my: { xs: 2, md: 0 },
            }}>
            {certificateListFiltered.length} Resultados
          </Typography>
        </Grid>
      </Grid>
      {!search && categoriesSelected.length === 0 && (
        <>
          <Grid
            container
            justifyContent="center"
            sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={12} py={2} pr={2}>
              <Swiper
                onSwiper={setSwiper}
                modules={[EffectCoverflow, Pagination]}
                effect="coverflow"
                initialSlide={activeStep}
                pagination={true}
                onSlideChange={(event) => {
                  setActiveStep(event.activeIndex);
                }}
                tabIndex={activeStep}>
                {certificateListFiltered.map((certificate) => (
                  <SwiperSlide key={certificate.id}>
                    <Grid item xs={12} py={2}>
                      <CertificateCard certificate={certificate} />
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
            <MobileStepper
              variant="dots"
              steps={
                certificateListFiltered.length >= 5
                  ? 5
                  : certificateListFiltered.length
              }
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  sx={{ ml: 2 }}
                  disabled={activeStep === certificateListFiltered.length - 1}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  sx={{ mr: 2 }}
                  disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </Grid>
        </>
      )}

      <Grid
        container
        alignItems="strech"
        direction="row"
        sx={{
          display: {
            xs: search || categoriesSelected.length > 0 ? 'flex' : ' none',
            md: 'flex',
          },
        }}>
        {certificateListFiltered.map((certificate) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            py={2}
            pr={2}
            key={certificate.id}
            sx={{ maxWidth: { xs: 'auto', md: '290px' } }}>
            <CertificateCard certificate={certificate} />
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" justifyContent="center" py={2}>
        <Dialpad sx={{ color: '#3CE14C' }} />
        <Typography variant="subtitle2" color="grayCustom.main" sx={{ pl: 2 }}>
          No hay más elementos que mostrar...
        </Typography>
      </Grid>
    </>
  );
};
