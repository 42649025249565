import { Close, Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useUsers } from '../../hook/useUsers';

interface Props {
  onSearch: (search: string) => void;
}

export const UserSearch: FC<Props> = ({ onSearch }) => {
  const { search, setSearch } = useUsers();

  const handleSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    onSearch(value);
  };

  const filterCleaning = () => {
    if (search === '') {
      return;
    }
    setSearch('');
    onSearch('');
  };
  return (
    <Grid item xs={12} md={9}>
      <TextField
        label="Buscar"
        variant="outlined"
        fullWidth
        size={'small'}
        value={search}
        onChange={handleSearchChange}
        sx={{ py: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Close onClick={filterCleaning} sx={{ cursor: 'pointer' }} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
