import React, { FC } from 'react';
import InReviewImg from '../../../../../assets/icons/InReviewImg.svg';
import PaymentPendientImg from '../../../../../assets/icons/PaymentPendientImg.svg';
import RejectedImg from '../../../../../assets/icons/RejectedImg.svg';
import ValidImg from '../../../../../assets/icons/ValidImg.svg';
import ExpiredImg from '../../../../../assets/icons/ExpiredImg.svg';
import HistoricalImg from '../../../../../assets/icons/HistoricalImg.svg';
import { CertificateStatus } from '../../../../../utils/enums';
interface Props {
  statusId: number;
}

export const FollowUpCertificateTableImageStatus: FC<Props> = ({
  statusId,
}) => {
  switch (statusId) {
    case CertificateStatus.IN_REVIEW:
      return (
        <div>
          <img src={InReviewImg} alt="icon" />
        </div>
      );
    case CertificateStatus.PENDING_PAYMENT:
      return (
        <div>
          <img src={PaymentPendientImg} alt="icon" />
        </div>
      );
    case CertificateStatus.REJECTED:
      return (
        <div>
          <img src={RejectedImg} alt="icon" />
        </div>
      );
    case CertificateStatus.VALID:
      return (
        <div>
          <img src={ValidImg} alt="icon" />
        </div>
      );
    case CertificateStatus.EXPIRED:
      return (
        <div>
          <img src={ExpiredImg} alt="icon" />
        </div>
      );
    case CertificateStatus.HISTORICAL:
      return (
        <div>
          <img src={HistoricalImg} alt="icon" />
        </div>
      );
    default:
      return <p>No disponible</p>;
  }
};
