import { CircularProgress } from '@mui/material';
import './Spinner.css';

export const Spinner = () => {
  return (
    <div className={'spinner'}>
      <CircularProgress />
    </div>
  );
};
