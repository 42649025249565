import { Deserializable } from '../interfaces';

export class Director implements Deserializable {
  campus: string;
  nivel: string;
  nombreDirector: string;
  firmaDirector: string;
  activo: boolean;

  constructor() {
    this.campus = '';
    this.nivel = '';
    this.nombreDirector = '';
    this.firmaDirector = '';
    this.activo = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
