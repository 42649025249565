import { FC } from 'react';
import {
  Expired,
  Historical,
  InReview,
  NotAccepted,
  PendingPayment,
  Valid,
} from '../../../../../components';
import { CertificateStatus } from '../../../../../utils/enums';

interface Props {
  statusId: number;
}

export const FollowUpCertificateTableStatus: FC<Props> = ({ statusId }) => {
  switch (statusId) {
    case CertificateStatus.IN_REVIEW:
      return <InReview />;
    case CertificateStatus.PENDING_PAYMENT:
      return <PendingPayment />;
    case CertificateStatus.REJECTED:
      return <NotAccepted />;
    case CertificateStatus.VALID:
      return <Valid />;
    case CertificateStatus.EXPIRED:
      return <Expired />;
    case CertificateStatus.HISTORICAL:
      return <Historical />;
    default:
      return <p>No disponible</p>;
  }
};
