import { Grid } from '@mui/material';
import { useCertificateRequest } from '../../hooks/useCertificateRequest';
import { CertificateCategoryItem } from '../CertificateCategoryItem/CertificateCategoryItem';

export const CertificateCategoryList = () => {
  const { categories } = useCertificateRequest();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
      {categories.map((category) => (
        <CertificateCategoryItem key={category} category={category} />
      ))}
    </Grid>
  );
};
