import { createContext } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { Constancia, PageResult } from '../../../../utils/models';
import { CertificateTableColumn } from '../enums/CertificateTableColumn';
import {
  CertificateState,
  ModalRecordCertificate,
} from '../interfaces/interfaces';

export type CertificateContextProps = {
  certificateState: CertificateState;
  setCertificateList: (certificateList: Constancia[]) => void;
  setPage: (page: number) => void;
  setStatus: (status: boolean, id: number) => void;
  setPaginatorOptions: (paginatorOption: PageResult<Constancia[]>) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setModalRecord: (modalRecord: ModalRecordCertificate) => void;
  setSearch: (search: string) => void;
  setOrder: (orderBy: CertificateTableColumn, dir: DirectionOptions) => void;
  setConfirmDialog: (confirmDialog: ConfirmDialogOptions) => void;
};

export const CertificateContext = createContext<CertificateContextProps>(
  {} as CertificateContextProps,
);
