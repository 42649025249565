import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { FC } from 'react';
import { CancelFillIcon, CreditScoreIcon } from '../../../../../assets/icons';
import certificateIcon from '../../../../../assets/icons/certificateIcon.svg';
import { ScheduleFilIcon } from '../../../../../assets/icons/ScheduleFilIIcon';
import { ToggleOnFillIcon } from '../../../../../assets/icons/ToggleOnFillIcon';
import { CertificateStatus } from '../../../../../utils/enums';
import { DateHelper } from '../../../../../utils/helpers';
import { SolicitudAlumno } from '../../../../../utils/models';
import { StatusCard } from '../StatusCard/StatusCard';
interface Props {
  certificate: SolicitudAlumno;
  download: () => void;
  pay: () => void;
  reject: () => void;
  requestAgain: () => void;
}
export const DashboardCertificateCard: FC<Props> = ({
  certificate,
  download,
  pay,
  reject,
  requestAgain,
}) => {
  const theme = useTheme();

  const handleDownload = () => {
    download();
  };
  const handlePay = () => {
    pay();
  };
  const handleRequestAgain = () => {
    requestAgain();
  };

  const handleRejected = () => {
    reject();
  };
  return (
    <>
      <Card sx={{ width: '100%', height: '100%' }}>
        <Grid container sx={{ bgcolor: 'primary.darker' }}>
          <Grid item xs={10}>
            <StatusCard certificate={certificate} />
            <Typography
              variant="subtitle1"
              px={1}
              py={1}
              my={2}
              color="white"
              sx={{
                fontWeight: 600,
                lineHeight: '17px',
                fontStyle: 'normal',
                minHeight: '30px',
                height: '43px',
                maxHeight: '43px',
                width: '90%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}>
              {certificate.nombre}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            px={1}
            py={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img
              src={certificateIcon}
              style={{ height: 70, width: 70 }}
              alt="icon"
            />
          </Grid>
        </Grid>
        <CardContent sx={{ pb: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ minHeight: '20px' }}>
            {certificate.estatusId === CertificateStatus.VALID && (
              <>
                <Typography
                  variant="caption"
                  color="grayCustom.main"
                  pr={1}
                  sx={{ fontWeight: 400 }}>
                  Vence el {DateHelper.convertDateString(certificate.vigencia)}
                </Typography>
                <ToggleOnFillIcon
                  bgColorBody={theme.palette.grayCustom.main}
                  dotColor={'white'}
                />
              </>
            )}
            {certificate.estatusId === CertificateStatus.EXPIRED && (
              <>
                <Typography
                  variant="caption"
                  color="grayCustom.main"
                  pr={1}
                  sx={{ fontWeight: 400 }}>
                  Venció el {DateHelper.convertDateString(certificate.vigencia)}
                </Typography>
                <ScheduleFilIcon
                  contentColor={theme.palette.grayCustom.main}
                  exclamationColor="white"
                />
              </>
            )}
            {certificate.estatusId === CertificateStatus.REJECTED && (
              <>
                <Typography
                  variant="caption"
                  color="grayCustom.main"
                  pr={1}
                  sx={{ fontWeight: 400 }}>
                  No aceptada
                </Typography>
                <CancelFillIcon fill={theme.palette.grayCustom.main} />
              </>
            )}
            {certificate.estatusId === CertificateStatus.PENDING_PAYMENT && (
              <>
                <Typography
                  variant="caption"
                  color="grayCustom.main"
                  pr={1}
                  sx={{ fontWeight: 400 }}>
                  Aceptada
                </Typography>
                <CreditScoreIcon fill={theme.palette.grayCustom.main} />
              </>
            )}
          </Stack>

          <Typography
            component="p"
            variant="body2"
            color="gray"
            paragraph
            pr={1}
            py={1}
            sx={{
              lineHeight: '15px',
              height: '70px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
            }}>
            {certificate.descripcion}
          </Typography>
        </CardContent>
        <CardActions>
          {certificate.estatusId === CertificateStatus.VALID && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDownload}>
              Descargar
            </Button>
          )}
          {certificate.estatusId === CertificateStatus.PENDING_PAYMENT && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handlePay}>
              Pagar
            </Button>
          )}

          {(certificate.estatusId === CertificateStatus.EXPIRED ||
            certificate.estatusId === CertificateStatus.HISTORICAL) && (
            <Button
              variant="contained"
              fullWidth
              sx={{ bgcolor: 'primary.darker' }}
              onClick={handleRequestAgain}>
              Solicitar de nuevo
            </Button>
          )}
          {certificate.estatusId === CertificateStatus.REJECTED && (
            <Button
              variant="contained"
              fullWidth
              disabled
              sx={{ bgcolor: 'primary.darker' }}
              onClick={handleRejected}>
              Ver mensaje
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};
