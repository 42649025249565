import { createContext } from 'react';
import { Constancia, SolicitudDetalle } from '../../../../utils/models';
import {
  DownloadTab,
  PayTab,
  PreviewFileRequestProcess,
  RequestProcessState,
  RequestProgressBar,
  VerifyDataTab,
} from '../interfaces/interfaces';

export type RequestProcessContextProps = {
  requestProcessState: RequestProcessState;
  setCertificate: (certificate: SolicitudDetalle) => void;
  setVerifyDataTab: (verifyDataTab: VerifyDataTab) => void;
  setPayTab: (payTab: PayTab) => void;
  setDownloadTab: (downloadTab: DownloadTab) => void;
  setPreviewData: (previewOptions: PreviewFileRequestProcess) => void;
  setProgressBar: (progressBar: RequestProgressBar) => void;
  setCurrentTabIndex: (index: number) => void;
};

export const RequestProcessContext = createContext<RequestProcessContextProps>(
  {} as RequestProcessContextProps,
);
