import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import FileSaver from 'file-saver';
import { ChangeEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmDialogOptions,
  EmptyTable,
  FilterTableMenu,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import {
  FollowUpCertificatesService,
  RequestProcessService,
} from '../../../../../services';
import { TablePaginatorOptions } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { DateHelper, getFileName } from '../../../../../utils/helpers';
import { SolicitudSeguimiento } from '../../../../../utils/models';
import { FollowUpCertificateModalOption } from '../../enums/FollowUpCertificateModalOption';
import { FollowUpCertificateTableColumn } from '../../enums/FollowUpCertificateTableColumn';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
import { FollowUpCertificateTableActions } from '../FollowUpCertificateTableActions/FollowUpCertificateTableActions';
import { FollowUpCertificateTableImageStatus } from '../FollowUpCertificateTableImageStatus/FollowUpCertificateTableImageStatus';
import { FollowUpCertificateTableStatus } from '../FollowUpCertificateTableStatus/FollowUpCertificateTableStatus';
import './FollowUpCertificatesTable.css';

interface Props {
  onReloadData: () => void;
  onTableFilter: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => void;
}

export const FollowUpCertificatesTable: FC<Props> = ({
  onReloadData,
  onTableFilter,
}) => {
  const followUpCertificateService = new FollowUpCertificatesService();
  const requestProcessService = new RequestProcessService();
  const theme = useTheme();
  const palette = theme.palette;
  const navigate = useNavigate();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    followUpCertificateList,
    paginatorOptions,
    modal,
    setModal,
    filters,
    setFilters,
  } = useFollowUpCertificates();

  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogOptions>({
    open: false,
    title: '',
    subTitle: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => null,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    const value = newPage + 1;
    setFilters({ ...filters, page: value });
    onTableFilter(filters.rowsPerPage, value, filters.orderBy, filters.dir);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    const valuePage = 1;
    setFilters({ ...filters, rowsPerPage: value, page: valuePage });
    onTableFilter(value, valuePage, filters.orderBy, filters.dir);
  };

  const onOrderUpward = (columnName: FollowUpCertificateTableColumn) => {
    setFilters({ ...filters, orderBy: columnName, dir: DirectionOptions.ASC });
    onTableFilter(
      filters.rowsPerPage,
      filters.page,
      columnName,
      DirectionOptions.ASC,
    );
  };

  const onOrderFalling = (columnName: FollowUpCertificateTableColumn) => {
    setFilters({ ...filters, orderBy: columnName, dir: DirectionOptions.DESC });
    onTableFilter(
      filters.rowsPerPage,
      filters.page,
      columnName,
      DirectionOptions.DESC,
    );
  };

  const onDownload = async (certificate: SolicitudSeguimiento) => {
    setLoading(true);
    await requestProcessService
      .getURLToDownloadCertificate(certificate.id)
      .then(async (r) => {
        const res = r.data;
        setLoading(false);
        if (res.success) {
          axios
            .get(res.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream',
              });
              const fileName = getFileName(certificate.nombre);
              const file = new File([blob], fileName, {
                type: 'application/pdf',
              });
              FileSaver.saveAs(file, fileName);
            })
            .catch(() => {
              setNotify({
                ...notify,
                open: true,
                message: 'Error al obtener el archivo',
                type: 'error',
              });
            });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const onExpired = (certificate: SolicitudSeguimiento) => {
    setModal({
      ...modal,
      type: FollowUpCertificateModalOption.UPDATE_VALIDITY,
      show: true,
      certificateEdit: certificate,
    });
  };

  const onReactive = (certificate: SolicitudSeguimiento) => {
    setModal({
      ...modal,
      type: FollowUpCertificateModalOption.UPDATE_VALIDITY,
      show: true,
      certificateEdit: certificate,
    });
  };

  const onShowDetails = (certificate: SolicitudSeguimiento) => {
    navigate(`${certificate.id}/detalle`, {
      state: { certificate: certificate },
    });
  };

  const approved = (certificate: SolicitudSeguimiento) => {
    setLoading(true);
    followUpCertificateService
      .approveCertificate(certificate.id)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia aprobada correctamente',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const rejected = (certificate: SolicitudSeguimiento) => {
    setModal({
      ...modal,
      type: FollowUpCertificateModalOption.REJECT,
      show: true,
      certificateEdit: certificate,
    });
  };

  const verifyPayment = (certificate: SolicitudSeguimiento) => {
    setLoading(true);
    followUpCertificateService
      .verifyPayment(certificate.id)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Pago verificado correctamente',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const generateCertificate = (certificate: SolicitudSeguimiento) => {
    setLoading(true);
    followUpCertificateService
      .generateCertificate(certificate.id)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia generada correctamente',
            type: 'success',
          });
          onReloadData();
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  return (
    <>
      <Paper elevation={0} sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table__cell" variant="head">
                  Constancia{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.NOMBRE)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.NOMBRE)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Folio de emisión{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.FOLIO)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.FOLIO)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Matrícula{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.MATRICULA)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.MATRICULA)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Alumno{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.ALUMNO)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.ALUMNO)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Campus{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.CAMPUS)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.CAMPUS)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Fecha de solicitud{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(
                        FollowUpCertificateTableColumn.FECHA_CREACION,
                      )
                    }
                    onOrderFalling={() =>
                      onOrderFalling(
                        FollowUpCertificateTableColumn.FECHA_CREACION,
                      )
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Fecha de emisión{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.EMISION)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.EMISION)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Status{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.ESTATUS)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(FollowUpCertificateTableColumn.ESTATUS)
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Aprobado por{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(FollowUpCertificateTableColumn.APROBADO_POR)
                    }
                    onOrderFalling={() =>
                      onOrderFalling(
                        FollowUpCertificateTableColumn.APROBADO_POR,
                      )
                    }
                  />
                </TableCell>
                <TableCell
                  className="table__cell"
                  variant="head"
                  sx={{ minWidth: '250px' }}>
                  Fecha de aprobación{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(
                        FollowUpCertificateTableColumn.FECHA_APROBACION,
                      )
                    }
                    onOrderFalling={() =>
                      onOrderFalling(
                        FollowUpCertificateTableColumn.FECHA_APROBACION,
                      )
                    }
                  />
                </TableCell>
                <TableCell className="table__cell" variant="head">
                  Verificado por{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(
                        FollowUpCertificateTableColumn.VERIFICADO_POR,
                      )
                    }
                    onOrderFalling={() =>
                      onOrderFalling(
                        FollowUpCertificateTableColumn.VERIFICADO_POR,
                      )
                    }
                  />
                </TableCell>
                <TableCell
                  className="table__cell"
                  variant="head"
                  sx={{ minWidth: '250px' }}>
                  Fecha de pago verificado{' '}
                  <FilterTableMenu
                    onOrderUpward={() =>
                      onOrderUpward(
                        FollowUpCertificateTableColumn.FECHA_VERIFICAD_POR,
                      )
                    }
                    onOrderFalling={() =>
                      onOrderFalling(
                        FollowUpCertificateTableColumn.FECHA_VERIFICAD_POR,
                      )
                    }
                  />
                </TableCell>
                <TableCell variant="head" align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {followUpCertificateList.map((row: SolicitudSeguimiento) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    <Stack direction="row" alignItems="center">
                      <FollowUpCertificateTableImageStatus
                        statusId={row.estatusId}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          lineHeight: '15px',
                          minHeight: '30px',
                          maxHeight: '35px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}>
                        {row.nombre}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.folio}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.matricula}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.alumno}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.campus}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {DateHelper.convertDateString(row.fechaCreacion)}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {DateHelper.convertDateString(row.fechaEmision)}
                  </TableCell>
                  <TableCell
                    className="table__cell"
                    sx={{ p: 1, minWidth: '230px' }}>
                    <FollowUpCertificateTableStatus statusId={row.estatusId} />
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.aprobadoPor ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    className="table__cell"
                    sx={{ p: 1, minWidth: '230px' }}>
                    {DateHelper.convertDateString(row.fechaAprobacion) ?? 'N/A'}
                  </TableCell>
                  <TableCell className="table__cell" sx={{ p: 1 }}>
                    {row.verificadoPor ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    className="table__cell"
                    sx={{ p: 1, minWidth: '230px' }}>
                    {DateHelper.convertDateString(row.fechaPagoVerificado) ??
                      'N/A'}
                  </TableCell>
                  <TableCell sx={{ p: 1 }} align="center">
                    <FollowUpCertificateTableActions
                      certificate={row}
                      onDownload={() => onDownload(row)}
                      onExpired={() => onExpired(row)}
                      onReactive={() => onReactive(row)}
                      onShowDetails={() => onShowDetails(row)}
                      approved={() => approved(row)}
                      rejected={() => rejected(row)}
                      verifyPayment={() => verifyPayment(row)}
                      generateCertificate={() => generateCertificate(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable elements={[{}]} /> {/* // Todo add elements */}
        <Divider />
        <TablePagination
          rowsPerPageOptions={TablePaginatorOptions.rowsPerPageOptions}
          component="div"
          count={paginatorOptions.totalCount}
          page={filters.page - 1}
          rowsPerPage={filters.rowsPerPage}
          SelectProps={{
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
