export enum CampusIPID {
  LT = 1, // Las Torres
  GP = 2, // Guadalupe
  SN = 3, // San Nicolás
  CL = 4, // Culiacán
  QR = 5, // Querétaro
  GU = 6, // Guadalajara
  LP = 7, // San Luis Potosí
  ZP = 8, // Zapopan
  MA = 9, // Mazatlán
  HO = 10, // Hermosillo
  OB = 11, // Ciudad Obregón
  MO = 12, // Los Mochis
  TL = 13, // Toluca
  FE = 14, // Ferrería
  VZ = 15, // Veracruz
  CI = 16, // Cuautitlán Izcalli
  CN = 17, // Cancún
  PU = 18, // Puebla
  VH = 19, // Villahermosa
  ME = 20, // Mérida
  CR = 21, // Cuernavaca
  JU = 22, // Ciudad Juárez
  CH = 23, // Chihuahua
  LG = 24, // Laguna
  CU = 25, // Cumbres
  DG = 26, // Durango
  LR = 27, // Nuevo Laredo
  RY = 28, // Reynosa
  AO = 29, // Álvaro Obregón
  LA = 30, // Las Américas
  RM = 31, // Reforma
  TJ = 32, // Tijuana
  SA = 33, // Saltillo
  ZC = 34, // Zacatecas
  MK = 35, // Mitikah
  AP = 36, // Artz
  // Montes Urales	 =37,
  // Santa Fe	 =38,
  // Atirantado	 =39,
  // Connect Ferrería	 =41,
}
