import { FC } from 'react';

interface Props {
  bgColorBody: string;
  dotColor: string;
}

export const ToggleOnFillIcon: FC<Props> = ({ bgColorBody, dotColor }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 13.5C4 13.5 2.9375 13.0625 2.0625 12.1875C1.1875 11.3125 0.75 10.25 0.75 9C0.75 7.75 1.1875 6.6875 2.0625 5.8125C2.9375 4.9375 4 4.5 5.25 4.5H12.75C14 4.5 15.0625 4.9375 15.9375 5.8125C16.8125 6.6875 17.25 7.75 17.25 9C17.25 10.25 16.8125 11.3125 15.9375 12.1875C15.0625 13.0625 14 13.5 12.75 13.5H5.25ZM12.7687 10.8937C13.2937 10.8937 13.7406 10.7094 14.1094 10.3406C14.4781 9.97188 14.6625 9.525 14.6625 9C14.6625 8.475 14.4781 8.02812 14.1094 7.65937C13.7406 7.29062 13.2937 7.10625 12.7687 7.10625C12.2437 7.10625 11.7969 7.29062 11.4281 7.65937C11.0594 8.02812 10.875 8.475 10.875 9C10.875 9.525 11.0594 9.97188 11.4281 10.3406C11.7969 10.7094 12.2437 10.8937 12.7687 10.8937Z"
        fill={bgColorBody}
      />
      <rect x="10" y="6" width="6" height="6" rx="3" fill={dotColor} />
    </svg>
  );
};
