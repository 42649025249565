import { createContext } from 'react';
import { Constancia } from '../../../../utils/models';
import { CertificateRequestState, PreviewFile } from '../interfaces/interfaces';

export type CertificateRequestContextProps = {
  certificateState: CertificateRequestState;
  reloadCertificate: (certificateList: Constancia[]) => void;
  setSearch: (search: string) => void;
  setCategoriesSelected: (cateogires: string[]) => void;
  setCategories: (cateogires: string[]) => void;
  setCertifcateSelected: (certificate: Constancia) => void;
  setPreviewFileOptions: (previewOptions: PreviewFile) => void;
};

export const CertificateRequestContext =
  createContext<CertificateRequestContextProps>(
    {} as CertificateRequestContextProps,
  );
