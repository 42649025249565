import { Search } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLoading } from '../../../../../hooks';
import { CampusService } from '../../../../../services';
import { DirectionOptions } from '../../../../../utils/enums';
import { Campus } from '../../../../../utils/models';
import { CampusTableColumn } from '../../../Campus/enums/CampusTableColumn';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
import './FollowUpCertificatesSearch.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FollowUpCertificatesSearch = () => {
  const campusService = new CampusService();
  const [campusList, setCampusList] = useState<Campus[]>([]);
  const { filters, setFilters } = useFollowUpCertificates();
  const { setLoading } = useLoading();

  const formik = useFormik({
    initialValues: { campuses: [], folio: '', enrollment: '' },
    onSubmit: () => {
      return;
    },
  });

  const getAllCampus = async () => {
    setLoading(true);
    await campusService
      .getAllCampus(
        '',
        -1,
        -1,
        CampusTableColumn.NOMBRE,
        DirectionOptions.ASC,
        false,
      )
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Campus[] = res.data.data.map((campus) =>
            new Campus().deserialize(campus),
          );
          setCampusList(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handlSelectAllCampus = () => {
    let campusListSelected: string[] = formik.values.campuses;

    if (campusListSelected.includes('0')) {
      campusListSelected = [];
      setFilters({ ...filters, campus: campusListSelected });
    } else {
      campusListSelected = campusList.map((item) => item.id);
      setFilters({ ...filters, campus: campusListSelected });
      campusListSelected.push('0');
    }
    formik.setValues({ ...formik.values, campuses: campusListSelected });
  };

  const handleSelectCampus = (campusId: string) => {
    let campusListSelected: string[] = formik.values.campuses;
    if (campusListSelected.includes(campusId)) {
      campusListSelected = campusListSelected.filter(
        (item) => item !== campusId,
      );
    } else {
      campusListSelected.push(campusId);
    }

    if (
      campusListSelected.includes('0') &&
      campusListSelected.length === campusList.length
    ) {
      campusListSelected = campusListSelected.filter((item) => item !== '0');
      setFilters({ ...filters, campus: campusListSelected });
    } else if (
      !campusListSelected.includes('0') &&
      campusListSelected.length === campusList.length
    ) {
      setFilters({ ...filters, campus: campusListSelected });
      campusListSelected.push('0');
    } else {
      campusListSelected = campusListSelected.filter((item) => item !== '0');
      setFilters({ ...filters, campus: campusListSelected });
    }
    formik.setValues({ ...formik.values, campuses: campusListSelected });
  };

  useEffect(() => {
    getAllCampus();
  }, []);

  useEffect(() => {
    const campuses: string[] = formik.values.campuses.filter(
      (item: string) => item !== '0',
    );

    setFilters({
      ...filters,
      campus: campuses,
      folio: formik.values.folio,
      enrollment: formik.values.enrollment,
    });
  }, [formik.values.folio, formik.values.enrollment]);
  return (
    <Grid container>
      <Grid item xs={12} md={4} pt={2} pr={2}>
        <FormControl fullWidth variant="outlined" size={'small'} sx={{ py: 1 }}>
          <InputLabel sx={{ pt: 2 }}>Campus</InputLabel>
          <Select
            name="campuses"
            multiple
            autoWidth
            value={formik.values.campuses}
            className="filter__select"
            label={
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontWeight: 500, display: 'inline' }}>
                Campus
              </Typography>
            }
            MenuProps={MenuProps}
            aria-describedby="campusHelperText">
            <MenuItem value={'0'} onClick={handlSelectAllCampus}>
              Todos los campus
            </MenuItem>
            {campusList.map((campus) => (
              <MenuItem
                key={campus.id}
                value={campus.id}
                onClick={() => handleSelectCampus(campus.id)}>
                {campus.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} pt={2} pr={2}>
        <TextField
          placeholder="Buscar por ”Matrícula”..."
          name="enrollment"
          variant="outlined"
          fullWidth
          sx={{
            py: 1,
            '& input': {
              paddingY: '14px',
            },
          }}
          size={'small'}
          value={formik.values.enrollment}
          onChange={formik.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} pt={2} pr={2}>
        <TextField
          placeholder="Buscar por ”Folio de emisión”..."
          name="folio"
          variant="outlined"
          value={formik.values.folio}
          onChange={formik.handleChange}
          fullWidth
          size={'small'}
          sx={{
            py: 1,
            '& input': {
              paddingY: '14px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
