import React from 'react';

export const RejectIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.584 18.333a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V2.917a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h7c.167 0 .33.034.49.104.16.07.295.16.406.27l3.812 3.813c.111.111.202.247.271.406.07.16.104.323.104.49v10.333a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H4.584ZM11.48 6.167v-3.25H4.584v14.166h10.833V6.792h-3.312a.607.607 0 0 1-.625-.625Z"
        fill="#232D38"
      />
      <path
        d="m8.063 14.688 1.895-1.876 1.875 1.876a.6.6 0 0 0 .438.187.6.6 0 0 0 .437-.188.6.6 0 0 0 .188-.437.6.6 0 0 0-.188-.438l-1.875-1.874 1.875-1.876a.6.6 0 0 0 .188-.437.6.6 0 0 0-.188-.438.6.6 0 0 0-.437-.187.6.6 0 0 0-.438.188l-1.875 1.874-1.896-1.874a.683.683 0 0 0-.437-.178.564.564 0 0 0-.438.178.6.6 0 0 0-.187.437.6.6 0 0 0 .188.438l1.895 1.874-1.896 1.876A.6.6 0 0 0 7 14.25a.6.6 0 0 0 .188.438c.125.124.27.183.437.177a.683.683 0 0 0 .438-.178Z"
        fill="#232D38"
      />
    </svg>
  );
};
