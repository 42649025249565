import { DirectionOptions } from '../../../../utils/enums';
import { Campus, PageResult } from '../../../../utils/models';
import { CampusTableColumn } from '../enums/CampusTableColumn';
import { CampusState, ModalRecordCampus } from '../interfaces/interfaces';

type CampusAction =
  | { type: 'reloadCampus'; campusList: Campus[] }
  | { type: 'changePage'; page: number }
  | { type: 'changeStatus'; status: boolean; campus: Campus }
  | {
      type: 'changePaginatorOptions';
      paginatorOptions: PageResult<Campus[]>;
    }
  | {
      type: 'changeRowsPerPage';
      rowsPerPage: any;
    }
  | {
      type: 'changeSearch';
      search: string;
    }
  | {
      type: 'changeModalRecord';
      modalRecord: ModalRecordCampus;
    }
  | {
      type: 'setOrder';
      orderBy: CampusTableColumn;
      dir: DirectionOptions;
    };

export const campusReducer = (
  state: CampusState,
  action: CampusAction,
): CampusState => {
  switch (action.type) {
    case 'reloadCampus':
      return {
        ...state,
        campusList: action.campusList,
      };
    case 'changePage':
      return {
        ...state,
        page: action.page,
      };
    case 'changeStatus':
      return {
        ...state,
        campusList: state.campusList.map(({ ...item }) => {
          if (item.id === action.campus.id) {
            item.activo = action.status;
          }
          return new Campus().deserialize(item);
        }),
      };
    case 'changePaginatorOptions':
      return {
        ...state,
        paginatorOptions: action.paginatorOptions,
      };
    case 'changeRowsPerPage':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };
    case 'changeModalRecord':
      return {
        ...state,
        modalRecord: action.modalRecord,
      };
    case 'changeSearch':
      return {
        ...state,
        search: action.search,
      };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    default:
      return state;
  }
};
