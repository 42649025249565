import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// interface LocationState {}

export const ValidatePay = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return <div>ValidatePay</div>;
};
