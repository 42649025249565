import { Deserializable } from '../interfaces';

export class FechaSolicitud implements Deserializable {
  vigencia: string;
  expiracion: string;

  constructor() {
    this.vigencia = null;
    this.expiracion = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
