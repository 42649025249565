import { useEffect } from 'react';
import { AuthService } from '../../../services';

export const AuthLogin = () => {
  const authService = new AuthService();

  useEffect(() => {
    window.location.replace(authService.getLoginURL());
  }, []);
  return <div></div>;
};
