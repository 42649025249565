import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { ModalBar } from '../ModalBar/ModalBar';

export type ConfirmDialogOptions = {
  open: boolean;
  title: string;
  subTitle?: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
};

export type ConfirmDialogProps = {
  confirmDialog: ConfirmDialogOptions;
  setConfirmDialog: (options: ConfirmDialogOptions) => void;
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  confirmDialog,
  setConfirmDialog,
}) => {
  const handleOnClose = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          borderRadius: '16px',
        },
      }}
      maxWidth="md"
      open={confirmDialog.open}>
      <DialogTitle sx={{ m: 0 }}>
        <Typography
          variant="h6"
          component="span"
          sx={{ color: 'white', fontWeight: 600 }}>
          {confirmDialog.title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ m: 0 }}>
        <ModalBar
          startIcon={confirmDialog.startIcon}
          endIcon={confirmDialog.endIcon}
          title={confirmDialog.subTitle}
        />
      </DialogContent>
      <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
        <Button
          variant="outlined"
          autoFocus
          sx={{
            textTransform: 'uppercase',
            width: { xs: '100%', md: 'auto' },
            py: 1,
            m: 1,
          }}
          onClick={handleOnClose}>
          {confirmDialog.cancelText}
        </Button>
        <Button
          variant="contained"
          sx={{
            textTransform: 'uppercase',
            width: { xs: '100%', md: 'auto' },
            py: 1,
            m: 1,
          }}
          onClick={confirmDialog.onConfirm}>
          {confirmDialog.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
