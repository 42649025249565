import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, SolicitudAlumno } from '../../../../utils/models';
import { CertificateHistoryTableColumn } from '../components/enums/CertificateHistoryTableColumn';
import { CertificateHistoryState } from '../interfaces/interfaces';

type CertificateAction =
  | { type: 'setCertificateList'; certificateList: SolicitudAlumno[] }
  | { type: 'setPage'; page: number }
  | {
      type: 'setPaginatorOptions';
      paginatorOption: PageResult<SolicitudAlumno[]>;
    }
  | { type: 'setRowsPerPage'; rowsPerPage: number }
  | { type: 'setSearch'; search: string }
  | {
      type: 'setOrder';
      orderBy: CertificateHistoryTableColumn;
      dir: DirectionOptions;
    }
  | { type: 'setConfirmDialog'; confirmDialog: ConfirmDialogOptions };

export const certificateReducer = (
  state: CertificateHistoryState,
  action: CertificateAction,
): CertificateHistoryState => {
  switch (action.type) {
    case 'setCertificateList':
      return { ...state, certificateList: action.certificateList };
    case 'setPage':
      return { ...state, page: action.page };
    case 'setPaginatorOptions':
      return { ...state, paginatorOptions: action.paginatorOption };
    case 'setRowsPerPage':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'setSearch':
      return { ...state, search: action.search };
    case 'setOrder':
      return { ...state, orderBy: action.orderBy, dir: action.dir };
    case 'setConfirmDialog':
      return { ...state, confirmDialog: { ...action.confirmDialog } };
    default:
      return { ...state };
  }
};
