import { createContext, FC, useState } from 'react';
import { Spinner } from '../components';

interface LoadingContextProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

interface LoadingProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const LoadingContext = createContext({} as LoadingContextProps);

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <>{loading && <Spinner />}</>
      {children}
    </LoadingContext.Provider>
  );
};
