import { useContext } from 'react';
import { CertificateHistoryContext } from '../context/CertificateHistoryContext';

export const useCertificateHistory = () => {
  const {
    certificateState,
    setCertificateList,
    setPage,
    setPaginatorOptions,
    setRowsPerPage,
    setSearch,
    setOrder,
    setConfirmDialog,
  } = useContext(CertificateHistoryContext);

  const {
    certificateList,
    page,
    paginatorOptions,
    rowsPerPage,
    search,
    orderBy,
    dir,
    confirmDialog,
  } = certificateState;

  return {
    certificateList,
    setCertificateList,
    page,
    setPage,
    paginatorOptions,
    setPaginatorOptions,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    orderBy,
    dir,
    setOrder,
    confirmDialog,
    setConfirmDialog,
  };
};
