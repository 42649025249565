import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import certificateIcon from '../../../../../assets/icons/certificateIcon.svg';
import { useLoading, useNotify } from '../../../../../hooks';
import { RequestProcessService } from '../../../../../services';
import { RoutesPath } from '../../../../../utils/enums';
import { SolicitudDetalle } from '../../../../../utils/models';
import { useRequestProcess } from '../../hooks/useRequestProcess';
import { RequestProcessTabs } from '../RequestProcessTabs/RequestProcessTabs';

interface LocationState {
  certificateId: number;
  certificateStudentId: number;
  certificateIdError: number;
}

export const RequestProcessPage = () => {
  const requestProcessService = new RequestProcessService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const { certificate, setCertificate } = useRequestProcess();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { certificateId, certificateStudentId, certificateIdError } =
    state as LocationState;

  const getNewCertificateDetailById = async (id: number) => {
    setLoading(true);
    await requestProcessService
      .getNewCertificateDetailById(id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: SolicitudDetalle = new SolicitudDetalle().deserialize(
            res.data,
          );
          setCertificate(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const getCertificateDetailById = async (id: number) => {
    setLoading(true);
    await requestProcessService
      .getCertificateDetailById(id)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: SolicitudDetalle = new SolicitudDetalle().deserialize(
            res.data,
          );
          setCertificate(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
          });
        }
      })
      .catch(() => {
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (certificateIdError) {
      getCertificateDetailById(certificateIdError);
      return;
    }

    if (!certificateId && !certificateStudentId) {
      navigate(RoutesPath.FOLLOW_UP_CERTIFICATE);
      return;
    }

    if (certificateId) {
      getNewCertificateDetailById(certificateId);
    }

    if (certificateStudentId) {
      getCertificateDetailById(certificateStudentId);
    }
  }, []);
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Stack direction="row" alignItems="center">
            <Box
              sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}
              pr={2}>
              <img
                src={certificateIcon}
                style={{
                  height: 50,
                  width: 40,
                }}
                alt="icon"
              />
            </Box>
            <Typography
              variant="h1"
              component="h1"
              color="secondary"
              sx={{
                display: 'inline',
                fontSize: { xs: '24px' },
              }}>
              {certificate.nombre}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} sx={{ py: 2 }}>
          <Typography
            component="span"
            variant="subtitle2"
            paragraph
            color="grayCustom.main"
            sx={{ fontSize: '18px' }}>
            {certificate.descripcion}
          </Typography>
        </Grid>
        <Grid item md={12} py={2}>
          <Typography
            variant="h6"
            component="span"
            color="primary.dark"
            pr={1}
            display={'inline'}
            sx={{ fontWeight: 600 }}>
            Completa los pasos para solicitar tu constancia
          </Typography>
        </Grid>
        <RequestProcessTabs errorPay={!!certificateIdError} />
      </Grid>
    </>
  );
};
