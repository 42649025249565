import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AdminCatalogIcon,
  AdminCertificate,
  AdminUserIcon,
  FilePageIcon,
  FileSearchIcon,
  FollowCertificateIcon,
  GeneralIcon
} from '../../assets/icons';
import { AuthService } from '../../services';
import { RoleType, RoutesPath, RoutesPathTitle } from '../../utils/enums';
import { Auth } from '../../utils/helpers';
import { ProtectedRoute } from '../ProtectedRoutes/ProtectedRoutes';
import './Sidebar.css';

const SidebarList = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    padding: '10px 5px',
    margin: '10px 20px',
    backgroundColor: 'white',
    borderRadius: '16px',
    top: 0,
    transition: 'top ease 0.5s',
    '.sidebar__icon-content': {
      marginLeft: '10px',
    },
    '.sidebar__icon': {
      display: 'block',
    },
    '.sidebar__icon--hover': {
      display: 'none',
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#067969 !important',
    color: 'white',
    top: '-5px',
    boxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    webkitBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    mozBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    '.sidebar__icon-content': {
      backgroundColor: '#01A29C',
      maxHeight: '40px',
    },
    '.sidebar__icon': {
      display: 'none',
    },
    '.sidebar__icon--hover': {
      display: 'block',
    },
  },
  '& .Mui-selected:hover': {
    backgroundColor: '#067969 !important',
    color: 'white',
    top: '-5px',
    boxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    webkitBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    mozBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    '.sidebar__icon-content': {
      backgroundColor: '#01A29C',
      maxHeight: '40px',
    },
    '.sidebar__icon': {
      display: 'none',
    },
    '.sidebar__icon--hover': {
      display: 'block',
    },
  },
  '& .MuiListItemButton-root:hover': {
    top: '-5px',
    boxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    webkitBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    mozBoxShadow: '6px 12px 19px -13px rgba(0,0,0,0.75)',
    '.sidebar__icon-content': {
      backgroundColor: '#01A29C',
    },
    '.sidebar__icon': {
      display: 'none',
    },
    '.sidebar__icon--hover': {
      display: 'block',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },

  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '& .MuiList-root > .MuiListItemButton-root': {
    marginLeft: '40px',
    my: 2,
    padding: '10px',
    paddingLeft: '20px',
  },
});

export function SideBar(): JSX.Element {
  const authService = new AuthService();
  const [openSubMenuCatalog, setOpenSubMenuCatalog] = useState(false);
  const [title, setTitle] = useState<RoutesPathTitle>();
  const location = useLocation();

  const handleCatalogListToggle = () => {
    setOpenSubMenuCatalog(!openSubMenuCatalog);
  };

  useEffect(() => {
    if (location.pathname.includes(RoutesPath.ADMIN_CATALOG)) {
      setOpenSubMenuCatalog(true);
    }
  }, []);

  const changeTitle = () => {
    switch (location.pathname) {
      case RoutesPath.FOLLOW_UP_CERTIFICATE:
        setTitle(RoutesPathTitle.FOLLOW_UP_CERTIFICATE);
        break;
      case RoutesPath.FOLLOW_UP_CERTIFICATE_DETAIL:
        setTitle(RoutesPathTitle.FOLLOW_UP_CERTIFICATE);
        break;
      case RoutesPath.ADMIN_USERS:
        setTitle(RoutesPathTitle.ADMIN_USERS);
        break;
      case RoutesPath.ADMIN_CATALOG:
        setTitle(RoutesPathTitle.ADMIN_CATALOG);
        break;
      case RoutesPath.ADMIN_CATALOG_CAMPUS:
        setTitle(RoutesPathTitle.ADMIN_CATALOG_CAMPUS);
        break;
      case RoutesPath.ADMIN_CATALOG_CTT:
        setTitle(RoutesPathTitle.ADMIN_CATALOG_CTT);
        break;
      case RoutesPath.ADMIN_CATALOG_DIRECTOR:
        setTitle(RoutesPathTitle.ADMIN_CATALOG_DIRECTOR);
        break;
      case RoutesPath.ADMIN_CATALOG_PERIOD:
        setTitle(RoutesPathTitle.ADMIN_CATALOG_PERIOD);
        break;
      case RoutesPath.ADMIN_CERTIFICATE:
        setTitle(RoutesPathTitle.ADMIN_CERTIFICATE);
        break;
      case RoutesPath.STUDENT_GENERAL:
        setTitle(RoutesPathTitle.STUDENT_GENERAL);
        break;
      case RoutesPath.STUDENT_CERTIFICATE_REQUEST:
        setTitle(RoutesPathTitle.STUDENT_CERTIFICATE_REQUEST);
        break;
      case RoutesPath.STUDENT_CERTIFICATE_HISTORY:
        setTitle(RoutesPathTitle.STUDENT_CERTIFICATE_HISTORY);
        break;
    }
  };
  
  const handleLogout = () => {
    Auth.logout();
    const form = document.createElement('form');
    form.method = 'post';
    form.action = `${process.env.REACT_APP_API_BASE_URL}/auth/ssologout`;
    document.body.append(form);
    form.submit();
  };

  useEffect(() => {
    changeTitle();
  }, [location.pathname]);

  return (
    <SidebarList sx={{ p: 0 }}>
      <Grid container alignItems="center">
        <Grid item xs={12} py={2} px={4} sx={{ bgcolor: 'grayCustom.light' }}>
          <Typography
            variant="subtitle1"
            color="grayCustom.dark"
            sx={{ fontWeight: 600, fontSize: '16px' }}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="grayCustom.main"
            sx={{ fontWeight: 400, fontSize: '14px' }}>
            Servicios Escolares Tecmilenio
          </Typography>
        </Grid>
      </Grid>
      <ProtectedRoute roles={[RoleType.ADMIN, RoleType.COLLABORATOR]}>
        <ListItem className="sidebar__item" sx={{ px: 0, pt: 0, pb: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.FOLLOW_UP_CERTIFICATE}
            selected={location.pathname.includes(
              RoutesPath.FOLLOW_UP_CERTIFICATE,
            )}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <FollowCertificateIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <FollowCertificateIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText primary={RoutesPathTitle.FOLLOW_UP_CERTIFICATE} />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.ADMIN]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.ADMIN_USERS}
            selected={RoutesPath.ADMIN_USERS === location.pathname}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <AdminUserIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <AdminUserIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText primary={RoutesPathTitle.ADMIN_USERS} />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.ADMIN]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            onClick={handleCatalogListToggle}
            selected={location.pathname.includes(RoutesPath.ADMIN_CATALOG)}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <AdminCatalogIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <AdminCatalogIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText primary={RoutesPathTitle.ADMIN_CATALOG} />
            {openSubMenuCatalog ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSubMenuCatalog} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 8, py: 1 }}
              component={Link}
              to={RoutesPath.ADMIN_CATALOG_CAMPUS}
              selected={RoutesPath.ADMIN_CATALOG_CAMPUS === location.pathname}>
              <ListItemText primary={RoutesPathTitle.ADMIN_CATALOG_CAMPUS} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 8, py: 1 }}
              component={Link}
              to={RoutesPath.ADMIN_CATALOG_CTT}
              selected={RoutesPath.ADMIN_CATALOG_CTT === location.pathname}>
              <ListItemText primary={RoutesPathTitle.ADMIN_CATALOG_CTT} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 8, py: 1 }}
              component={Link}
              to={RoutesPath.ADMIN_CATALOG_DIRECTOR}
              selected={
                RoutesPath.ADMIN_CATALOG_DIRECTOR === location.pathname
              }>
              <ListItemText primary={RoutesPathTitle.ADMIN_CATALOG_DIRECTOR} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 8, py: 1 }}
              component={Link}
              to={RoutesPath.ADMIN_CATALOG_PERIOD}
              selected={RoutesPath.ADMIN_CATALOG_PERIOD === location.pathname}>
              <ListItemText primary={RoutesPathTitle.ADMIN_CATALOG_PERIOD} />
            </ListItemButton>
          </List>
        </Collapse>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.ADMIN]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.ADMIN_CERTIFICATE}
            selected={RoutesPath.ADMIN_CERTIFICATE === location.pathname}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <AdminCertificate />
                </Box>
                <Box className="sidebar__icon--hover">
                  <AdminCertificate fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText primary={RoutesPathTitle.ADMIN_CERTIFICATE} />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.STUDENT]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.STUDENT_GENERAL}
            selected={RoutesPath.STUDENT_GENERAL === location.pathname}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <GeneralIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <GeneralIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText primary={RoutesPathTitle.STUDENT_GENERAL} />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.STUDENT]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.STUDENT_CERTIFICATE_REQUEST}
            selected={location.pathname.includes(
              RoutesPath.STUDENT_CERTIFICATE_REQUEST,
            )}>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <FilePageIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <FilePageIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={RoutesPathTitle.STUDENT_CERTIFICATE_REQUEST}
            />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ProtectedRoute roles={[RoleType.STUDENT]}>
        <ListItem sx={{ px: 0, py: 0 }}>
          <ListItemButton
            component={Link}
            sx={{ py: 2 }}
            to={RoutesPath.STUDENT_CERTIFICATE_HISTORY}
            selected={
              RoutesPath.STUDENT_CERTIFICATE_HISTORY === location.pathname
            }>
            <ListItemIcon>
              <Box
                className="sidebar__icon-content"
                p={1}
                sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
                <Box className="sidebar__icon">
                  <FileSearchIcon />
                </Box>
                <Box className="sidebar__icon--hover">
                  <FileSearchIcon fill="white" />
                </Box>
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={RoutesPathTitle.STUDENT_CERTIFICATE_HISTORY}
            />
          </ListItemButton>
        </ListItem>
      </ProtectedRoute>
      <ListItem sx={{ px: 0, py: 0 }}>
        <ListItemButton sx={{ py: 2 }} onClick={handleLogout}>
          <ListItemIcon>
            <Box
              className="sidebar__icon-content"
              p={1}
              sx={{ bgcolor: 'grayCustom.light', borderRadius: '10px' }}>
              <Box className="sidebar__icon">
                <Logout />
              </Box>
              <Box className="sidebar__icon--hover">
                <Logout sx={{ color: 'white' }} />
              </Box>
            </Box>
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItemButton>
      </ListItem>
    </SidebarList>
  );
}
