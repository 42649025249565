import { createContext } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import { DirectionOptions } from '../../../../utils/enums';
import { PageResult, SolicitudAlumno } from '../../../../utils/models';
import { CertificateHistoryTableColumn } from '../components/enums/CertificateHistoryTableColumn';
import { CertificateHistoryState } from '../interfaces/interfaces';

export type CertificateHistoryContextProps = {
  certificateState: CertificateHistoryState;
  setCertificateList: (certificateList: SolicitudAlumno[]) => void;
  setPage: (page: number) => void;
  setPaginatorOptions: (paginatorOption: PageResult<SolicitudAlumno[]>) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setSearch: (search: string) => void;
  setOrder: (
    orderBy: CertificateHistoryTableColumn,
    dir: DirectionOptions,
  ) => void;
  setConfirmDialog: (confirmDialog: ConfirmDialogOptions) => void;
};

export const CertificateHistoryContext =
  createContext<CertificateHistoryContextProps>(
    {} as CertificateHistoryContextProps,
  );
