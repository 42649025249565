import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesPath } from '../enums';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const RoutesWithNotFound = ({ children }: Props) => {
  return (
    <Routes>
      {children}
      <Route
        path="*"
        element={<Navigate replace to={RoutesPath.DASHBOARD} />}
      />
    </Routes>
  );
};
