import axios from 'axios';
import { RoutesPath } from '../../utils/enums';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_FRONT = process.env.REACT_APP_FRONT_BASE_URL;
export const BASE_URL_API = `${process.env.REACT_APP_API_BASE_URL}/api`;

const axiosApp = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': true,
  },
});

axiosApp.defaults.withCredentials = true;

axiosApp.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosApp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.replace(`${BASE_URL}${RoutesPath.AUTH_LOGIN}`);
    }
    return Promise.reject(error);
  },
);

export default axiosApp;
