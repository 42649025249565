export const HistoryFillIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.6 8.738 2.157 2.118a.55.55 0 0 1 .168.403.55.55 0 0 1-.168.403.54.54 0 0 1-.394.17.54.54 0 0 1-.394-.17L8.644 9.375a.543.543 0 0 1-.169-.412V5.756a.546.546 0 0 1 .563-.562.546.546 0 0 1 .562.562v2.982Zm-.656 7.012c-1.75 0-3.234-.563-4.453-1.688-1.219-1.124-1.947-2.53-2.184-4.218a.583.583 0 0 1 .103-.45.523.523 0 0 1 .403-.207.492.492 0 0 1 .394.141.667.667 0 0 1 .206.385c.212 1.4.825 2.568 1.837 3.506 1.013.937 2.244 1.406 3.694 1.406 1.588 0 2.931-.556 4.031-1.669 1.1-1.112 1.65-2.462 1.65-4.05 0-1.55-.556-2.86-1.668-3.928-1.113-1.069-2.45-1.603-4.013-1.603-.85 0-1.647.194-2.39.581a6.52 6.52 0 0 0-1.941 1.538h1.406a.546.546 0 0 1 .563.562.546.546 0 0 1-.563.563H3.225a.546.546 0 0 1-.562-.563V3.281a.546.546 0 0 1 .562-.562.546.546 0 0 1 .563.562v1.425a7.135 7.135 0 0 1 2.316-1.8 6.378 6.378 0 0 1 2.84-.656c.938 0 1.819.175 2.644.525.825.35 1.547.828 2.166 1.434a6.735 6.735 0 0 1 1.462 2.129c.356.812.535 1.687.535 2.625 0 .937-.179 1.818-.535 2.643a6.788 6.788 0 0 1-1.462 2.156 6.979 6.979 0 0 1-2.166 1.454 6.596 6.596 0 0 1-2.644.534Z"
        fill="#00A794"
      />
    </svg>
  );
};
