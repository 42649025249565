import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesPath } from '../../../../../utils/enums';

export const PayError = () => {
  const navigate = useNavigate();
  const { certificateIdError } = useParams();
  useEffect(() => {
    navigate(RoutesPath.STUDENT_CERTIFICATE_REQUEST_PROCESS, {
      state: { certificateIdError },
    });
  });

  return <div></div>;
};
