import { DirectionOptions } from '../../../../utils/enums';
import { ClaveCentroTrabajo, PageResult } from '../../../../utils/models';
import { WorkPlaceTableColumn } from '../enums/WorkPlaceTableColumn';
import {
  ModalRecordWorkPlace,
  WorkPlaceState,
} from '../interface/workPlaceState';

type WorkPlaceAction =
  | { type: 'setSearch'; search: string }
  | {
      type: 'setWorkCenterKeyList';
      workCenterKeyList: ClaveCentroTrabajo[];
    }
  | { type: 'setPage'; page: number }
  | {
      type: 'setPaginatorOptions';
      paginatorOptions: PageResult<ClaveCentroTrabajo[]>;
    }
  | {
      type: 'setRowsPerPage';
      rowsPerPage: any;
    }
  | {
      type: 'setModal';
      modalRecord: ModalRecordWorkPlace;
    }
  | {
      type: 'setOrder';
      orderBy: WorkPlaceTableColumn;
      dir: DirectionOptions;
    };

export const workPlaceReducer = (
  state: WorkPlaceState,
  action: WorkPlaceAction,
): WorkPlaceState => {
  switch (action.type) {
    case 'setSearch':
      return {
        ...state,
        search: action.search,
      };
    case 'setWorkCenterKeyList':
      return {
        ...state,
        workCenterKeyList: action.workCenterKeyList,
      };
    case 'setPage':
      return {
        ...state,
        page: action.page,
      };
    case 'setPaginatorOptions':
      return {
        ...state,
        paginatorOptions: action.paginatorOptions,
      };
    case 'setRowsPerPage':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };
    case 'setModal':
      return {
        ...state,
        modalRecord: action.modalRecord,
      };
    case 'setOrder':
      return {
        ...state,
        orderBy: action.orderBy,
        dir: action.dir,
      };
    default:
      return state;
      break;
  }
};
