import { Deserializable } from '../interfaces';

export class SolicitudSeguimiento implements Deserializable {
  id: number;
  nombre: string;
  folio: string;
  matricula: string;
  alumno: string;
  campus: string;
  fechaEmision: string;
  estatus: string;
  estatusId: number;
  fechaCreacion: string;
  verificadoPor: string;
  fechaPagoVerificado: string;
  aprobadoPor: string;
  fechaAprobacion: string;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.folio = null;
    this.matricula = null;
    this.alumno = null;
    this.campus = null;
    this.fechaEmision = null;
    this.estatus = null;
    this.estatusId = null;
    this.fechaCreacion = null;
    this.verificadoPor = null;
    this.fechaPagoVerificado = null;
    this.aprobadoPor = null;
    this.fechaAprobacion = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
