import { CheckCircle, Description, Feed } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  DynamicForm,
  EmptyDialog,
  FieldDisable,
  ModalBar,
} from '../../../../../components';
import { useLoading, useNotify } from '../../../../../hooks';
import { FollowUpCertificatesService } from '../../../../../services';
import {
  DatoConstancia,
  DatosAlumnoConstancia,
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../../../../utils/models';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
interface Props {
  onReloadData: () => void;
}

export const UpdateFieldsCertificate: FC<Props> = ({ onReloadData }) => {
  const followUpCertificateService = new FollowUpCertificatesService();
  const { modal, setModal, followUpCertificate } = useFollowUpCertificates();
  const [request, setRequest] = useState<SolicitudDetalle>(
    new SolicitudDetalle(),
  );
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [validEditForm, setValidEditForm] = useState<boolean>(false);

  const handleClose = () => {
    setRequest(new SolicitudDetalle());
    setModal({
      ...modal,
      show: false,
      certificateEdit: new SolicitudSeguimiento(),
    });
  };

  useEffect(() => {
    setRequest(followUpCertificate);
  }, [followUpCertificate, modal]);

  const handleSubmit = async () => {
    const body = new DatosAlumnoConstancia();
    body.noEditables = request.noEditables;
    body.editablesAlumno = request.editablesAlumno;
    body.editableEscolar = request.editableEscolar;
    setLoading(true);
    await followUpCertificateService
      .updateFields(followUpCertificate.id, body)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            type: 'success',
            message: 'Se ha guardado la constancia correctamente',
          });
          onReloadData();
          handleClose();
        } else {
          setNotify({
            ...notify,
            open: true,
            type: 'error',
            message: res.message,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          type: 'error',
          message: 'Error al actualizar la constancia',
        });
      });
  };

  const handleChangeEditableFieldsStudent = (dataList: DatoConstancia[]) => {
    const newCerticate = new SolicitudDetalle().deserialize(request);
    newCerticate.editablesAlumno = dataList;
    setRequest(newCerticate);

    const valid = dataList.find((field) => !field.valor);
    setValidEditForm(!valid);
  };

  const handleChangeEditableFieldsSchool = (dataList: DatoConstancia[]) => {
    const newCerticate = new SolicitudDetalle().deserialize(request);
    newCerticate.editableEscolar = dataList;
    setRequest(newCerticate);

    const valid = dataList.find((field) => !field.valor);
    setValidEditForm(!valid);
  };

  useEffect(() => {
    setRequest(followUpCertificate);
  }, [modal, followUpCertificate]);

  return (
    <>
      <EmptyDialog
        dialog={{ open: modal.show }}
        dialogTitle={{
          title: 'Editar datos de la constancia',
          onClose: handleClose,
        }}
        dialogContent={{
          children: (
            <>
              <Grid container>
                <ModalBar
                  startIcon={<Description sx={{ color: 'grayCustom.main' }} />}
                  title="Edita algunos campos autorizados de la constancia"
                />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600 }}></Typography>
                <Grid item container xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="grayCustom.dark"
                    sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
                    Datos GENERALES (No editables)
                  </Typography>
                  {request.noEditables.map((data) => (
                    <Grid item xs={12} md={6} key={data.codigo} pr={1}>
                      <FieldDisable
                        icon={<CheckCircle />}
                        title={data.etiqueta}
                        subtitle={data.valor}
                      />
                    </Grid>
                  ))}
                </Grid>
                {request.editablesAlumno.length > 0 && (
                  <Grid item xs={12} py={1}>
                    <Typography
                      variant="subtitle2"
                      color="primary.main"
                      sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
                      Datos complementarios del Alumno
                    </Typography>
                    <DynamicForm
                      dataList={request.editablesAlumno}
                      setDataList={handleChangeEditableFieldsStudent}
                    />
                  </Grid>
                )}
                {request.editableEscolar.length > 0 && (
                  <Grid item xs={12} py={1}>
                    <Typography
                      variant="subtitle2"
                      color="primary.main"
                      sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
                      Datos complementarios de Escolares
                    </Typography>
                    <DynamicForm
                      dataList={request.editableEscolar}
                      setDataList={handleChangeEditableFieldsSchool}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          ),
        }}
        dialogActions={{
          cancelText: 'Cancelar',
          onClose: handleClose,
          confirmText: 'Guardar',
          onConfirm: handleSubmit,
          isValid: validEditForm,
        }}
      />
    </>
  );
};
