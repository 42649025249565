import { Response } from '../../utils/interfaces';
import { PageResult, SolicitudAlumno } from '../../utils/models';
import http from '../http/http';

export class CertificateHistoryService {
  getAllCertificate(
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<SolicitudAlumno[]>>>('/Historia', {
      params: { filtro, pageSize, pageNumber, ordenar, dir, inactivos },
    });
  }
}
