import { Deserializable } from '../interfaces';

export class SolicitudAlumno implements Deserializable {
  id: number;
  nombre: string;
  descripcion: string;
  fechaEmision: string;
  vigencia: string;
  estatus: string;
  rechazo: string;
  estatusId: number;
  esDescargada: boolean;
  fechaCreacion: string;
  constanciaId: number;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.descripcion = null;
    this.fechaEmision = null;
    this.vigencia = null;
    this.estatus = null;
    this.rechazo = null;
    this.estatusId = null;
    this.esDescargada = null;
    this.fechaCreacion = null;
    this.constanciaId = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
