import { Deserializable } from '../interfaces';

export class DatosValidacion implements Deserializable {
  nombre: string;
  emision: string;
  folio: string;
  alumno: string;
  matricula: string;

  constructor() {
    this.nombre = null;
    this.emision = null;
    this.folio = null;
    this.alumno = null;
    this.matricula = null;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
