import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { CancelFillIcon, CreditScoreIcon } from '../../../../../assets/icons';
import { ScheduleFilIcon } from '../../../../../assets/icons/ScheduleFilIIcon';
import { ToggleOnFillIcon } from '../../../../../assets/icons/ToggleOnFillIcon';
import {
  ButtonOptions,
  StatusButton,
} from '../../../../../components/certificateStatus/StatusButton/StatusButton';
import { CertificateStatus } from '../../../../../utils/enums';
import { SolicitudAlumno } from '../../../../../utils/models';
interface Props {
  certificate: SolicitudAlumno;
}
export const StatusCard: FC<Props> = ({ certificate }) => {
  const theme = useTheme();
  const button: ButtonOptions = {
    backgorund: theme.palette.primary.lighter,
    color: 'white',
    backgorundHover: theme.palette.primary.lighter,
    colorHover: 'white',
    px: 1,
    py: 0.5,
    fontSize: '12px',
    fullWidth: false,
  };
  return (
    <Box sx={{ height: '30px', px: 1, py: 1 }}>
      {certificate.estatusId === CertificateStatus.IN_REVIEW && (
        <StatusButton
          icon={
            <ToggleOnFillIcon
              bgColorBody="white"
              dotColor={theme.palette.primary.lighter}
            />
          }
          button={button}
          title={'ACTIVA'}
        />
      )}
      {certificate.estatusId === CertificateStatus.EXPIRED && (
        <StatusButton
          icon={
            <ScheduleFilIcon
              contentColor="white"
              exclamationColor={theme.palette.primary.lighter}
            />
          }
          button={button}
          title={'EXPIRADA'}
        />
      )}
      {certificate.estatusId === CertificateStatus.PENDING_PAYMENT && (
        <StatusButton
          icon={
            <CreditScoreIcon
              bgColorBody="white"
              dotColor={theme.palette.primary.lighter}
            />
          }
          button={button}
          title={'PAGO PENDIENTE'}
        />
      )}
      {certificate.estatusId === CertificateStatus.REJECTED && (
        <StatusButton
          icon={<CancelFillIcon fill="white" />}
          button={button}
          title={'NO ACEPTADA'}
        />
      )}
      {certificate.estatusId === CertificateStatus.VALID && (
        <StatusButton
          icon={
            <ToggleOnFillIcon
              bgColorBody="white"
              dotColor={theme.palette.primary.lighter}
            />
          }
          button={button}
          title={'VALIDA'}
        />
      )}
    </Box>
  );
};
