export enum RoutesPathTitle {
  FOLLOW_UP_CERTIFICATE = 'Seguimiento de constancias',
  ADMIN_USERS = 'Administración de usuarios',
  ADMIN_CATALOG = 'Administración de catálogos',
  ADMIN_CATALOG_CAMPUS = 'Campus',
  ADMIN_CATALOG_CTT = 'Centro de trabajo',
  ADMIN_CATALOG_DIRECTOR = 'Directores',
  ADMIN_CATALOG_PERIOD = 'Periodos',
  ADMIN_CERTIFICATE = 'Administración de constancias',
  STUDENT_GENERAL = 'General',
  STUDENT_CERTIFICATE_REQUEST = 'Solicitud de constancia',
  STUDENT_CERTIFICATE_HISTORY = 'Historial de constancias',
}
