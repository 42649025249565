import { Alert, Snackbar } from '@mui/material';
import { FC } from 'react';

export type NotifyOptions = {
  message: string;
  open: boolean;
  type: 'success' | 'warning' | 'error' | 'info';
  autoHideDuration?: number;
};

export type NotificationProps = {
  notify: NotifyOptions;
  setNotify: (options: NotifyOptions) => void;
};

export const NotifyDefaultOptions: NotifyOptions = {
  message: 'Se ha guardado correctamente',
  open: false,
  type: 'success',
  autoHideDuration: 5000,
};

export const Notification: FC<NotificationProps> = ({
  notify,
  setNotify,
}: NotificationProps) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotify({ ...notify, open: false });
  };

  return (
    <Snackbar
      open={notify.open}
      autoHideDuration={notify.autoHideDuration || 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={notify.type}
        sx={{ width: '100%' }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
};
