import { Response } from '../../utils/interfaces/response';
import { Constancia, PageResult } from '../../utils/models';
import http from '../http/http';

export class CertificatesService {
  getAllCertificate(
    filtro?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<Constancia[]>>>('/Constancia', {
      params: { filtro, pageSize, pageNumber, ordenar, dir, inactivos },
    });
  }

  getCertificateById(certificateId: number) {
    return http.get<Response<PageResult<Constancia[]>>>(
      `/Constancia/${certificateId}`,
    );
  }

  createCertificate(certificate: Constancia) {
    return http.post<Response<never>>('/Constancia', certificate);
  }

  updateCertificate(certificate: Constancia) {
    return http.put<Response<never>>('/Constancia', certificate);
  }

  updateStatusCertificate(certificateId: number, status: boolean) {
    return http.post<Response<never>>(
      `/Constancia/${certificateId}/Estatus`,
      status,
    );
  }

  deleteCertificate(certificateId: number) {
    return http.delete<Response<never>>(`/Constancia/${certificateId}`);
  }

  getURLTemplate(certificateId: number) {
    return http.get<Response<string>>(`/Constancia/${certificateId}/Plantilla`);
  }

  getURLTemplateBase64(certificateId: number) {
    return http.get<Response<string>>(
      `/Constancia/${certificateId}/Plantilla`,
      {},
    );
  }
}
