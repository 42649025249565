import { WarningAmber } from '@mui/icons-material';
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MilitarTechIcon, SportScoreIcon } from '../../../../../assets/icons';
import { ProcessBarTitle } from '../../enums/enums';
import { useRequestProcess } from '../../hooks/useRequestProcess';

export const ProgressBar = () => {
  const { progressBar } = useRequestProcess();
  const theme = useTheme();
  const palette = theme.palette;
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={progressBar.percentage}
          sx={{
            bgcolor: 'primary.dark',
            backgroundColor: '#F6F6F6',
          }}
        />
      </Box>
      <Box sx={{ width: '100%' }} pt={1}>
        <Stack direction="row" alignItems="center">
          <Typography
            variant="subtitle1"
            color="primary.dark"
            sx={{ display: 'inline', fontSize: '18px', fontWeight: 700 }}>
            {progressBar.percentage}%
          </Typography>
          <Typography
            variant="subtitle1"
            color="grayCustom.main"
            sx={{ display: 'inline', fontSize: '18px', fontWeight: 500 }}
            px={2}>
            {progressBar.title}
          </Typography>
          {ProcessBarTitle.VERIFY_DATA === progressBar.title && (
            <SportScoreIcon />
          )}
          {ProcessBarTitle.MAKE_PAY === progressBar.title && (
            <WarningAmber sx={{ color: 'grayCustom.main' }} />
          )}
          {ProcessBarTitle.DOWNLOAD_CERTIFICATE === progressBar.title && (
            <MilitarTechIcon fill={palette.primary.dark} />
          )}
        </Stack>
      </Box>
    </>
  );
};
