import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

export const useUsers = () => {
  const {
    userState,
    setUserList,
    setPage,
    setStatusUser,
    setPaginatorOptions,
    setRowsPerPage,
    setModalRecord,
    setSearch,
    setOrder,
    setConfirmDialog,
  } = useContext(UserContext);

  const {
    userList,
    page,
    paginatorOptions,
    rowsPerPage,
    search,
    modalRecord,
    orderBy,
    dir,
    confirmDialog,
  } = userState;

  return {
    userList,
    setUserList,
    page,
    setPage,
    paginatorOptions,
    setPaginatorOptions,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    modalRecord,
    setModalRecord,
    setStatusUser,
    orderBy,
    dir,
    setOrder,
    confirmDialog,
    setConfirmDialog,
  };
};
