export const EditSquareIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m18.25 7.52-3.563-3.5 1.188-1.187c.236-.236.531-.354.885-.354.355 0 .65.125.886.375l1.75 1.77c.236.25.354.546.354.886 0 .34-.125.629-.375.865L18.25 7.52ZM3.75 19.75a1.2 1.2 0 0 1-.875-.375A1.2 1.2 0 0 1 2.5 18.5V6a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h8.438l-5.396 5.416a1.817 1.817 0 0 0-.542 1.313v3.27c0 .348.122.643.365.886S7.153 16 7.5 16h3.25c.25 0 .49-.049.719-.146.229-.097.434-.236.614-.417L17.5 10v8.5a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H3.75Zm4.375-5a.607.607 0 0 1-.625-.625v-2.396c0-.167.035-.33.104-.49a1.3 1.3 0 0 1 .271-.406l5.917-5.917 3.562 3.5-5.937 5.959a1.302 1.302 0 0 1-.407.27c-.16.07-.322.105-.49.105H8.126Z" />
    </svg>
  );
};
