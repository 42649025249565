export const CreditScoreIcon = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      fill="#0075FA"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m10.819 16.106-2.4-2.4a.54.54 0 0 1-.169-.393c0-.15.063-.288.188-.413.1-.1.23-.15.393-.15s.3.05.413.15l1.969 1.987 4.106-4.106a.507.507 0 0 1 .393-.16c.15.007.282.066.394.179a.55.55 0 0 1 .169.403.583.583 0 0 1-.188.422l-4.48 4.481a.54.54 0 0 1-.394.169.54.54 0 0 1-.394-.169ZM2.625 15c-.313 0-.578-.11-.797-.328a1.085 1.085 0 0 1-.328-.797v-9.75c0-.313.11-.578.328-.797.219-.219.484-.328.797-.328h12.75c.313 0 .578.11.797.328.219.219.328.485.328.797v4.481h-.6l-4.688 4.744-1.218-1.237a1.632 1.632 0 0 0-1.172-.497 1.566 1.566 0 0 0-1.172.478c-.325.325-.49.719-.497 1.181-.006.463.153.856.478 1.181l.525.544H2.625Zm0-6.394h12.75V6H2.625v2.606Z" />
    </svg>
  );
};
