export const TrashFillIcon = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      fill="#FF4842"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M6.525 21c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05V5.25H4.75A.728.728 0 0 1 4 4.5a.728.728 0 0 1 .75-.75H8.7A.728.728 0 0 1 9.45 3h5.1a.728.728 0 0 1 .75.75h3.95a.728.728 0 0 1 .75.75.728.728 0 0 1-.75.75h-.275V19.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45H6.525Zm2.65-4.4a.728.728 0 0 0 .75.75.728.728 0 0 0 .75-.75V8.125a.728.728 0 0 0-.75-.75.728.728 0 0 0-.75.75V16.6Zm4.15 0a.728.728 0 0 0 .75.75.728.728 0 0 0 .75-.75V8.125a.728.728 0 0 0-.75-.75.728.728 0 0 0-.75.75V16.6Z" />
    </svg>
  );
};
