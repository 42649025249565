import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../hooks';
import { AuthService } from '../../../services';
import { Auth } from '../../../utils/helpers';
import { Perfil, SessionToken } from '../../../utils/models';

export const AuthSuccessPage = () => {
  const query = useQuery();
  const authService = new AuthService();
  const navigate = useNavigate();

  const checkSession = async () => {
    const session = new SessionToken();
      await authService.getProfileUser().then((response) => {
        const res = response.data;
        const data = new Perfil().deserialize(res.data);
        session.id = data.id;
        session.nombre = data.nombre;
        session.rol = data.rol;
        session.campus = data.campus;
        Auth.login(session);
        navigate('/');
      });
  };
  useEffect(() => {
    checkSession();
  }, []);

  return <></>;
};
