import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLoading, useNotify } from '../../../../../hooks';
import { FollowUpCertificatesService } from '../../../../../services';
import { SolicitudSeguimiento } from '../../../../../utils/models';
import { FollowUpCertificateModalOption } from '../../enums/FollowUpCertificateModalOption';
import { useFollowUpCertificates } from '../../hooks/useFollowUpCertificates';
import { RejectedCertificate } from '../../modals/RejectedCertificate/RejectedCertificate';
import { UpdateValidity } from '../../modals/UpdateValidity/UpdateValidity';
import { FollowUpCertificatesSearch } from '../FollowUpCertificatesSearch/FollowUpCertificatesSearch';
import { FollowUpCertificatesTable } from '../FollowUpCertificatesTable/FollowUpCertificatesTable';

export const FollowUpCertificatesPage = () => {
  const followUpCertificatesService = new FollowUpCertificatesService();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    setFollowUpCertificateList,
    setPaginatorOptions,
    setModal,
    modal,
    filters,
    setFilters,
  } = useFollowUpCertificates();

  const getAllFollowUpCertificates = async (
    campus?: string[],
    folio?: string,
    enrollment?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
  ) => {
    setFollowUpCertificateList([]);
    setLoading(true);
    await followUpCertificatesService
      .getAllCertificates(
        campus,
        folio,
        enrollment,
        pageSize,
        pageNumber,
        ordenar,
        dir,
        true,
      )
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          if (res.data.data.length === 0) {
            setNotify({
              ...notify,
              open: true,
              message: 'No se encontró una constancia',
              type: 'info',
            });
          }
          const data: SolicitudSeguimiento[] = res.data.data.map(
            (followUpCertificate) =>
              new SolicitudSeguimiento().deserialize(followUpCertificate),
          );
          setFollowUpCertificateList(data);
          setPaginatorOptions(res.data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onReloadData = () => {
    getAllFollowUpCertificates(
      filters.campus,
      filters.folio,
      filters.enrollment,
      filters.rowsPerPage,
      filters.page,
      filters.orderBy,
      filters.dir,
    );
  };

  const onTableFilter = async (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    asc: string,
  ) => {
    getAllFollowUpCertificates(
      filters.campus,
      filters.folio,
      filters.enrollment,
      pageSize,
      pageNumber,
      orderBy,
      asc,
    );
  };

  useEffect(() => {
    onReloadData();
  }, [filters]);
  return (
    <>
      {modal.type === FollowUpCertificateModalOption.REJECT && (
        <RejectedCertificate onReloadData={onReloadData} />
      )}
      {modal.type === FollowUpCertificateModalOption.UPDATE_VALIDITY && (
        <UpdateValidity onReloadData={onReloadData} />
      )}
      <Grid px={2}>
        <Grid item md={12}>
          <Typography variant="h1" color="secondary">
            Seguimiento de constancias
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} sx={{ py: 2 }}>
          <Typography
            variant="subtitle2"
            color="grayCustom.main"
            component="span">
            Ahora puedes agilizar el tiempo de respuesta y seguimiento a las
            tareas involucradas en el proceso de solicitud de constancias por
            parte de {'  '}
            <Typography
              variant="subtitle2"
              color="primary"
              display={'inline'}
              component="span">
              Alumnos de Tecmilenio.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ boxShadow: 1, borderRadius: '16px' }}>
        <Grid
          item
          xs={12}
          py={2}
          sx={{
            bgcolor: 'grayCustom.light',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}>
          <Typography
            color="secondary"
            px={2}
            sx={{
              fontWeight: { xs: 500, lg: 600 },
              fontSize: { xs: '15px', lg: '18px' },
            }}>
            Constancias por revisar
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ px: 2, py: 1 }}>
          <FollowUpCertificatesSearch />
        </Grid>
        <Grid item container xs={12} sx={{ px: 2, py: 1, overflowX: 'auto' }}>
          <FollowUpCertificatesTable
            onReloadData={onReloadData}
            onTableFilter={onTableFilter}
          />
        </Grid>
      </Grid>
    </>
  );
};
