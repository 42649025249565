import { createContext } from 'react';
import { DirectionOptions } from '../../../../utils/enums';
import { Director, PageResult } from '../../../../utils/models';
import { DirectorTableColumn } from '../enums/DirectorTableColumn';
import { DirectorState, ModalRecordDirector } from '../interfaces/interfaces';

export type DirectorContextProps = {
  directorState: DirectorState;
  reloadDirector: (directorList: Director[]) => void;
  changePage: (page: number) => void;
  changeStatus: (status: boolean, director: Director) => void;
  changePaginatorOptions: (paginatorOptions: PageResult<Director[]>) => void;
  changeRowsPerPage: (rowsPerPage: any) => void;
  changeModalRecord: (modalRecord: ModalRecordDirector) => void;
  changeSearch: (search: string) => void;
  setOrder: (orderBy: DirectorTableColumn, dir: DirectionOptions) => void;
};

export const DirectorContext = createContext<DirectorContextProps>(
  {} as DirectorContextProps,
);
