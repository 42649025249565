import fileSize from 'filesize';

/**
 * Check the extension of a list of files
 *
 * @param files - File list
 * @param ext - Extension file list - e.g. ['.jpg', '.png', '.jpeg']
 * @returns Object with valid and invalid files
 */
export const checkExtFiles = (
  files: File[],
  ext: string[],
): { valid: File[]; invalid: File[] } => {
  const invalid: File[] = [];
  const valid: File[] = [];
  files.forEach((file) => {
    const extFile = file.name.split('.').pop();
    if (ext.indexOf(`.${extFile}`) === -1) {
      invalid.push(file);
    } else {
      valid.push(file);
    }
  });
  return {
    invalid,
    valid,
  };
};

export const getFileSize = (size: number) => fileSize(size);

export const convertBlobToBase64 = async (blob: Blob) => {
  return await blobToBase64(blob);
};

export const convertBinaryToBlob = async (blob: Blob) => {
  return await binaryToBlob(blob);
};

const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const binaryToBlob = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
