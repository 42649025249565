import React from 'react';

export const MarkChatReadIcon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="m14.604 14.896 3.25-3.25a.564.564 0 0 1 .438-.177.64.64 0 0 1 .438.197.6.6 0 0 1 .187.438.6.6 0 0 1-.188.438l-3.687 3.687a.6.6 0 0 1-.438.188.6.6 0 0 1-.437-.188l-1.917-1.917a.6.6 0 0 1-.187-.437.6.6 0 0 1 .187-.438.564.564 0 0 1 .438-.177.683.683 0 0 1 .437.177l1.48 1.459ZM5 15l-2.27 2.27a.578.578 0 0 1-.678.136.57.57 0 0 1-.385-.573V2.917c0-.32.125-.608.375-.865s.542-.385.875-.385h14.167c.32 0 .607.128.864.385s.386.545.386.865v6.458h-6.875c-.348 0-.643.121-.886.364a1.206 1.206 0 0 0-.364.886V15H5Z" />
    </svg>
  );
};
