import { useReducer } from 'react';
import { TablePaginatorOptions } from '../../../../utils/consts';
import { DirectionOptions } from '../../../../utils/enums';
import { ClaveCentroTrabajo, PageResult } from '../../../../utils/models';
import { WorkPlaceTableColumn } from '../enums/WorkPlaceTableColumn';
import {
  ModalRecordWorkPlace,
  WorkPlaceState,
} from '../interface/workPlaceState';
import { WorkPlaceContext } from './WorkPlaceContext';
import { workPlaceReducer } from './workPlaceReducer';

const INITIAL_STATE: WorkPlaceState = {
  search: '',
  workCenterKeyList: [],
  page: 1,
  paginatorOptions: new PageResult<ClaveCentroTrabajo[]>(),
  rowsPerPage: TablePaginatorOptions.rowsPerPageDefault,
  orderBy: WorkPlaceTableColumn.CAMPUS,
  dir: DirectionOptions.ASC,
  modalRecord: {
    show: false,
    type: 'add',
    campusId: null,
    programa: null,
  },
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const WorkPlaceProvider = ({ children }: props) => {
  const [workPlaceState, dispatch] = useReducer(
    workPlaceReducer,
    INITIAL_STATE,
  );

  const setSearch = (search: string) => {
    dispatch({ type: 'setSearch', search });
  };

  const setWorkCenterKeyList = (workCenterKeyList: ClaveCentroTrabajo[]) => {
    dispatch({ type: 'setWorkCenterKeyList', workCenterKeyList });
  };

  const setPage = (page: number) => {
    dispatch({ type: 'setPage', page });
  };

  const setPaginatorOptions = (
    paginatorOptions: PageResult<ClaveCentroTrabajo[]>,
  ) => {
    dispatch({ type: 'setPaginatorOptions', paginatorOptions });
  };

  const setRowsPerPage = (rowsPerPage: any) => {
    dispatch({ type: 'setRowsPerPage', rowsPerPage });
  };

  const setModal = (modalRecord: ModalRecordWorkPlace) => {
    dispatch({ type: 'setModal', modalRecord });
  };

  const setOrder = (orderBy: WorkPlaceTableColumn, dir: DirectionOptions) => {
    dispatch({ type: 'setOrder', orderBy, dir });
  };

  return (
    <WorkPlaceContext.Provider
      value={{
        workPlaceState,
        setSearch,
        setWorkCenterKeyList,
        setPage,
        setPaginatorOptions,
        setRowsPerPage,
        setModal,
        setOrder,
      }}>
      {children}
    </WorkPlaceContext.Provider>
  );
};
