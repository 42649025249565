import { PeriodPage } from './components/PeriodPage/PeriodPage';
import { PeriodProvider } from './context/PeriodProvider';

export const Periods = () => {
  return (
    <PeriodProvider>
      <PeriodPage></PeriodPage>
    </PeriodProvider>
  );
};
