export interface LevelStudy {
  key: string;
  name: string;
}
export const levelStudyList: LevelStudy[] = [
  { key: '00', name: 'Indefinido' },
  { key: 'PP', name: 'Preparatoria' },
  { key: 'PR', name: 'Profesional' },
  { key: 'PA', name: 'Profesional Asociado' },
  { key: 'MA', name: 'Maestría' },
  { key: 'MS', name: 'Master' },
  { key: 'ES', name: 'Especialidad' },
  { key: 'EX', name: 'Extensión' },
  { key: 'EC', name: 'Educación Continua' },
];
