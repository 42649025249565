import { Abc, Info, People } from '@mui/icons-material';
import { AppBar, CssBaseline, Grid, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DateStartIcon,
  TabFilledIcon,
  TecmilenioIcon,
} from '../../assets/icons';
import { ToolbarModule } from '../../components';
import { useLoading, useNotify } from '../../hooks';
import { ValidateCertificateService } from '../../services';
import { DateHelper } from '../../utils/helpers';
import { DatosValidacion } from '../../utils/models';
import { DataDetailItem } from '../collaborators/FollowUpCertificates/components/DataDetailItem/DataDetailItem';

export const ValidCertificate = () => {
  const validateCertificateService = new ValidateCertificateService();
  const [dataCertificate, setDataCertificate] = useState<DatosValidacion>(
    new DatosValidacion(),
  );
  const { setLoading } = useLoading();
  const { notify, setNotify } = useNotify();
  const { token } = useParams();
  const [error, setError] = useState<string>(null);

  const validateCertificate = async (token: string) => {
    setLoading(true);
    await validateCertificateService
      .validateCertificate(token)
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setError(null);
          const data = new DatosValidacion().deserialize(res.data);
          setDataCertificate(data);
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia verificada correctamente',
            type: 'success',
          });
        } else {
          setError('¡Esta constancia es invalida o no existe!');
        }
      })
      .catch(() => {
        setError('¡Esta constancia es invalida o no existe!');
        setLoading(false);
      });
  };
  useEffect(() => {
    if (token) {
      validateCertificate(token);
    } else {
      setError('¡Esta constancia es invalida o no existe!');
    }
  }, []);
  return (
    <>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <TecmilenioIcon />
          <Grid
            container
            justifyContent="center"
            sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
              Hola
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <Grid container pt={4}>
        <Grid item mt={4} xs={12} p={4}>
          <ToolbarModule
            title={'Validación de constancia'}
            descriptionTmp={
              <>
                <Typography variant="subtitle2" color="grayCustom.main">
                  Datos de la constancia
                </Typography>
              </>
            }></ToolbarModule>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center" px={2}>
        <Grid
          item
          container
          xs={12}
          md={6}
          py={4}
          mt={4}
          sx={{ borderRadius: '16px', border: '1px solid black' }}>
          {!error && (
            <>
              <Grid item xs={12} md={6}>
                <DataDetailItem
                  icon={<People sx={{ color: 'grayCustom.main' }} />}
                  title={'Alumno'}
                  subtitle={dataCertificate.alumno}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataDetailItem
                  icon={<TabFilledIcon />}
                  title={'Matrícula'}
                  subtitle={dataCertificate.matricula}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataDetailItem
                  icon={<Abc sx={{ color: 'grayCustom.main' }} />}
                  title={'Folio'}
                  subtitle={dataCertificate.folio}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataDetailItem
                  icon={<DateStartIcon />}
                  title={'Fecha de emisión'}
                  subtitle={DateHelper.convertDateString(
                    dataCertificate.emision,
                    'LL',
                  )}
                />
              </Grid>
            </>
          )}
          {error && (
            <>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="center">
                <Grid item container justifyContent="center">
                  <Info sx={{ fontSize: '60px', color: '#B82238' }} />
                </Grid>
                <Typography
                  component="div"
                  variant="h1"
                  color="#B82238"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '20px', md: '32px' },
                    pt: 4,
                    textAlign: 'center',
                  }}>
                  {error}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
