import { createTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
declare module '@mui/material/styles' {
  interface Palette {
    grayCustom: Palette['primary'];
  }
  interface PaletteOptions {
    grayCustom: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }
}

const pallete = {
  primary: {
    main: '#06524C',
    dark: '#067969',
    darker: '#067969',
    // darker: '#00AB55',
    light: '#CAFFCD',
    lighter: '#26D07C',
  },
  secondary: {
    main: '#1C2B51',
  },
  info: {
    main: '#0075FA',
  },
  grayCustom: {
    main: '#6e7d8a',
    light: '#F6F6F6',
    dark: '#232d38',
    darker: '#DCE0E4',
  },
};

export const theme = createTheme(
  {
    typography: {
      fontFamily: ['Montserrat', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontSize: '40px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '24px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '24px',
        fontWeight: 500,
      },
      h4: {
        fontSize: '24px',
        fontWeight: 400,
      },
      h5: {
        fontSize: '24px',
        fontWeight: 400,
        fontFamily: 'Karla, Montserrat, sans-serif',
      },
      h6: {
        fontSize: '18px',
        fontWeight: 400,
      },
    },
    palette: {
      primary: {
        main: pallete.primary.main,
        dark: pallete.primary.dark,
        darker: pallete.primary.darker,
        light: pallete.primary.light,
        lighter: pallete.primary.lighter,
      },
      secondary: {
        main: pallete.secondary.main,
      },
      info: {
        main: pallete.info.main,
      },
      grayCustom: {
        main: pallete.grayCustom.main,
        light: pallete.grayCustom.light,
        dark: pallete.grayCustom.dark,
        darker: pallete.grayCustom.darker,
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '10.5px 14px 16.5px 14px',
            /* height: '56px',
            minHeight: '56px', */
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            py: 2,
            textTransform: 'none',
            fontWeight: 600,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: '4px 8px 4px 8px',
            borderRadius: '8px',
            /* height: '56px',
            minHeight: '56px', */
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: { px: 2, borderRadius: '16px' },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: pallete.grayCustom.light,
            borderRadius: '16px',
            '& th': {
              borderBottom: 'none',
              // borderRight: "2px solid #DCE0E4"
            },
            /* "& th::after": {
              content: '"|"',
              position:"relative",
              height: "18px",
              width: "2px",
              color: "#DCE0E4",
            }, */
            '& tr  th:first-of-type': {
              borderTopLeftRadius: '16px',
              borderBottomLeftRadius: '16px',
            },
            '& tr  th:last-child': {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
              borderRight: 'none',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {},
        variants: [
          {
            props: { variant: 'head' },
            style: {
              color: pallete.grayCustom.main,
              fontWeight: 600,
            },
          },
          {
            props: { variant: 'body' },
            style: {
              borderBottom: 'none',
            },
          },
        ],
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: pallete.grayCustom.dark,
            fontFamily: 'Karla',
          },
          selectLabel: {
            fontFamily: 'Karla',
          },
          select: {
            fontFamily: 'Karla',
          },
          displayedRows: {
            fontFamily: 'Karla',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paper': {
              borderRadius: '16px',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: pallete.primary.darker,
            color: 'white',
            fontWeight: 600,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '12px',
          },
        },
      },
    },
  },
  esES,
);
