import {
  MoreVert,
  SaveAlt,
  ToggleOffOutlined,
  ToggleOnOutlined,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApproveIcon,
  ContactSupportFillIcon,
  CreditScoreIcon,
  RejectIcon,
} from '../../../../../assets/icons';
import { CertificateStatus } from '../../../../../utils/enums';
import { SolicitudSeguimiento } from '../../../../../utils/models';

interface Props {
  certificate: SolicitudSeguimiento;
  onDownload: () => void;
  onExpired: () => void;
  onReactive: () => void;
  onShowDetails: () => void;
  approved: () => void;
  rejected: () => void;
  verifyPayment: () => void;
  generateCertificate: () => void;
}

export const FollowUpCertificateTableActions: FC<Props> = ({
  certificate,
  onDownload,
  onReactive,
  onExpired,
  onShowDetails,
  approved,
  rejected,
  verifyPayment,
  generateCertificate,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [actions, setActions] = useState({
    download: false,
    status: false,
    showDetails: false,
    approve: false,
    reject: false,
    verifyPayment: false,
    generateCertificate: false,
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusAction = () => {
    switch (certificate.estatusId) {
      case CertificateStatus.VALID:
        onExpired();
        break;
      case CertificateStatus.EXPIRED:
        onReactive();
        break;
    }
    handleClose();
  };

  const handleOnDownload = () => {
    onDownload();
    handleClose();
  };
  const handleApproved = () => {
    approved();
    handleClose();
  };
  const handleRejected = () => {
    rejected();
    handleClose();
  };
  const handleOnShowDetails = () => {
    onShowDetails();
    handleClose();
  };
  const handleVerifyPayment = () => {
    verifyPayment();
    handleClose();
  };
  const handleGenerateCertificate = () => {
    generateCertificate();
    handleClose();
  };

  useEffect(() => {
    switch (certificate.estatusId) {
      case CertificateStatus.IN_REVIEW:
        setActions({
          ...actions,
          approve: true,
          reject: true,
          showDetails: true,
        });
        break;
      case CertificateStatus.PENDING_PAYMENT:
        setActions({ ...actions, showDetails: true, verifyPayment: true });
        break;
      case CertificateStatus.REJECTED:
        setActions({ ...actions, showDetails: true });
        break;
      case CertificateStatus.VALID:
        setActions({
          ...actions,
          download: true,
          status: true,
          showDetails: true,
          generateCertificate: true,
        });
        break;
      case CertificateStatus.EXPIRED:
        setActions({
          ...actions,
          download: true,
          status: true,
          showDetails: true,
        });
        break;
      case CertificateStatus.HISTORICAL:
        setActions({ ...actions, showDetails: true });
        break;
    }
  }, []);

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: 0.5 }}>
        <MoreVert />
      </IconButton>
      <Menu
        variant="menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{ top: 0, left: 0 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {actions.download && (
          <MenuItem onClick={handleOnDownload}>
            <ListItemIcon>
              <SaveAlt sx={{ color: 'secondary.main' }} />
            </ListItemIcon>{' '}
            <ListItemText>Descargar</ListItemText>{' '}
          </MenuItem>
        )}
        {actions.status && (
          <MenuItem onClick={handleStatusAction}>
            {certificate.estatusId === CertificateStatus.VALID && (
              <>
                <ListItemIcon>
                  <ToggleOffOutlined sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Expirar" />
              </>
            )}
            {certificate.estatusId === CertificateStatus.EXPIRED && (
              <>
                <ListItemIcon>
                  <ToggleOnOutlined sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Reactivar" />
              </>
            )}
          </MenuItem>
        )}

        {actions.approve && (
          <MenuItem onClick={handleApproved}>
            <ListItemIcon>
              <ApproveIcon />
            </ListItemIcon>{' '}
            <ListItemText>Aprobar</ListItemText>{' '}
          </MenuItem>
        )}
        {actions.reject && (
          <MenuItem onClick={handleRejected}>
            <ListItemIcon>
              <RejectIcon />
            </ListItemIcon>{' '}
            <ListItemText>Rechazar</ListItemText>{' '}
          </MenuItem>
        )}
        {actions.showDetails && (
          <MenuItem onClick={handleOnShowDetails}>
            <ListItemIcon>
              <ContactSupportFillIcon />
            </ListItemIcon>{' '}
            <ListItemText>Ver detalles</ListItemText>{' '}
          </MenuItem>
        )}
                {actions.verifyPayment && (
          <MenuItem onClick={handleVerifyPayment}>
            <ListItemIcon>
            <CreditScoreIcon fill="black" />
            </ListItemIcon>{' '}
            <ListItemText>Verificar pago</ListItemText>{' '}
          </MenuItem>
        )}
        {actions.generateCertificate && (
          <MenuItem onClick={handleGenerateCertificate}>
            <ListItemIcon></ListItemIcon>{' '}
            <ListItemText>Generar constancia</ListItemText>{' '}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
