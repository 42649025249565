import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import './PreviewImageStyle.css';

export type PreviewImageProps = {
  image: string;
  open: boolean;
  handleClose: () => void;
};

export const PreviewImage: FC<PreviewImageProps> = ({
  image,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      style={{ zIndex: 1800 }}
      open={open}
      onClose={() => handleClose}
      maxWidth={'md'}>
      <DialogTitle>
        Vista previa{' '}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} pt={2}>
            <img
              className="image"
              src={image}
              alt="image"
              style={{ maxHeight: '400px' }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: 'uppercase',
            width: { xs: '100%', md: 'auto' },
            py: 1,
            m: 1,
          }}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
