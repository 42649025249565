import { Add, Clear, Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useFormik } from 'formik';
import { FC, KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { EditSquareIcon } from '../../../../../assets/icons';
import {
  ModalBar,
  MultiSelect,
  NumberFormatCustom,
  PreviewPdf,
} from '../../../../../components';
import {
  DragNDrop,
  FileLoaded,
} from '../../../../../components/DragNDrop/DragNDrop';
import { useLoading, useNotify } from '../../../../../hooks';
import { CampusService, CertificatesService } from '../../../../../services';
import { validationMsj } from '../../../../../utils/consts';
import { DirectionOptions } from '../../../../../utils/enums';
import { convertBlobToBase64, getFileSize } from '../../../../../utils/helpers';
import { DataFile } from '../../../../../utils/interfaces';
import { Campus, Constancia } from '../../../../../utils/models';
import { CampusTableColumn } from '../../../Campus/enums/CampusTableColumn';
import { useCertificates } from '../../hooks/useCertificates';

interface Props {
  onReloadData: () => void;
}

type CertificateForm = {
  nombre: string;
  descripcion: string;
  esCosto: boolean;
  costo: string;
  esAprobacionEscolar: boolean;
  campuses: string[];
  activo: boolean;
  categorias: string[];
  category: string;
  plantilla: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MAX_LENGTH_NAME = 200;
const MAX_LENGTH_DESCRIPTION = 3000;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialStateForm: CertificateForm = {
  nombre: '',
  descripcion: '',
  esCosto: false,
  costo: '0',
  esAprobacionEscolar: false,
  campuses: [],
  categorias: [],
  activo: false,
  category: '',
  plantilla: '',
};

const validationSchema = Yup.object({
  nombre: Yup.string()
    .required(validationMsj.is.required)
    .max(MAX_LENGTH_NAME, validationMsj.is.maxLength(MAX_LENGTH_NAME))
    .trim(validationMsj.is.trim()),
  descripcion: Yup.string()
    .required(validationMsj.is.required)
    .max(
      MAX_LENGTH_DESCRIPTION,
      validationMsj.is.maxLength(MAX_LENGTH_DESCRIPTION),
    )
    .trim(validationMsj.is.trim()),
  esCosto: Yup.boolean(),
  activo: Yup.boolean(),
  costo: Yup.number().when(['esCosto'], {
    is: true,
    then: (schema) =>
      schema
        .required(validationMsj.is.required)
        .min(1, validationMsj.is.min(1)),
    otherwise: (schema) => schema.default(0),
  }),
  esAprobacionEscolar: Yup.boolean(),
  campuses: Yup.array(),
  categorias: Yup.array(),
  category: Yup.string(),
  plantilla: Yup.string().nullable(true),
});

export const CertificateRecord: FC<Props> = ({ onReloadData }) => {
  const certificateService = new CertificatesService();
  const campusService = new CampusService();

  const { modalRecord, setModalRecord } = useCertificates();
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const [campusList, setCampusList] = useState<Campus[]>([]);
  const [file, setFile] = useState<DataFile>({
    name: null,
    data: null,
    url: null,
    sizeDescription: null,
    blob: null,
  });

  const [previewFileModal, setPreviewFileModal] = useState({
    image: '',
    open: false,
  });

  const formik = useFormik({
    initialValues: initialStateForm,
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  const getAllCampus = async () => {
    setLoading(true);
    await campusService
      .getAllCampus(
        '',
        -1,
        -1,
        CampusTableColumn.NOMBRE,
        DirectionOptions.ASC,
        false,
      )
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          const data: Campus[] = res.data.data.map((user) =>
            new Campus().deserialize(user),
          );
          setCampusList(data);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!modalRecord.certificateEdit.id) {
      return;
    }
    const category: string = formik.values.category;
    const categoryList: string[] = formik.values.categorias.slice();
    const categoryExist = categoryList.find((c) => c === category);
    if (categoryExist) {
      setNotify({
        ...notify,
        open: true,
        message: 'Este correo ya existe en la lista',
        type: 'info',
      });
      return;
    }
    if (category !== '') {
      categoryList.push(category);
    }
    const body = new Constancia();
    body.deserialize(formik.values);
    body.costo = parseFloat(formik.values.costo);
    body.categorias = categoryList;

    if (modalRecord.certificateEdit.id) {
      body.id = modalRecord.certificateEdit.id;
      if (file.name === body.plantilla) {
        body.plantilla = 'sincambios:sincambios';
      } else if (!file.url) {
        body.plantilla = 'borrar:borrar';
      } else {
        body.plantilla = `agregar:${file.data.replace(
          'data:application/pdf;base64,',
          '',
        )}`;
      }
      setLoading(true);
      await certificateService
        .updateCertificate(body)
        .then((response) => {
          setLoading(false);
          const res = response.data;
          if (res.success) {
            setNotify({
              ...notify,
              open: true,
              message: 'Constancia guardada correctamente',
              type: 'success',
            });
            handleClose();
          } else {
            setNotify({
              ...notify,
              open: true,
              message: res.message,
              type: 'error',
            });
          }
        })
        .catch(() => {
          setNotify({
            ...notify,
            open: true,
            message: 'Error inesperado',
            type: 'error',
          });
        });
    }
  };

  const resetDataFile = () => {
    setFile({ ...file, name: '', url: null, data: null, blob: null });
  };

  const handleClose = () => {
    formik.resetForm();
    resetDataFile();
    onReloadData();
    setModalRecord({
      ...modalRecord,
      show: false,
      certificateEdit: new Constancia(),
    });
  };

  const setFileByURL = async (fileName: string) => {
    setLoading(true);
    await certificateService
      .getURLTemplate(modalRecord.certificateEdit.id)
      .then(async (response) => {
        const res = response.data;
        setLoading(false);
        if (res.success) {
          setLoading(true);
          await axios({
            url: res.data,
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(async (response: any) => {
            const url = URL.createObjectURL(response.data);
            const base64 = await convertBlobToBase64(response.data);
            setLoading(false);
            const size = getFileSize(response.data.size);

            setFile({
              ...file,
              name: fileName,
              url,
              data: base64,
              sizeDescription: size,
            });
          });
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const openPreview = () => {
    setPreviewFileModal({
      ...previewFileModal,
      image: file.data,
      open: true,
    });
  };

  const addCategory = () => {
    const category: string = formik.values.category;
    if (category === '') {
      return;
    }
    const categoryList: string[] = formik.values.categorias.slice();
    const categoryExist = categoryList.find((c) => c === category);
    if (categoryExist) {
      setNotify({
        ...notify,
        open: true,
        message: 'Este correo ya existe en la lista',
        type: 'info',
      });
      return;
    }
    categoryList.push(category);
    formik.setValues({
      ...formik.values,
      categorias: categoryList,
      category: '',
    });
  };

  const deleteCategory = (index: number) => {
    const categoryList: string[] = formik.values.categorias.slice();
    categoryList.splice(index, 1);
    formik.setValues({
      ...formik.values,
      categorias: Array.from(categoryList),
    });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (
      event.code === 'Enter' &&
      formik.values.category &&
      !formik.errors.category
    ) {
      addCategory();
    }
  };

  const handleMultiselectChange = (
    selected: { id: string | number; name: string }[],
  ) => {
    formik.setValues({
      ...formik.values,
      campuses: selected.map((item) => item.id) as string[],
    });
  };

  const onLoaded = async (files: FileLoaded) => {
    const size = getFileSize(files.list[0].size);

    const url: string = files.list[0].preview;
    const blob: Blob = await fetch(url).then((r) => r.blob());
    const base64 = await convertBlobToBase64(blob);
    formik.setValues({ ...formik.values, plantilla: '' });
    setFile({
      ...file,
      url,
      data: base64,
      sizeDescription: size,
      name: files.list[0].name,
    });
  };

  const onDeleteFile = () => {
    resetDataFile();
  };

  const handleCostChange = (event: SyntheticEvent, value: boolean) => {
    formik.setValues({ ...formik.values, esCosto: value, costo: '0' });
  };

  useEffect(() => {
    getAllCampus();
  }, []);

  useEffect(() => {
    formik.resetForm();
    if (modalRecord.certificateEdit.id) {
      formik.setValues({
        ...formik.values,
        nombre: modalRecord.certificateEdit.nombre,
        descripcion: modalRecord.certificateEdit.descripcion,
        esCosto: modalRecord.certificateEdit.esCosto,
        costo: modalRecord.certificateEdit.costo
          ? modalRecord.certificateEdit.costo.toString()
          : '0',
        esAprobacionEscolar: modalRecord.certificateEdit.esAprobacionEscolar,
        campuses: modalRecord.certificateEdit.campuses,
        activo: modalRecord.certificateEdit.activo,
        categorias: modalRecord.certificateEdit.categorias,
        plantilla: modalRecord.certificateEdit.plantilla,
      });
      setFile({ ...file, name: modalRecord.certificateEdit.plantilla });
      if (modalRecord.certificateEdit.plantilla) {
        setFileByURL(modalRecord.certificateEdit.plantilla);
      }
    }
  }, [modalRecord]);

  return (
    <>
      <PreviewPdf
        file={file.data}
        open={previewFileModal.open}
        handleClose={() =>
          setPreviewFileModal({ ...previewFileModal, open: false })
        }
      />
      <Dialog
        open={modalRecord.show}
        onClose={() => handleClose}
        fullWidth
        maxWidth={'md'}>
        <DialogTitle>
          Editar los datos de la constancia{' '}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalRecord.type === 'edit' && (
            <>
              <ModalBar
                startIcon={<EditSquareIcon />}
                title="Edita los datos de la constancia"
              />
            </>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                component="span"
                color="primary.darker"
                sx={{ fontWeight: 600, pb: 2 }}>
                DATOS DE LA CONSTANCIA
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ py: 2 }}>
              <TextField
                name="nombre"
                label="Nombre de la constancia"
                placeholder="Agrega el nombre de la constancia"
                variant="outlined"
                type="text"
                fullWidth
                size={'small'}
                required
                InputProps={{ inputProps: { maxLength: MAX_LENGTH_NAME } }}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={!!formik.errors.nombre}
                helperText={formik.errors.nombre}
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              md={3}
              alignItems="center"
              justifyContent="center">
              <FormGroup>
                <Typography variant="body2" color="grayCustom.main">
                  Activar costo
                </Typography>
                <FormControlLabel
                  control={<Switch size="small" />}
                  name="esCosto"
                  label=""
                  checked={formik.values.esCosto}
                  onChange={handleCostChange}
                />
              </FormGroup>
            </Grid>
            {formik.values.esCosto && (
              <Grid item xs={8} md={3} sx={{ py: 2 }}>
                <TextField
                  label="Agregar un costo"
                  placeholder="Agrega el costo $"
                  value={formik.values.costo}
                  onChange={formik.handleChange}
                  name="costo"
                  fullWidth
                  sx={{
                    '& input': {
                      padding: '8.5px 14px 8.5px 14px',
                    },
                  }}
                  inputProps={{ maxLength: 16, min: 1 }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  variant="outlined"
                  error={!!formik.errors.costo}
                  helperText={formik.errors.costo}
                />
              </Grid>
            )}
            {!formik.values.esCosto && (
              <Grid item xs={8} md={3} sx={{ py: 2 }}>
                <Box
                  sx={{
                    bgcolor: 'grayCustom.light',
                    borderRadius: '8px',
                    px: 1,
                  }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Gratuita
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 400, color: 'grayCustom.main' }}>
                    Costo desactivado
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sx={{ py: 2 }}>
              <TextField
                name="descripcion"
                label="Descripción"
                placeholder="Agrega una descripción de la constancia..."
                variant="outlined"
                type="text"
                fullWidth
                size={'small'}
                required
                multiline
                minRows={4}
                sx={{
                  maxHeight: 'none',
                  height: 'none',
                  '& MuiOutlinedInput': {
                    maxHeight: 'none',
                    height: 'none',
                  },
                }}
                maxRows={10}
                InputProps={{
                  inputProps: { maxLength: MAX_LENGTH_DESCRIPTION },
                }}
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={!!formik.errors.descripcion}
                helperText={formik.errors.descripcion}
              />
            </Grid>
            <Grid item xs={12} py={2}>
              <MultiSelect
                title="Campus"
                elementList={campusList.map((campus) => {
                  return { id: campus.id.toString(), name: campus.nombre };
                })}
                selectedStart={formik.values.campuses}
                onChange={(selected) => handleMultiselectChange(selected)}
                errors={formik.errors.campuses as string}
              />
            </Grid>
            <Grid item xs={12} sx={{ py: 2 }}>
              <Stack direction="row" sx={{ mb: 1 }}>
                <TextField
                  name="category"
                  label="Categoría"
                  placeholder="Agregar las categorías asociadas"
                  variant="outlined"
                  type="text"
                  fullWidth
                  size={'small'}
                  value={formik.values.category || ''}
                  onChange={formik.handleChange}
                  error={!!formik.errors.category}
                  helperText={formik.errors.category}
                  onKeyDown={handleKeyPress}
                />
                <Tooltip title="Agregar categoría">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={addCategory}
                      disabled={
                        !!formik.errors.category ||
                        formik.values.category === ''
                      }>
                      <Add />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
              <Grid container>
                {formik.values.categorias.map((categoria, index) => (
                  <Grid item key={categoria} sx={{ p: 1 }}>
                    <Chip
                      label={categoria}
                      variant="outlined"
                      onDelete={() => deleteCategory(index)}
                      deleteIcon={<Clear sx={{ color: 'black !important' }} />}
                      sx={{
                        p: 1,
                        bgcolor: 'primary.light',
                        color: 'primary.dark',
                        fontWeight: 600,
                        border: 'none',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DragNDrop
                fileOptions={{
                  type: 'pdf',
                  maxSizeInMB: 5,
                  accept: { 'application/pdf': ['.pdf'] },
                }}
                existFile={file}
                title={
                  <Typography
                    variant="h6"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 600 }}>
                    Puedes arrastrar una archivo o{' '}
                    <Typography
                      variant="h6"
                      component="span"
                      color="primary.main"
                      sx={{ display: 'inline', fontWeight: 600 }}>
                      cargarlo desde aquí
                    </Typography>{' '}
                  </Typography>
                }
                subtitle={
                  <Typography
                    variant="body1"
                    color="grayCustom.main"
                    align="center"
                    component="span"
                    sx={{ fontWeight: 500, fontSize: '12px' }}>
                    *El formato aceptado para la plantilla es{' '}
                    <Typography
                      variant="body1"
                      component="span"
                      color="grayCustom.main"
                      sx={{
                        display: 'inline',
                        fontWeight: 500,
                        fontSize: '12px',
                      }}>
                      .pdf
                    </Typography>{' '}
                    <span style={{ display: 'block ' }}>
                      (Tamaño máximo 5MB)
                    </span>
                  </Typography>
                }
                onLoaded={onLoaded}
                onDelete={onDeleteFile}
                onPreview={openPreview}
              />
            </Grid>

            <Grid item container xs={12} md={6} py={1}>
              <Stack direction="row" alignItems="center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="esAprobacionEscolar"
                        checked={formik.values.esAprobacionEscolar}
                        onChange={formik.handleChange}
                      />
                    }
                    label=""
                  />
                </FormGroup>
                <Typography
                  variant="subtitle1"
                  color="grayCustom.main"
                  sx={{ fontWeight: 600 }}>
                  Esta constancia requiere aprobación de{' '}
                  <Typography
                    variant="subtitle1"
                    color="primary.main"
                    sx={{ fontWeight: 600, display: 'inline' }}>
                    Servicios Escolares
                  </Typography>
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              py={1}
              alignItems="center"
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
              <FormGroup>
                <Typography
                  variant="body2"
                  color="grayCustom.main"
                  sx={{ display: 'inline' }}>
                  Activar constancia
                </Typography>
                <FormControlLabel
                  control={<Switch size="small" sx={{ ml: 1 }} />}
                  name="activo"
                  label=""
                  checked={formik.values.activo}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, display: { xs: 'block', md: 'flex' } }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!formik.isValid}
            sx={{
              textTransform: 'uppercase',
              width: { xs: '100%', md: 'auto' },
              py: 1,
              m: 1,
            }}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
