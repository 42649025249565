import { Sort } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { OrderAscIcon, OrderDescIcon } from '../../assets/icons';
import { DirectionOptions } from '../../utils/enums';

interface Props {
  onOrderUpward: () => void;
  onOrderFalling: () => void;
}

export const FilterTableMenu: FC<Props> = ({
  onOrderUpward,
  onOrderFalling,
}) => {
  const [selected, setSelected] = useState<DirectionOptions>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderUpward = (directionOption: DirectionOptions) => {
    setSelected(directionOption);
    onOrderUpward();
  };

  const handleOrderFalling = (directionOption: DirectionOptions) => {
    setSelected(directionOption);
    onOrderFalling();
  };

  return (
    <Box
      sx={{
        float: 'right',
        position: 'relative',
        top: '-5px',
        borderRight: '2px solid #DCE0E4',
      }}>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: 0.5 }}>
        <Sort />
      </IconButton>
      <Menu
        variant="menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{ top: 0, left: 0 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Typography
          variant="subtitle2"
          color="grayCustom.main"
          sx={{ textTransform: 'uppercase', py: 1, px: 1 }}>
          Ordenar por:
        </Typography>
        <MenuItem onClick={() => handleOrderUpward(DirectionOptions.ASC)}>
          <ListItemIcon>
            <OrderAscIcon />
          </ListItemIcon>{' '}
          <ListItemText>Ascendente</ListItemText>{' '}
        </MenuItem>
        <MenuItem onClick={() => handleOrderFalling(DirectionOptions.DESC)}>
          <ListItemIcon>
            <OrderDescIcon />
          </ListItemIcon>{' '}
          <ListItemText>Descendente</ListItemText>{' '}
        </MenuItem>
      </Menu>
    </Box>
  );
};
