import { Deserializable } from '../interfaces';

export class Campus implements Deserializable {
  id: string;
  nombre: string;
  nombreCoordinador: string;
  firmaCoordinador: string;
  direccion: string;
  activo: boolean;
  correos: string[];

  constructor() {
    this.id = '';
    this.nombre = '';
    this.nombreCoordinador = '';
    this.firmaCoordinador = '';
    this.direccion = '';
    this.activo = false;
    this.correos = [];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
