import { useReducer } from 'react';
import { SolicitudDetalle } from '../../../../utils/models';
import { ProcessBarTitle, RequestProcessStep } from '../enums/enums';
import {
  DownloadTab,
  PayTab,
  PreviewFileRequestProcess,
  RequestProcessState,
  RequestProgressBar,
  VerifyDataTab
} from '../interfaces/interfaces';
import { RequestProcessContext } from './RequestProcessContext';
import { requestProcessReducer } from './requestProcessReduce';

const INITIAL_STATE_PERIOD: RequestProcessState = {
  certificate: new SolicitudDetalle(),
  currentTabIndex: RequestProcessStep.VERIFY_DATA,
  previewFile: {
    open: false,
    file: '',
  },
  verifyDataTab: {
    tab: {
      valid: false,
      index: RequestProcessStep.VERIFY_DATA,
      show: true,
      required: true,
      disabled: false,
    },
  },
  payTab: {
    tab: {
      valid: false,
      index: RequestProcessStep.MAKE_PAY,
      show: false,
      required: false,
      disabled: true,
    },
  },
  downloadTab: {
    tab: {
      valid: false,
      index: RequestProcessStep.DOWNLOAD_CERTIFICATE,
      show: false,
      required: true,
      disabled: true,
    },
  },
  progressBar: {
    percentage: 0,
    title: ProcessBarTitle.VERIFY_DATA,
  },
};

interface props {
  children: JSX.Element | JSX.Element[];
}

export const RequestProcessProvider = ({ children }: props) => {
  const [requestProcessState, dispatch] = useReducer(
    requestProcessReducer,
    INITIAL_STATE_PERIOD,
  );

  const setCertificate = (certificate: SolicitudDetalle) => {
    dispatch({ type: 'setCertificate', certificate });
  };

  const setVerifyDataTab = (verifyDataTab: VerifyDataTab) => {
    dispatch({ type: 'setVerifyDataTab', verifyDataTab });
  };

  const setPayTab = (payTab: PayTab) => {
    dispatch({ type: 'setPayTab', payTab });
  };

  const setDownloadTab = (downloadTab: DownloadTab) => {
    dispatch({ type: 'setDownloadTab', downloadTab });
  };

  const setPreviewData = (previewOptions: PreviewFileRequestProcess) => {
    dispatch({ type: 'setPreviewData', previewOptions });
  };

  const setProgressBar = (progressBar: RequestProgressBar) => {
    dispatch({ type: 'setProgressBar', progressBar });
  };

  const setCurrentTabIndex = (index: number) => {
    dispatch({ type: 'setCurrentTabIndex', index });
  };

  return (
    <RequestProcessContext.Provider
      value={{
        requestProcessState,
        setCertificate,
        setVerifyDataTab,
        setPayTab,
        setDownloadTab,
        setPreviewData,
        setProgressBar,
        setCurrentTabIndex,
      }}>
      {children}
    </RequestProcessContext.Provider>
  );
};
