import { createContext } from 'react';
import { ConfirmDialogOptions } from '../../../../components';
import {
  PageResult,
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../../../utils/models';
import {
  FollowUpCertificateFilters,
  FollowUpCertificateState,
  ModalRecordFollowUpCertificate,
} from '../interfaces/interfaces';

export type FollowUpCertificateContextProps = {
  followUpCertificateState: FollowUpCertificateState;
  setFollowUpCertificate: (followUpCertificate: SolicitudDetalle) => void;
  setFollowUpCertificateList: (
    followUpCertificateList: SolicitudSeguimiento[],
  ) => void;
  setPaginatorOptions: (
    paginatorOption: PageResult<SolicitudSeguimiento[]>,
  ) => void;
  setModal: (modal: ModalRecordFollowUpCertificate) => void;
  setFilters: (filters: FollowUpCertificateFilters) => void;
  setConfirmDialog: (confirmDialog: ConfirmDialogOptions) => void;
};

export const FollowUpCertificateContext =
  createContext<FollowUpCertificateContextProps>(
    {} as FollowUpCertificateContextProps,
  );
