import MenuIcon from '@mui/icons-material/Menu';
import { Grid, IconButton, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';
import { TecmilenioIcon } from '../../assets/icons';
import { useAuth } from '../../hooks';

interface Props {
  drawerWidth: number;
  mobileOpen: boolean;
  setMobileOpen: (open: boolean) => void;
}

export const Nav: FC<Props> = ({ drawerWidth, mobileOpen, setMobileOpen }) => {
  const { user } = useAuth();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: { sm: '100%' },
        ml: { sm: `${drawerWidth}px` },
      }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: 'none' } }}>
          <MenuIcon fontSize="large" />
        </IconButton>
        <TecmilenioIcon />
        <Grid
          container
          justifyContent="center"
          sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
            Hola, {user && user.nombre}
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
