import { levelStudyList } from '../consts';
import { Campus } from '../models';

export const getCampusNameById = (id: string, campusList: Campus[]): string => {
  const campus = campusList.find((c) => c.id === id);
  if (campus) return campus.nombre;
  return '-';
};

export const getLevelNameById = (key: string): string => {
  const levelStudy = levelStudyList.find((item) => item.key === key);
  if (levelStudy) return levelStudy.name;
  return '-';
};
