import { Response } from '../../utils/interfaces';
import {
  DatosAlumnoConstancia,
  FechaSolicitud,
  PageResult,
  SolicitudDetalle,
  SolicitudSeguimiento,
} from '../../utils/models';
import http from '../http/http';

export class FollowUpCertificatesService {
  getAllCertificates(
    campus: string[] = [],
    folio?: string,
    enrollment?: string,
    pageSize?: number,
    pageNumber?: number,
    ordenar?: string,
    dir?: string,
    inactivos?: boolean,
  ) {
    return http.get<Response<PageResult<SolicitudSeguimiento[]>>>(
      `/Seguimiento?${campus.map((c) => `campus=${c}`).join('&')}`,
      {
        params: {
          folio,
          matricula: enrollment,
          pageSize,
          pageNumber,
          ordenar,
          dir,
          inactivos,
        },
      },
    );
  }

  getCertificateById(certificateId: number) {
    return http.get<Response<PageResult<SolicitudDetalle>>>(
      `/Seguimiento/${certificateId}`,
    );
  }

  rejectCertificate(certificateId: number, message: string) {
    return http.put<Response<never>>(
      `/Seguimiento/${certificateId}/Rechazar`,
      message,
    );
  }

  updateFields(certificateId: number, fields: DatosAlumnoConstancia) {
    return http.put<Response<never>>(
      `/Seguimiento/${certificateId}/Detalles`,
      fields,
    );
  }

  approveCertificate(certificateId: number) {
    return http.put<Response<never>>(`/Seguimiento/${certificateId}/Aprobar`);
  }

  getCertificateDates(certificateId: number) {
    return http.get<Response<PageResult<FechaSolicitud>>>(
      `/Seguimiento/${certificateId}/Vigencia`,
    );
  }

  updatedExpirationTime(certificateId: number, date: string) {
    return http.put<Response<never>>(
      `/Seguimiento/${certificateId}/Vigencia`,
      date,
    );
  }
  verifyPayment(certificateId: number) {
    return http.post<Response<never>>(
      `/Seguimiento/${certificateId}/VerificarPago`,
    );
  }

  generateCertificate(certificateId: number) {
    return http.post<Response<never>>(
      `/Seguimiento/${certificateId}/GenerarConstancia`,
    );
  }
}
