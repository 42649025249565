import { Abc, ArrowForward, CheckCircle, Person } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  DynamicForm,
  FieldDisable,
  ReadDocument,
} from '../../../../../components/';
import { useLoading, useNotify } from '../../../../../hooks';
import { RequestProcessService } from '../../../../../services';
import { validationMsj } from '../../../../../utils/consts';
import { CertificateStatus, RoutesPath } from '../../../../../utils/enums';
import {
  convertBlobToBase64,
  toCurrencyFormat,
} from '../../../../../utils/helpers';
import { DataFile } from '../../../../../utils/interfaces';
import { DatoConstancia, SolicitudDetalle } from '../../../../../utils/models';
import { RequestProcessStep } from '../../enums/enums';
import { useRequestProcess } from '../../hooks/useRequestProcess';
interface Props {
  handleChangeTab: (index: RequestProcessStep) => void;
}

export const VerifyData: FC<Props> = ({ handleChangeTab }) => {
  const requestProcessService = new RequestProcessService();
  const { certificate, setCertificate } = useRequestProcess();
  const [validEditForm, setValidEditForm] = useState<boolean>(false);
  const { notify, setNotify } = useNotify();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [file, setFile] = useState<DataFile>({
    name: null,
    data: null,
    url: null,
    sizeDescription: null,
    blob: null,
  });

  const formik = useFormik({
    initialValues: {
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      acceptTerms: Yup.boolean().required(validationMsj.is.required()),
    }),
    onSubmit: () => handleSubmit(),
  });

  const setFileByURL = async (certificateId: number) => {
    setLoading(true);
    await requestProcessService
      .getCertificatePreview(certificateId)
      .then(async (response) => {
        setLoading(false);
        const url = URL.createObjectURL(response.data);
        const base64 = await convertBlobToBase64(response.data);
        setLoading(false);

        setFile({
          ...file,
          name: 'Vista previa',
          url,
          data: base64,
        });
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const handleSubmit = () => {
    // TODO: Add logic
  };

  const sendToSchoolApproval = () => {
    setLoading(true);
    requestProcessService
      .sendToSchoolApproval(certificate.id, certificate.editablesAlumno)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          setNotify({
            ...notify,
            open: true,
            message: 'Constancia enviada a aprobación escolar',
            type: 'success',
          });
          navigate(RoutesPath.STUDENT_CERTIFICATE_HISTORY);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const sendToSaveStudentFields = () => {
    setLoading(true);
    requestProcessService
      .sendToSaveStudentFields(certificate.id, certificate.editablesAlumno)
      .then(async (response) => {
        setLoading(false);
        const res = response.data;
        if (res.success) {
          navigate(RoutesPath.STUDENT_CERTIFICATE_HISTORY);
        } else {
          setNotify({
            ...notify,
            open: true,
            message: res.message,
            type: 'error',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const completeStudentFields = () => {
    setLoading(true);
    requestProcessService
      .completeStudentFields(certificate.id, certificate.editablesAlumno)
      .then(async () => {
        setLoading(false);
        sendToDownload();
      })
      .catch(() => {
        setLoading(false);
        setNotify({
          ...notify,
          open: true,
          message: 'Error inesperado',
          type: 'error',
        });
      });
  };

  const sendToPay = () => {
    setLoading(true);
    window.location.replace(requestProcessService.getURLToPay(certificate.id));
  };

  const sendToDownload = () =>
    handleChangeTab(RequestProcessStep.DOWNLOAD_CERTIFICATE);

  const handleNextStep = () => {
    // No necesita aprobación ni realizar pago
    if (!certificate.esAprobacionEscolar && !certificate.esCosto) {
      completeStudentFields();
      return;
    }

    // Necesita aprobación y guardar datos
    if (
      certificate.esAprobacionEscolar &&
      certificate.editablesAlumno.length > 0
    ) {
      sendToSchoolApproval();
      return;
    }

    // No necesita aprobación, requiere guardar datos y mandar a pagar
    if (
      !certificate.esAprobacionEscolar &&
      certificate.esCosto &&
      certificate.editablesAlumno.length > 0
    ) {
      sendToSaveStudentFields();
      return;
    }

    // No necesita aprobación, no requiere guardar datos y mandar a pagar
    if (certificate.esAprobacionEscolar) {
      sendToSchoolApproval();
      return;
    }

    // Necesita aprobación
    if (certificate.esAprobacionEscolar) {
      sendToSchoolApproval();
      return;
    }

    // No necesita aprobación y pago

    if (
      certificate.esCosto &&
      certificate.estatusId === CertificateStatus.TRY_REQUEST
    ) {
      sendToPay();
    } else {
      sendToDownload();
    }
  };

  const handleChangeEditableFields = (dataList: DatoConstancia[]) => {
    const newCerticate = new SolicitudDetalle().deserialize(certificate);
    newCerticate.editablesAlumno = dataList;
    setCertificate(newCerticate);

    if (dataList.length === 0) {
      setValidEditForm(true);
      return;
    }

    const valid = dataList.find((field) => !field.valor.trim());
    setValidEditForm(!valid);
  };

  const openTermsAndConditions = () => {
    window.open(
      'https://tecmilenio.mx/es/aviso-de-privacidad/alumnos',
      '_blank',
    );
  };

  useEffect(() => {
    if (!certificate.id) {
      return;
    }
    setFileByURL(certificate.id);
    if (certificate.editablesAlumno.length === 0) {
      setValidEditForm(true);
    } else {
      handleChangeEditableFields(certificate.editablesAlumno);
    }
  }, [certificate.id]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          px={4}>
          <Box sx={{ boxShadow: 2 }}>
            <ReadDocument file={file.url} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            color="secondary"
            pr={1}
            sx={{ fontWeight: 600 }}>
            Completa los siguientes datos
          </Typography>

          <Typography
            variant="body2"
            color="primary.main"
            pr={1}
            py={2}
            sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
            Datos del alumno
          </Typography>

          <Grid item xs={12} md={6}>
            <FieldDisable
              icon={<Person />}
              title={'Nombre del alumno'}
              subtitle={certificate.alumno}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldDisable
              icon={<Abc />}
              title={'Matrícula'}
              subtitle={certificate.matricula}
            />
          </Grid>

          <Typography
            variant="body2"
            color="primary.main"
            pr={1}
            py={2}
            sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
            Datos de la constancias
          </Typography>
          {certificate.noEditables.map((data) => (
            <Grid item xs={12} md={6} key={data.codigo}>
              <FieldDisable
                icon={<CheckCircle />}
                title={data.etiqueta}
                subtitle={data.valor}
              />
            </Grid>
          ))}
          {certificate.editablesAlumno.length > 0 && (
            <>
              <Typography
                variant="body2"
                color="primary.main"
                pr={1}
                py={2}
                sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
                Datos personales
              </Typography>
              <DynamicForm
                dataList={certificate.editablesAlumno}
                setDataList={handleChangeEditableFields}
              />
            </>
          )}

          <Grid item xs={12} lg={10} sx={{ pt: 3 }}>
            <Typography variant="body2" paragraph sx={{ fontWeight: 400 }}>
              Es muy importante que antes de continuar con tu pago, verifiques
              que todos los datos e información personal, sean los correctos, de
              lo contrario, deberás informarlo lo antes posible al Departamento
              de Escolares de tu Campus.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ pt: 3 }}>
            <FormGroup
              sx={{
                bgcolor: 'primary.light',
                px: 2,
                py: 1,
                borderRadius: '8px',
              }}>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptTerms"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                    />
                  }
                  label={<></>}
                />
                <Typography
                  variant="caption"
                  color="grayCustom.dark"
                  sx={{
                    fontWeight: 600,
                    '&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={openTermsAndConditions}>
                  Acepto los Términos y condiciones
                </Typography>
              </Stack>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            item
            container
            xs={12}
            md={8}
            lg={8}
            pt={2}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  px: 3,
                  py: 1,
                  minWidth: '250px',
                  '&: hover': {
                    bgcolor: 'primary.dark',
                    color: 'white',
                  },
                }}
                component={Link}
                to={RoutesPath.STUDENT_GENERAL}>
                CANCELAR SOLICITUD
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  px: 3,
                  py: 1,
                  minWidth: '250px',
                  bgcolor: 'primary.dark',
                  '&: hover': {
                    bgcolor: 'primary.main',
                  },
                }}
                endIcon={<ArrowForward />}
                onClick={handleNextStep}
                disabled={!formik.values.acceptTerms || !validEditForm}>
                {certificate.esAprobacionEscolar
                  ? 'SOLICITAR APROBACIÓN'
                  : certificate.esCosto
                  ? `PAGAR ${toCurrencyFormat(certificate.costo)} `
                  : 'DESCARGAR'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
