import React from 'react';

export const FileSearchIcon = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      fill="#6E7D8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 15.2c.617 0 1.133-.208 1.55-.625.417-.417.625-.933.625-1.55s-.208-1.133-.625-1.55c-.417-.417-.933-.625-1.55-.625s-1.133.208-1.55.625c-.417.417-.625.933-.625 1.55s.208 1.133.625 1.55c.417.417.933.625 1.55.625Zm6.4 5.3-4.35-4.35c-.333.2-.663.342-.988.425-.324.083-.679.125-1.062.125-1.033 0-1.904-.354-2.613-1.063-.708-.708-1.062-1.579-1.062-2.612 0-1.033.354-1.904 1.063-2.613.708-.708 1.579-1.062 2.612-1.062 1.033 0 1.904.354 2.613 1.063.708.708 1.062 1.579 1.062 2.612a3.7 3.7 0 0 1-.163 1.1 2.73 2.73 0 0 1-.487.925l3.475 3.475V8.45L14.2 3.5H5.5v17h12.9ZM5.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h8.625c.217 0 .425.046.625.138.2.091.367.22.5.387l4.375 5.025c.133.133.23.283.288.45.058.167.087.342.087.525V20.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Z" />
    </svg>
  );
};
