import { Close } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
interface Element {
  id: string;
  name: string;
}

interface Props {
  title: string;
  elementList: Element[];
  selectedStart: string[];
  onChange: (selectedList: Element[]) => void;
  errors: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelect: FC<Props> = ({
  title,
  elementList,
  selectedStart,
  errors,
  onChange,
}) => {
  const [selectedList, setSelectedList] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedEventList = event.target.value as string[];
    setSelectedList(selectedEventList);

    const selected = elementList.filter((item) =>
      selectedEventList.includes(item.name),
    );
    onChange(selected);
  };

  const handleDelete = (e: React.MouseEvent, element: string) => {
    e.preventDefault();
    const selectedFiltered = selectedList.filter((item) => item !== element);
    setSelectedList(selectedFiltered);

    const selected = elementList.filter((item) =>
      selectedFiltered.includes(item.name),
    );
    onChange(selected);
  };

  useEffect(() => {
    const selectedFiltered = elementList.filter((e) =>
      selectedStart.find((item) => e.id === item),
    );

    setSelectedList(selectedFiltered.map((item) => item.name));
  }, [selectedStart, elementList]);

  return (
    <FormControl sx={{ width: '100%' }} error={!!errors}>
      <InputLabel id="multiple-select">{title}</InputLabel>
      <Select
        labelId="multiple-select"
        label={title}
        id="demo-mutiple-chip-checkbox"
        aria-describedby="multiselecHelperText"
        multiple
        value={selectedList}
        onChange={handleChange}
        sx={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}
        MenuProps={MenuProps}
        renderValue={(selected) => (
          <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((element) => (
              <Chip
                sx={{
                  m: 1,
                  py: 2,
                  bgcolor: 'primary.main',
                  color: 'white',
                  borderRadius: '8px',
                  '&:hover': { bgcolor: 'primary.main', color: 'white' },
                }}
                key={element}
                label={
                  <Typography
                    variant="body2"
                    color="white"
                    sx={{ fontWeight: 500 }}
                  >
                    {element}
                  </Typography>
                }
                clickable
                deleteIcon={
                  <Close
                    onMouseDown={(event) => event.stopPropagation()}
                    sx={{
                      '&.MuiChip-deleteIcon': {
                        color: 'white',
                      },
                      '&.MuiChip-deleteIcon:hover': {
                        color: 'primary.dark',
                      },
                    }}
                  />
                }
                onDelete={(e) => handleDelete(e, element)}
              />
            ))}
          </Stack>
        )}
      >
        {elementList.map((element: Element) => (
          <MenuItem key={element.id} value={element.name}>
            <Checkbox checked={selectedList.includes(element.name)} />
            <ListItemText primary={element.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="multiselecHelperText">{errors}</FormHelperText>
    </FormControl>
  );
};
