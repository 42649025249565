import { Close, Search } from '@mui/icons-material';
import { TextField, InputAdornment, Grid } from '@mui/material';
import { FC, ChangeEvent } from 'react';
import { useCertificateHistory } from '../../hooks/useCertificateHistory';

interface Props {
  onSearch: (search: string) => void;
}

export const CertificateHistorySearch: FC<Props> = ({ onSearch }) => {
  const { search, setSearch } = useCertificateHistory();

  const handleCertificateSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    onSearch(value);
  };

  const filterCleaning = () => {
    if (search === '') {
      return;
    }
    setSearch('');
    onSearch('');
  };

  return (
    <Grid item xs={12} md={12}>
      <TextField
        label="Buscar"
        variant="outlined"
        placeholder="Buscar por “Constancia”..."
        fullWidth
        size={'small'}
        value={search}
        sx={{ py: 1 }}
        onChange={handleCertificateSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
              <Close onClick={filterCleaning} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
