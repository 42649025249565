export const validationMsj = {
  is: {
    required: (): string => 'El campo es obligatorio',
    email: (): string => 'Favor de agregar un correo válido',
    minLength: (min: number): string =>
      `El número mínimo de caracteres es ${min}.`,
    maxLength: (max: number): string =>
      `El número máximo de caracteres es ${max}.`,
    notEmpty: (): string => 'Favor de seleccionar uno o más elementos',
    onlyNumber: (): string => 'Solo se permite agregar números',
    onlyAlphanumeric: (): string => 'Solo se permite agregar números y letras',
    onlyText: (): string =>
      'No se permite agregar números ni carácteres especiales',
    minDate: (): string => 'La fecha de final debe ser mayor que la de inicio ',
    maxDate: (): string => 'La fecha inicial debe ser menor a la final',
    min: (min: number): string => `La cantidad mínima es de ${min}`,
    payRollFormat: (): string =>
      'El fomato de nómina es de una letra seguida de 8 números',
    trim: (): string => 'No puede incluir espacios iniciales y finales',
  },
};
